import _ from 'lodash';
import React from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import tw, { css, styled } from 'twin.macro';
import { ImSpinner8 } from 'react-icons/im';

export default function SurveyLoader({ handleCancelClick, text, type }) {
  const navigate = useNavigate();
  const { t } = useTranslation('survey');

  if (type && type === "error") {
    return (
      <Container>
        <ContentDiv>
          <h1 className="text-lg md:text-2xl">
            {t('common.text.error')}
          </h1>
          <div className="p-16 text-left mx-auto whitespace-pre-line">
            {!_.isEmpty(text) ? (
              <p>{text}</p>
            ) : (
              <p>{t('surveyloader.error.description')}</p>
            )}
          </div>
          <BtnDiv>
            {handleCancelClick && (
              <Btn
                className="btn-primary"
                onClick={() => handleCancelClick()}
              >
                {t('common.action.back')}
              </Btn>
            )}
            <Btn
              className="btn-danger"
              onClick={() => navigate("/")}
            >
              {t('common.action.exit_session')}
            </Btn>
          </BtnDiv>
        </ContentDiv>
      </Container>
    )
  }

  return (
    <Container>
      <ContentDiv>
        <SpinnerDiv aria-busy="true" role="status">
          <span className="text-6xl text-blue-100">
            <ImSpinner8 className="animate-spin" />
          </span>
        </SpinnerDiv>
        <h1>{t('common.text.loading')} {text}</h1>
      </ContentDiv>
    </Container>
  )
}

const Container = styled.div(() => [
  tw`bg-white flex flex-row h-screen justify-center`,
  css`
    max-height: -webkit-fill-available;
  `,
]);

const ContentDiv = styled.div(() => [
  tw`bg-white flex flex-col justify-center`,
  tw`h-auto text-center`,
  tw`w-full lg:w-3/4`,
]);

const SpinnerDiv = styled.div(() => [
  tw`flex justify-center p-8`,
]);

const BtnDiv = styled.div(() => [
  tw`bg-white flex flex-col justify-center md:flex-row`,
]);

const Btn = styled.div(() => [
  tw`m-4 md:w-1/4`,
]);