import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Card from '../../layout/Card';
import AlertCard from '../../../layout/AlertCard';

interface PeriopCardMatProps {
  dueDate: moment.Moment | null;
  toggleModal: () => void;
}

export default function PeriopCardMat({ dueDate, toggleModal }: PeriopCardMatProps) {
  const navigate = useNavigate();
  const { t } = useTranslation('home');

  const daysAfterSurgery = moment().diff(dueDate, 'days');

  return (
    <div
      aria-labelledby="periop-title"
      aria-describedby="periop-body"
      data-test="edu-event"
      className="py-4"
      role="dialog"
    >
      <Card
        headerComp={(
          <>
            <h2 className="font-bold" role="status" id="periop-title">
              {t('periopcardmat.countdown', { count: daysAfterSurgery })}
            </h2>
          </>
        )}
        bodyComp={(
          <>
            <p id="periop-body">
              {t('periopcardmat.description')}
            </p>
            <AlertCard
              title={t('periopcardmat.button.set_surgery_title')}
              text={t('periopcardmat.button.set_surgery_description')}
              handleAlertClick={() => navigate('/maternity')}
            />
            <AlertCard
              title={t('periopcardmat.button.set_discharge_title')}
              text={t('periopcardmat.button.set_discharge_description')}
              handleAlertClick={toggleModal}
            />
          </>
        )}
      />
    </div>
  );
}
