import React from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import tw, { css, styled } from 'twin.macro';
import { setDischargePrompt } from '../../../patient-app-common/slices/appSlice';
import DateInput from '../../layout/DateInput';
import LoaderInline from '../../layout/LoaderInline';
import { useGetPatientQuery, useUpdatePatientMutation } from '../../../patient-app-common/api/patientApi';
import usePatientDates from '../../../patient-app-common/hooks/usePatientDates';
import useHospitalFlags from '../../../hooks/useHospitalFlags';

export default function SetDischarge() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation('home');

  const { hospital } = useHospitalFlags();
  const { data: patient } = useGetPatientQuery();
  const { surgeryDate } = usePatientDates();
  const [updatePatient, { isLoading }] = useUpdatePatientMutation();

  const [page, setPage] = React.useState(1);
  const [dischargeDate, setDischargeDate] = React.useState<null | string>(null);
  const [error, setError] = React.useState('');

  const handleCloseClick = () => {
    dispatch(setDischargePrompt(false));
    navigate('/');
  };

  const handleDateSubmit = async () => {
    if (!surgeryDate) {
      return;
    }

    if (!dischargeDate) {
      setError(t('common.error.blank_input'));
    } else if (moment(dischargeDate)
      .isBefore(moment(surgeryDate)
        .startOf('day'))) {
      const surgeryStr = moment(surgeryDate)
        .format('LL');
      setError(t('setdischarge.error.invalid_1', { surgeryStr }));
    } else if (moment(dischargeDate)
      .isAfter(moment()
        .endOf('day'))) {
      setError(t('setdischarge.error.invalid_2'));
    } else {
      setError('');

      const params = {
        dischargeDate: moment(dischargeDate)
          .locale('en')
          .format('MM/DD/YYYY'),
      };

      const result = await updatePatient(params).unwrap();
      dispatch(setDischargePrompt(false));

      if (result.success) {
        navigate('/');
      } else {
        setError(t('common.error.server'));
      }
    }
  };

  if (!patient) {
    return null;
  }

  const { hospitalDisplayName } = patient;

  return (
    <div className="bg-gray-100 h-screen">
      <div className="flex justify-center pt-12">
        <Card>
          <LogoDiv>
            {hospital?.logo && <img className="w-1/4" alt="logo" src={hospital.logo} />}
          </LogoDiv>
          <ContentDiv>
            {page === 1 && (
              <div>
                <Title>{t('setdischarge.title')}</Title>
                <Text>
                  {t('setdischarge.input.hint')}
                </Text>
                <button
                  className="btn-primary m-4 w-1/3"
                  onClick={() => setPage(2)}
                >
                  {t('common.action.yes')}
                </button>
                <button
                  className="btn-secondary m-4 w-1/3"
                  onClick={() => handleCloseClick()}
                >
                  {t('common.action.no')}
                </button>
              </div>
            )}

            {page === 2 && (
              <div>
                <Title>{t('setdischarge.input.label', { hospitalName: hospitalDisplayName })}</Title>
                <div className="pb-8">
                  <StyledDateInput
                    sendDate={(date: string) => { setError(''); setDischargeDate(date); }}
                    error={error}
                  />
                  <p className="error-text text-left text-sm">{error}</p>
                </div>
                <button
                  onClick={() => handleDateSubmit()}
                  className="btn-primary m-4 w-1/3"
                >
                  {t('common.action.submit')}
                </button>
                <button
                  onClick={() => setPage(1)}
                  className="btn-secondary m-4 w-1/3"
                >
                  {t('common.action.back')}
                </button>
                {isLoading && (<LoaderInline />)}
              </div>
            )}

          </ContentDiv>
        </Card>
      </div>
    </div>
  );
}

const Card = styled.div(() => [
  tw`bg-white border border-gray-500 h-auto text-center rounded shadow`,
  tw`w-full lg:w-3/4`,
  css`min-height:32rem`,
]);

const LogoDiv = styled.div(() => [
  tw`flex items-center justify-center p-8`,
]);

const ContentDiv = styled.div(() => [
  tw`flex justify-center pt-8 w-full`,
  tw`px-4 md:px-0`,
]);

const Title = styled.p(() => [
  tw`text-2xl text-left pb-8`,
]);

const Text = styled.p(() => [
  tw`text-lg text-left py-8`,
]);

const StyledDateInput = styled(DateInput)(({ error } : { error: string }) => [
  tw`form-input block w-full`,
  tw`hover:border-blue-100`,
  error && tw`border-red`,
]);
