import React from 'react';
import moment from 'moment';
import tw, { styled } from 'twin.macro';
import useModal from '../../hooks/useModal';
import { AppEvent } from '../../patient-app-common/types/ApplicationEvent';
import EduModal from '../education/EduModal';

interface FutureEventProps {
  event: AppEvent;
}

export default function FutureEvent({ event }: FutureEventProps) {
  const { visible, toggleModal } = useModal();

  return (
    <>
      <EduModal
        event={event}
        source="FutureEvent"
        toggleModal={toggleModal}
        visible={visible}
      />
      <EventContainer key={event.id} role="listitem">
        <EventHeader>
          <Dot />
          <DateDiv>{moment(event.startDateTime).format('LL')}</DateDiv>
        </EventHeader>
        <EventBody>
          <p className="font-bold text-base sm:text-lg">{event.title}</p>
          <p className="text-sm sm:text-base">{event.subtitle}</p>
          {event.edIds
          && event.edIds.length > 0
          && event.options.linkText && (
            <EduBtn onClick={toggleModal}>
              {event.options.linkText}
            </EduBtn>
          )}
        </EventBody>
      </EventContainer>
    </>
  );
}

const Dot = styled.div(() => [
  tw`border-2 border-green-30 bg-green-100 h-4 w-4 rounded-full z-10`,
]);

const EventContainer = styled.div(() => [tw`pl-4 py-4`, tw`hover:bg-teal-30`]);

const EventHeader = styled.div(() => [tw`flex items-center pb-2`]);

const DateDiv = styled.div(() => [
  tw`flex-1 font-light pl-4`,
  tw`text-sm sm:text-base`,
]);

const EventBody = styled.div(() => [tw`pl-12`]);

const EduBtn = styled.button(() => [
  tw`bg-blue-10 m-2 py-2 px-4 rounded-full text-blue-100 `,
  tw`text-sm sm:text-base`,
]);
