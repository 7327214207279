import React from 'react';
import { Link } from 'react-router-dom';
import tw, { styled } from 'twin.macro';
import Panel from '../layout/Panel';

export default function DemoSurveys({ surveys }) {

  return (
    <Panel title="Surveys">
      <p className="px-0 sm:px-12 pb-4">You can access all surveys available in the careplan here.</p>
      <div className="divide-y px-12">
        {surveys.map((survey) => {
          const surveyName = survey.patientDisplayName;
          return (
            <NavLink
              key={survey.id}
              to={`/tracker/${survey.id}`}
            >
              {surveyName}
            </NavLink>
          );
        })}
      </div>
    </Panel>
  );
}

const NavLink = styled(Link)(() => [
  tw`block font-bold pt-4 text-blue-100`,
  tw`hover:underline`,
]);
