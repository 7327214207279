import React, { useMemo } from 'react';
import { VictoryArea, VictoryChart, VictoryAxis } from 'victory';
import _ from 'lodash';

export default function LinePreview({ question }) {
  let data = question.patientEntries.map(({ answer, date }) => {
    const answerValue = _.toNumber(answer);
    // exclude entries that cannot be parsed to a number
    if (!_.isNil(answer) && _.isNumber(answerValue)) {
      return ({
        answer: answerValue,
        // Victory requires the date to be a JS date object
        date: new Date(date),
      });
    }
  });

  const yValues = useMemo(() => {
    if (!data) return null;

    const {
      answers,
      stepValue,
      type,
    } = question;

    // for numerical input, set the min and max for y-scale based on patient entries +/- step value
    if (type === 'numerical_input') {
      const max = _.maxBy(data, 'answer').answer + stepValue;
      let min = _.minBy(data, 'answer').answer - stepValue;
      min = min < 0 ? 0 : min;
      return [min, max];
    }
    // for scale and picker question types
    // convert answer keys to their numerical values through index positions
    return answers.map((_value, index) => index);
  }, [data, question]);

  if (!data) return null;

  // if all entries are 0, bump down all values to -1 to render an area
  if (_.every(data, ['answer', 0])) {
    data = data.map(({ date }) => ({ answer: -1, date }));
  }

  return (
    <div className="p-4" style={{ height: '200px' }}>
      <svg style={{ height: 0 }}>
        <defs>
          <linearGradient id="gradient" gradientTransform="rotate(90)">
            <stop offset="5%" stopColor="#81A7C4" />
            <stop offset="95%" stopColor="#ffffff00" />
          </linearGradient>
        </defs>
      </svg>
      <VictoryChart
        minDomain={{ y: _.first(yValues) }}
        maxDomain={{ y: _.last(yValues) }}
      >
        <VictoryAxis
          dependentAxis
          invertAxis={question.reverseY}
          tickValues={yValues}
          style={{
            axis: { stroke: 'transparent' },
            ticks: { stroke: 'transparent' },
            tickLabels: { fill: 'transparent' },
          }}
        />
        <VictoryArea
          scale="time"
          style={{
            data: {
              stroke: '#044F89',
              strokeWidth: 4,
              fill: 'url(#gradient)',
            },
          }}
          x="date"
          y="answer"
          data={data}
          padding={0}
          y0={() => (question.reverseY ? _.last(yValues) : _.first(yValues))}
        />
      </VictoryChart>
    </div>
  );
}
