/* eslint-disable no-useless-escape */
/* eslint-disable no-mixed-operators */

import _ from 'lodash';

// Returns a unique ID string
export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export function validateTestServer(server: string | null) {
  switch (server) {
    case 'ca':
      return 'https://ca.seamless.md';

    case 'ca-canary-2':
      return 'https://ca-canary-2.seamless.md';

    case 'ca-canary-3':
      return 'https://ca-canary-3.seamless.md';

    case 'usa':
      return 'https://usa.seamless.md';

    case 'usa-canary':
      return 'https://usa-canary.seamless.md';

    case 'app':
      return 'https://app.seamless.md';

    default:
      return null;
  }
}

export function isPhone(value: string) {
  if (!value) return false;

  const cleanedInput = value.replace(/[()\-\s]/g, '');
  const isNumerical = cleanedInput && !_.isNaN(_.toNumber(cleanedInput));

  if (!isNumerical) return false;

  return /^\d{10}$/.test(cleanedInput);
}

export function isEmail(value: string) {
  if (!value) return false;

  const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return regex.test(value);
}