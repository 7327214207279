import React from 'react';
import { useTranslation } from 'react-i18next';
import tw, { css, styled } from 'twin.macro';
import { BiFontSize } from 'react-icons/bi';

export default function TextSizePanel({ sendTextSize }: { sendTextSize: (value: string) => void }) {
  const { t } = useTranslation();
  const [selected, setSelected] = React.useState('1rem');

  const handleBtnClick = (size: string) => {
    setSelected(size);
    sendTextSize(size);
  };

  return (
    <BtnPanel>
      <Btn
        aria-label={t('textsizepanel.btn.1')}
        onClick={() => handleBtnClick('1rem')}
        selected={selected === '1rem'}
      >
        <span className="place-self-center"><BiFontSize /></span>
      </Btn>

      <Btn
        aria-label={t('textsizepanel.btn.2')}
        onClick={() => handleBtnClick('1.125rem')}
        position="center"
        selected={selected === '1.125rem'}
      >
        <span className="place-self-center"><BiFontSize /></span>
      </Btn>

      <Btn
        aria-label={t('textsizepanel.btn.3')}
        onClick={() => handleBtnClick('1.5rem')}
        position="right"
        selected={selected === '1.5rem'}
      >
        <span className="place-self-center"><BiFontSize /></span>
      </Btn>
    </BtnPanel>
  );
}

const BtnPanel = styled.div(() => [
  tw`bg-white border border-gray-500 flex rounded w-32`,
  css`width: 12rem;`,
]);

const Btn = styled.button(({ position, selected }:
  { position: string, selected: string }) => [
  tw`border-gray-500 border flex justify-center p-2 text-2xl w-1/3`,
  tw`hover:bg-teal-10`,
  css`height:3rem;`,
  position === 'center' && tw`text-4xl`,
  position === 'right' && tw`text-5xl`,
  selected && tw`bg-teal-10`,
]);
