import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BiDotsHorizontalRounded } from "react-icons/bi"
import { logout } from '../../../patient-app-common/slices/authSlice';

export default function ProviderExit() {
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      return dispatch(logout());
    }, 10000);
  }, []);

  return (
    <div className="bg-white flex flex-row h-screen justify-center">
      <div className="flex flex-col justify-center">
        <h1 className="font-semibold text-3xl">Thank you for completing the survey</h1>
        <div className="mx-auto py-4 text-lg">
          <p>You can close this tab and return to your dashboard.</p>
          <div className="inline-flex">
            <p>This tab will be redirected to the patient login page shortly</p>
            <span className="text-4xl inline-flex">
              <BiDotsHorizontalRounded className="animate-pulse place-self-end" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
