import React from 'react';
import tw, { styled } from 'twin.macro';
import NavBar from '../layout/NavBar';
import TodoList from './TodoList';
import FutureList from './FutureList';

export default function Todos() {
  const mainRef = React.useRef<null | HTMLDivElement>(null);

  return (
    <div
      className="bg-gray-100 h-full min-h-screen"
    >
      <NavBar skipHandler={() => mainRef.current?.focus()} />
      <Container
        aria-labelledby="to-do list"
        role="region"
        tabIndex={0}
        ref={mainRef}
      >
        <div className="pb-12">
          <TodoList />
        </div>
        <div>
          <FutureList />
        </div>
      </Container>
    </div>
  );
}

const Container = styled.div(() => [
  tw`p-4 md:p-8 mx-auto`,
  tw`pt-16 md:pt-24`,
  tw`w-full lg:w-1/2`,
]);
