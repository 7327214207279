import React from 'react';
import { useTranslation } from 'react-i18next';
import tw, { css, styled } from 'twin.macro';
import { LoaderInline } from '../../layout';

const image = `${process.env.PUBLIC_URL}/assets/images/placeholder.png`;

interface MsgPlaceholderProps {
  count: number;
  isLoading: boolean;
  // TODO: check error type from redux doc
  error: unknown;
}

export function ThreadPlaceholder({ count, isLoading, error }: MsgPlaceholderProps) {
  const { t } = useTranslation('inbox');
  let headerText = t('threadplaceholder.title', { count });

  if (error) {
    return (
      <Container>
        <div className="flex flex-col flex-start w-full m-4">
          <Notice>
            <p className="text-sm">
              {t('thread.error')}
            </p>
          </Notice>
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <ImgWrapper>
        <img
          className="m-4"
          src={image}
          alt=""
        />
        {isLoading
          ? <LoaderInline />
          : (
            <>
              <p className="font-light text-2xl">
                {headerText}
              </p>
              <p className="font-light">{t('threadplaceholder.description')}</p>
            </>
          )}
      </ImgWrapper>
    </Container>
  );
}

const Container = styled.div(() => [
  tw`float-right w-edutopic`,
  tw`flex flex-row justify-center`,
  tw`bg-white overflow-hidden h-screen`,
  css`margin-top: 3.875rem;`,
]);

const Notice = styled.div(() => [
  tw`border-gray-500 my-4 p-4 rounded-lg`,
  css`background-color: #FCF8E4`,
]);

const ImgWrapper = styled.div(() => [
  tw`flex flex-col justify-center w-1/2 mt-12`,
  tw`items-center object-contain text-center`,
  tw`hidden md:block`,
]);
