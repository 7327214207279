import React from "react";
import tw, { styled } from "twin.macro";

export default function Toggle({ handleToggle, toggle, id }) {
  return (
    <Label>
      <Input onChange={handleToggle} toggle={toggle} type="checkbox" id={id} />
      <Switch toggle={toggle} htmlFor={id} />
    </Label>
  );
}

const Label = styled.label(({ toggle }) => [
  tw`align-middle cursor-pointer duration-200 ease-in inline-block relative select-none transition w-10`,
  toggle && tw`hidden`,
]);

const Switch = styled.div(({ toggle }) => [
  tw`block h-6 overflow-hidden rounded-full`,
  toggle ? tw`bg-green-100` : tw`bg-gray-500`,
]);

const Input = styled.input(({ toggle }) => [
  tw`absolute appearance-none cursor-pointer bg-white block border-4 h-6 w-6 rounded-full`,
  toggle ? tw`right-0 border-green-100` : tw`left-0 border-gray-500`,
]);
