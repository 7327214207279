import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import tw, { styled } from 'twin.macro';
import DateInput from '../../layout/DateInput';
import LoaderInline from '../../layout/LoaderInline';
import { useUpdatePatientMutation } from '../../../patient-app-common/api/patientApi';
import { PatientDates } from '../../../patient-app-common/hooks/usePatientDates';

interface DateModalProps {
  patientDates: PatientDates;
  programPhase: string | undefined;
  callbackFn: () => void;
}

export default function DateModal({
  patientDates,
  programPhase,
  callbackFn,
}: DateModalProps) {
  const { t } = useTranslation('home');
  const { surgeryDate, dischargeDate } = patientDates;

  const [updatePatient, { isLoading }] = useUpdatePatientMutation();

  const [chosenDate, setChosenDate] = React.useState<null | string>(null);
  const [error, setError] = React.useState('');

  const validateDate = (dateType: string) => {
    let result = false;

    if (dateType === 'surgery_date') {
      result = moment(chosenDate)
        .isSameOrBefore(dischargeDate) || !moment(dischargeDate)
        .isValid();
    } else if (dateType === 'discharge_date') {
      result = moment(chosenDate)
        .isSameOrAfter(surgeryDate);
    }

    return result;
  };

  const handleSubmitClick = async () => {
    let dateType;

    if (programPhase === 'inPreop') {
      dateType = 'surgery_date';
    } else if (programPhase === 'inPeriop') {
      dateType = 'discharge_date';
    }

    if (!dateType) {
      return;
    }

    if (!moment(chosenDate)
      .isValid()) {
      setError(t('common.error.blank_input'));
      return;
    }

    const validationResult = validateDate(dateType);
    const dischargeStr = moment(dischargeDate)
      .format('LL');
    const surgeryStr = moment(surgeryDate)
      .format('LL');

    if (validationResult) {
      const params: { [key: string]: string } = { };
      params[dateType] = moment(chosenDate)
        .locale('en')
        .format('MM/DD/YYYY');

      const result = await updatePatient(params).unwrap();
      if (result.success) {
        setError('');
        callbackFn();
      } else {
        setError(t('common.error.server'));
      }
    } else if (dateType === 'surgery_date' && !validationResult) {
      setError(t('datemodal.error.invalid_surgery', { dischargeStr }));
    } else if (dateType === 'discharge_date' && !validationResult) {
      setError(t('datemodal.error.invalid_discharge', { surgeryStr }));
    }
  };

  return (
    <Container>
      <p>{t('common.input.date')}</p>
      <div className="py-4">
        <StyledDateInput sendDate={(date: string) => setChosenDate(date)} />
        {error && <p className="error-text text-sm">{error}</p>}
      </div>

      <div className="py-4 text-center">
        <button onClick={() => handleSubmitClick()} className="btn-primary w-1/2">
          {t('common.action.submit')}
        </button>
      </div>

      {isLoading && (
        <div className="text-center">
          <LoaderInline />
        </div>
      )}
    </Container>
  );
}

const Container = styled.div(() => [
  tw`p-8`,
]);

const StyledDateInput = styled(DateInput)(({ error } : { error: string}) => [
  tw`form-input block w-full`,
  tw`hover:border-blue-100`,
  error && tw`border-red`,
]);
