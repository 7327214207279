// Copied from @aersoftware/react-use-action-cable
import { useEffect, useMemo } from 'react';
import { createConsumer } from '@rails/actioncable';

export function useActionCable(url) {
  const actionCable = useMemo(() => createConsumer(url), []);

  useEffect(() => {
    return () => {
      actionCable.disconnect();
    }
  }, []);

  return { actionCable }
}