import React from 'react';
import _ from 'lodash';
import tw, { css, styled } from 'twin.macro';
import { 
  // AiOutlineDownload, 
  AiOutlineFilePdf 
} from 'react-icons/ai';
import { useTranslation } from 'react-i18next';
import { MessagePayload } from '../../../patient-app-common/types/SecureMessageThreads';

interface MessageProps {
  message: MessagePayload;
}

export function Reply({ message }: MessageProps) {
  const { t } = useTranslation();
  const isProvider = message.sentBy === 'provider';

  return (
    <div className="relative">
      <div className="flex flex-col">
        {!_.isEmpty(message.text) && (
          <MsgContainer received={isProvider}>
            <div className="whitespace-pre-wrap break-words text-sm">
              <p>{message.text}</p>
            </div>
          </MsgContainer>
        )}

        {message.file && message.fileType?.includes('image') && (
          <a
            className="relative m-2"
            href={message.file}
            target="_blank"
            rel="noreferrer"
          >
            {/*
            <ImgDiv>
              <AiOutlineDownload
                className="bg-gray-700 p-4 rounded-full text-7xl text-white"
              />
            </ImgDiv>
            */}
            <div className="max-w-sm flex flex-wrap content-center">
              <img src={message.file} className="mx-auto rounded-md" alt={t('common.text.attachment')} />
            </div>
          </a>
        )}
      </div>
      {message.file && message.fileType?.includes('pdf') && (
        <FileWrapper received={isProvider}>
          <MsgContainer received={isProvider}>
            <a className="flex flex-col" href={message.file} target="_blank" rel="noreferrer">
              <AiOutlineFilePdf className="flex text-6xl self-center" />
              <p className="font-semibold px-2 text-sm text-blue-100 self-center">
                {t('common.text.attachment')}
              </p>
            </a>
          </MsgContainer>
        </FileWrapper>
      )}
    </div>
  );
}

const MsgContainer = styled.div(({ received }: { received: boolean }) => [
  tw`bg-gray-300 m-2 mr-0 p-4 shadow-md inline-block max-w-lg`,
  received ? css`border-radius: 0px 30px 30px;` : css`border-radius: 30px 30px 0px 30px;`,
  received ? css`background-color: #F2F2F2` : css`background-color: #E6EDF3`,
]);

const FileWrapper = styled.div(({ received }: { received: boolean }) => [
  tw`flex`,
  tw`max-w-md`,
  tw`items-center object-contain text-center`,
  received ? tw`justify-start` : tw`justify-end`,
]);

// const ImgDiv = styled.div(() => [
//   tw`absolute inset-0 z-10`,
//   tw`text-center flex flex-col items-center justify-center`,
//   tw`bg-white opacity-0 hover:opacity-75 bg-opacity-100 duration-300`,
// ]);
