import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiChevronLeft } from 'react-icons/bi';
import { Link, useParams } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

import useSegment from '../../../hooks/useSegment';
import useTrackers from '../../../patient-app-common/hooks/useTrackers';
import useResults from '../../../patient-app-common/hooks/useResults';
import useSurveys from '../../../patient-app-common/hooks/useSurveys';

import NavBar from '../../layout/NavBar';
import { Loader } from '../../layout';
import TrackerEntry from './TrackerEntry';
import TrackerResult from './TrackerResult';

const EVENT = 'Past survey viewed';

export default function TrackerTabs() {
  const { t } = useTranslation('progress');
  const { sendTrackEvent } = useSegment();
  const { trackerID } = useParams();
  const { createResults } = useResults();

  const { getSurveyEntryById, isLoading: isLoadingEntries } = useTrackers();
  const { allSurveys, isLoading: isLoadingSurveys } = useSurveys();
  const isLoading = isLoadingEntries || isLoadingSurveys;

  const entry = getSurveyEntryById(_.toNumber(trackerID));

  const mainRef = React.useRef(null);
  const [tab, setTab] = useState('answers');

  const [recommendations, setRecommendations] = useState(null);

  const survey = useMemo(() => {
    if (!entry || !allSurveys) return null;
    return _.find(allSurveys, ['id', entry.procedureId]);
  }, [entry, allSurveys]);

  useEffect(() => {
    if (!entry?.result || !survey) return;

    const parseRecommendations = async () => {
      const results = await createResults(survey, entry);
      setRecommendations(results);
    };

    parseRecommendations();
  }, [survey, entry]);

  useEffect(() => {
    if (!entry || !survey) return;

    sendTrackEvent(EVENT, {
      entryDate: moment(entry.date).format('YYYY-MM-DD').toString(),
      procedureId: survey.id,
      procedureName: survey.patientDisplayName,
      tab,
    });
  }, [survey, entry, tab]);

  return (
    <div className="bg-gray-100 h-full min-h-screen">
      <NavBar skipHandler={() => mainRef.current.focus()} />
      <Loader text="" visible={isLoading} />
      <div
        aria-label={t('common.link.progress')}
        className="pb-8 pt-24"
        role="region"
        tabIndex={0}
        ref={mainRef}
      >
        <div className="mx-auto w-full md:w-1/2 px-4">
          <div className="flex-col">
            <Highlight>
              <Link
                to="/progress"
                state={{ tab: 'surveys' }}
              >
                <BiChevronLeft className="inline-flex" />
                {t('common.action.back')}
              </Link>
            </Highlight>

            {entry && (
              <h1 className="font-semibold text-xl py-2">
                {moment(entry.createdAt).format('LLLL')}
              </h1>
            )}
          </div>
          {entry?.result && (
            <TabBar
              role="tablist"
            >
              <TabBtn
                aria-selected={tab === 'answers'}
                onClick={() => setTab('answers')}
                role="tab"
                selected={tab === 'answers'}
              >
                {t('tracker.tab.answers')}
              </TabBtn>
              <TabBtn
                aria-selected={tab === 'results'}
                onClick={() => setTab('results')}
                role="tab"
                selected={tab === 'results'}
              >
                {t('tracker.tab.results')}
              </TabBtn>
            </TabBar>
          )}
        </div>

        {tab === 'answers' && (
          <TrackerEntry
            entry={entry}
            survey={survey}
          />
        )}

        {tab === 'results' && (
          <TrackerResult
            recommendations={recommendations}
          />
        )}
      </div>
    </div>
  );
}

const TabBar = styled.div(() => [
  tw`inline-flex py-4`,
]);

const TabBtn = styled.button(({ selected }) => [
  tw`mt-4 mr-8`,
  selected ? tw`text-blue-100 border-b-2 border-blue-100` : tw`text-gray-700`,
  tw`hover:text-blue-100`,
]);

const Highlight = styled.p(() => [
  tw`font-light text-sm text-blue-100`,
]);
