import React, { Fragment } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { FaCaretDown } from 'react-icons/fa';
import tw, { css, styled } from 'twin.macro';
import useAppEvents from '../../patient-app-common/hooks/useAppEvents';
import FutureEvent from './FutureEvent';

export default function FutureList() {
  const { t } = useTranslation('todo');
  const { futureTodos } = useAppEvents();
  const [count, setCount] = React.useState(5);

  if (!futureTodos.length) return null;

  return (
    <>
      <H2>
        {t('futurelist.title')}
      </H2>
      <Container>
        <div className="relative" id="future-list" role="list">
          <TimeLine />
          {_.take(futureTodos, count)
            .map((event) => (
              <Fragment key={event.id}>
                <FutureEvent event={event} />
              </Fragment>
            ))}
        </div>
        {count < futureTodos.length && (
          <FooterBtn onClick={() => setCount(count + 5)}>
            <p className="inline-flex text-xl">
              {t('common.action.show_more')}
              <span className="text-3xl ml-2"><FaCaretDown /></span>
            </p>
          </FooterBtn>
        )}
      </Container>
    </>
  );
}

const Container = styled.div(() => [
  tw`bg-white border-gray-500 px-4 rounded-lg shadow-md`,
]);

const H2 = styled.h2(() => [
  tw`font-light pb-4`,
  tw`text-lg sm:text-2xl`,
]);

const TimeLine = styled.div(() => [
  tw`absolute h-full top-0 z-0`,
  tw`border-r-2 border-dotted border-gray-500`,
  css`left:23px;`,
]);

const FooterBtn = styled.button(() => [
  tw`border-t border-gray-300 p-4 w-full`,
  tw`hover:bg-teal-30`,
]);
