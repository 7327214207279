import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import tw, { styled } from 'twin.macro';
import { BiChevronRight, BiChevronDown } from 'react-icons/bi';
import useSegment from '../../../hooks/useSegment';

const SELECTOR_EVENT = 'Past survey dropdown list selected';
const SHOW_EVENT = 'Past survey list show more entries';

export default function TrackerList({ surveys, surveyEntries }) {
  const { t } = useTranslation('progress');
  const { sendTrackEvent } = useSegment();

  const [count, setCount] = useState(10);
  const [selectedId, setSelectedId] = useState(0);

  const selectedSurveyEntries = useMemo(() => {
    if (!surveyEntries?.length) return [];

    let entries = [...surveyEntries].sort((a, b) => moment(b.createdAt) - moment(a.createdAt));

    if (selectedId) {
      entries = _.filter(entries, ['procedureId', selectedId]);
    }

    return entries;
  }, [selectedId, surveyEntries]);

  const entriesToShow = useMemo(() => (
    _.orderBy(selectedSurveyEntries.slice(0, count))
  ), [selectedSurveyEntries, count]);

  const handleSelectChange = (e) => {
    setCount(10);

    const id = _.toNumber(e.target.value);
    setSelectedId(id);

    const procedure = _.find(surveys, ['id', id]);
    if (!procedure) return;

    sendTrackEvent(SELECTOR_EVENT, {
      procedureId: procedure.id,
      procedureName: procedure.patientDisplayName,
    });
  };

  const showMore = () => {
    if (!selectedSurveyEntries.length) return;

    sendTrackEvent(SHOW_EVENT);

    if (count + 1 <= selectedSurveyEntries.length) {
      setCount(count + 10);
    } else {
      setCount(selectedSurveyEntries.length);
    }
  };

  if (!surveys || !surveyEntries?.length) {
    return (
      <div className="flex justify-center">
        <div className="text-center w-full">
          <div className="flex justify-center py-8">
            <img src="/assets/images/file-search.png" alt="" />
          </div>
          <h2 className="font-light text-gray-600 text-lg sm:text-xl">
            {t('trackerlist.blank.title')}
          </h2>
          <p className="font-light text-gray-600">
            {t('trackerlist.blank.subtitle')}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="mx-auto w-full md:w-1/2">
      <div className="flex flex-col my-8">
        <div className="pb-4">
          <select
            id="survey"
            defaultValue=""
            onChange={(e) => handleSelectChange(e)}
            className="form-select block"
          >
            <option key="0" value="0">
              {t('common.select.placeholder')}
            </option>
            {surveys.map((survey) => (
              <option key={survey.id} value={survey.id}>
                {survey.mainTracker ? t('common.text.main_tracker') : survey.patientDisplayName}
              </option>
            ))}
          </select>
        </div>
        {entriesToShow.map((entry) => {
          const survey = _.find(surveys, ['id', entry.procedureId]);
          if (!survey) return null;

          const { result } = entry;
          const surveyName = survey.mainTracker ? t('common.text.main_tracker') : survey.patientDisplayName;

          return (
            <Card key={entry.id}>
              <div className="inline-flex">
                <div className="pr-16">
                  <IconCalendar date={entry.createdAt} />
                </div>
                <div>
                  <h2 className="font-semibold text-lg">
                    {surveyName}
                  </h2>
                  {result && (
                    <div
                      className="rounded inline-flex p-2 my-2"
                      style={{
                        backgroundColor: result.apptext.recRecommendationBackgroundColor,
                      }}
                    >
                      <img
                        className="inline-flex w-6 h-6"
                        src={`/assets/icons/${result.apptext.recIconImage}`}
                        alt=""
                      />
                      <p className="text-white">
                        {result.apptext.recLabelText}
                      </p>
                    </div>
                  )}
                  <div className="bottom-0">
                    <Highlight>
                      <Link to={`/progress/survey/${entry.id}`}>
                        {t('trackerlist.link.survey')}
                        <BiChevronRight className="inline-flex" />
                      </Link>
                    </Highlight>
                  </div>
                </div>
              </div>
            </Card>
          );
        })}
      </div>
      {selectedSurveyEntries.length > entriesToShow.length && (
        <button
          className="text-sm text-blue-100"
          onClick={showMore}
        >
          {t('common.action.show_more')}
          <BiChevronDown className="inline-flex" />
        </button>
      )}
    </div>
  );
}

function IconCalendar({ date }) {
  return (
    <div className="min-w-24 bg-white min-h-32 font-medium">
      <div className="border rounded-lg w-24 flex-none text-center">
        <div className="block rounded-t overflow-hidden text-center">
          <div className="bg-blue-100 text-white py-1">
            {moment(date).format('MMM')}
          </div>
          <div>
            <span className="text-3xl font-bold leading-tight">
              {moment(date).format('D')}
            </span>
          </div>
          <div>
            <span className="text-sm">
              {moment(date).format('dddd')}
            </span>
          </div>
          <div>
            <span className="font-light text-xs">
              {moment(date).format('YYYY')}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

const Card = styled.div(() => [
  tw`bg-white border-gray-500 my-4 p-8 rounded-lg shadow-md w-full`,
  tw`flex-col md:flex-row md:inline-flex`,
]);

const Highlight = styled.p(() => [
  tw`font-light text-sm text-blue-100`,
]);
