import React from 'react';
import { useTranslation } from 'react-i18next';
import tw, { css, styled } from 'twin.macro';
import Modal from '../../layout/Modal';
import useModal from '../../../hooks/useModal';

export default function SurveyHeader({
  disableClose,
  exitHandler,
  surveyName,
  progress,
  index,
}) {
  const { t } = useTranslation();
  const { visible, toggleModal } = useModal();

  return (
    <header className="bg-white w-full">
      <Modal
        visible={visible}
        handleCloseClick={toggleModal}
        component={(
          <ExitAlert
            exitHandler={exitHandler}
            cancelHandler={toggleModal}
            progress={progress}
            surveyName={surveyName}
          />
        )}
      />

      <HeaderContainer>
        <div className="flex-1">
          <h1 className="text-lg sm:text-xl">{surveyName}</h1>
        </div>
        <div className="inline-flex">
          {!disableClose && (
            <button
              onClick={toggleModal}
              className="btn-danger mx-1"
            >
              {t('common.action.close')}
            </button>
          )}
        </div>
      </HeaderContainer>

      {index !== 0 ? (
        <div className="w-full">
          <div className="bg-white w-full">
            <ProgressBar
              aria-valuenow={progress}
              aria-valuemax="100"
              progress={progress}
              role="progressbar"
            >
              {progress}%
            </ProgressBar>
          </div>
        </div>
      ) : (
        <div className="bg-gray-100" style={{ height: '1.4rem' }} />
      )}
    </header>
  );
}

const HeaderContainer = styled.div(() => [
  tw`bg-white border-b border-gray-500 flex flex-wrap items-center lg:px-16 px-6 py-2`,
]);

const ProgressBar = styled.div(({ progress }) => [
  tw`bg-green-100 text-sm leading-none py-1 text-white text-center`,
  css`
    width: ${progress}%;
  `,
]);

function ExitAlert({
  cancelHandler,
  exitHandler,
}) {
  const { t } = useTranslation('survey');

  const handleCloseSurvey = () => {
    exitHandler();
  };

  return (
    <div>
      <div className="py-4">
        <p className="font-bold pb-2">
          {t('surveylayout.exit_dialog.title')}
        </p>
      </div>
      <div className="flex justify-evenly relative">
        <button
          onClick={cancelHandler}
          className="btn-primary mx-1 w-1/2 "
        >
          {t('common.action.cancel')}
        </button>

        <button onClick={handleCloseSurvey} className="btn-danger mx-1 w-1/2">
          {t('common.action.exit_session')}
        </button>
      </div>
    </div>
  );
}
