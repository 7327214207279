/* eslint-disable import/prefer-default-export */

interface MaternityAsset {
  [key: string]: {
    alt: string;
    src: string;
  }
}

export const MATERNITY_ASSETS: MaternityAsset = {
  1: {
    alt: '',
    src: '',
  },
  2: {
    alt: '',
    src: '',
  },
  3: {
    alt: 'maternitycard.week_3',
    src: '/assets/maternity/week-3.png',
  },
  4: {
    alt: 'maternitycard.week_4',
    src: '/assets/maternity/week-4.png',
  },
  5: {
    alt: 'maternitycard.week_5',
    src: '/assets/maternity/week-5.png',
  },
  6: {
    alt: 'maternitycard.week_6',
    src: '/assets/maternity/week-6.png',
  },
  7: {
    alt: 'maternitycard.week_7',
    src: '/assets/maternity/week-7.png',
  },
  8: {
    alt: 'maternitycard.week_8',
    src: '/assets/maternity/week-8.png',
  },
  9: {
    alt: 'maternitycard.week_9',
    src: '/assets/maternity/week-9.png',
  },
  10: {
    alt: 'maternitycard.week_10',
    src: '/assets/maternity/week-10.png',
  },
  11: {
    alt: 'maternitycard.week_11',
    src: '/assets/maternity/week-11.png',
  },
  12: {
    alt: 'maternitycard.week_12',
    src: '/assets/maternity/week-12.png',
  },
  13: {
    alt: 'maternitycard.week_13',
    src: '/assets/maternity/week-13.png',
  },
  14: {
    alt: 'maternitycard.week_14',
    src: '/assets/maternity/week-14.png',
  },
  15: {
    alt: 'maternitycard.week_15',
    src: '/assets/maternity/week-15.png',
  },
  16: {
    alt: 'maternitycard.week_16',
    src: '/assets/maternity/week-16.png',
  },
  17: {
    alt: 'maternitycard.week_17',
    src: '/assets/maternity/week-17.png',
  },
  18: {
    alt: 'maternitycard.week_18',
    src: '/assets/maternity/week-18.png',
  },
  19: {
    alt: 'maternitycard.week_19',
    src: '/assets/maternity/week-19.png',
  },
  20: {
    alt: 'maternitycard.week_20',
    src: '/assets/maternity/week-20.png',
  },
  21: {
    alt: 'maternitycard.week_21',
    src: '/assets/maternity/week-21.png',
  },
  22: {
    alt: 'maternitycard.week_22',
    src: '/assets/maternity/week-22.png',
  },
  23: {
    alt: 'maternitycard.week_23',
    src: '/assets/maternity/week-23.png',
  },
  24: {
    alt: 'maternitycard.week_24',
    src: '/assets/maternity/week-24.png',
  },
  25: {
    alt: 'maternitycard.week_25',
    src: '/assets/maternity/week-25.png',
  },
  26: {
    alt: 'maternitycard.week_26',
    src: '/assets/maternity/week-26.png',
  },
  27: {
    alt: 'maternitycard.week_27',
    src: '/assets/maternity/week-27.png',
  },
  28: {
    alt: 'maternitycard.week_28',
    src: '/assets/maternity/week-28.png',
  },
  29: {
    alt: 'maternitycard.week_29',
    src: '/assets/maternity/week-29.png',
  },
  30: {
    alt: 'maternitycard.week_30',
    src: '/assets/maternity/week-30.png',
  },
  31: {
    alt: 'maternitycard.week_31',
    src: '/assets/maternity/week-31.png',
  },
  32: {
    alt: 'maternitycard.week_32',
    src: '/assets/maternity/week-32.png',
  },
  33: {
    alt: 'maternitycard.week_33',
    src: '/assets/maternity/week-33.png',
  },
  34: {
    alt: 'maternitycard.week_34',
    src: '/assets/maternity/week-34.png',
  },
  35: {
    alt: 'maternitycard.week_35',
    src: '/assets/maternity/week-35.png',
  },
  36: {
    alt: 'maternitycard.week_36',
    src: '/assets/maternity/week-36.png',
  },
  37: {
    alt: 'maternitycard.week_37',
    src: '/assets/maternity/week-37.png',
  },
  38: {
    alt: 'maternitycard.week_38',
    src: '/assets/maternity/week-38.png',
  },
  39: {
    alt: 'maternitycard.week_39',
    src: '/assets/maternity/week-39.png',
  },
  40: {
    alt: 'maternitycard.week_40',
    src: '/assets/maternity/week-40.png',
  },
};
