import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Card from '../../layout/Card';
import { Patient } from '../../../../patient-app-common/types/Patient';

interface PostopCardProps {
  patient: Patient;
  dischargeDate: moment.Moment | null;
}

export default function PostopCard({ patient, dischargeDate }: PostopCardProps) {
  const { t } = useTranslation('home');

  const { daysPostOp } = patient;

  const daysAtHome = moment()
    .diff(moment(dischargeDate), 'days') + 1;

  return (
    <div className="py-4" data-test="phase-card">
      <Card
        headerComp={(
          <div>
            <h2 className="font-bold">
              <span>{t('postopcard.countdown', { daysAtHome, daysPostOp })}</span>
            </h2>
          </div>
        )}
        bodyComp={
          <p>{t('postopcard.description')}</p>
        }
      />
    </div>
  );
}
