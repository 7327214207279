import moment from 'moment';
import _ from 'lodash';

export function labelForWeek(startDate) {
  // e.g. Dec 31 2019 - Jan 6 2020
  // e.g. Jan 1 - Jan 7, 2020
  if (!moment(startDate).isValid()) {
    return '';
  }

  const endDate = moment(startDate).add(6, 'days');

  if (startDate.year() !== endDate.year()) {
    return `${startDate.format('MMM D YYYY')} - ${endDate.format('MMM D YYYY')}`;
  }
  return `${startDate.format('MMM D')} - ${endDate.format('MMM D')}, ${endDate.format('YYYY')}`;
}

// this helper is used to pick specific key value pairs relevant to progress charts
// renamed some properties to make it more readable
export function pickVisualPropsForQuestion(question) {
  const copiedQuestion = {
    procedureId: question.procedureId,
    id: question.id,
    type: question.type,
    title: question.title,
    chartTitle: question.chartTitle || question.title,
    reverseY: question?.chartReverseYAxis,
    stepValue: _.toNumber(question.stepValue) || 5,
    unit: question.options.unit,
  };
  return copiedQuestion;
}

// the API sends an array of answer choices, regardless of question type, in this format:
// answers: [{ text: "", verbose: "" }, { text: "", verbose: "" }]
// this helper blanks out the array for the question types that do not have specific choices
// also renames the answer verbose to chartLabel for clarity on its usage
export function pickAnswerPropsForQuestionType(question) {
  switch (question.type) {
    case 'camera':
    case 'date':
    case 'device':
    case 'numerical_input':
    case 'text':
      return [];

    default:
      return question.answers.map((answer) => ({
        text: answer.text,
        chartLabel: answer.verbose || answer.text,
      }));
  }
}

// this helper validates the format of the patient entry
// invalid entries can happen when a published question was edited by mistake
export function validateEntry(question, patientAnswer) {
  const { answers, type } = question;

  switch (type) {
    case 'checkbox':
      return _.isArray(patientAnswer) && answers.length >= patientAnswer.length;

    case 'radio':
    case 'gen_scale':
    case 'picker':
      return _.isNumber(patientAnswer) && answers.length > patientAnswer;

    case 'numerical_input': {
      const value = _.toNumber(patientAnswer);
      return _.isNumber(value);
    }

    default:
      return true;
  }
}

// this helper gets all the patient entries for the specific question
export function pickEntriesForQuestion(question, surveyInstances) {
  const entries = _.filter(surveyInstances, ['procedureId', question.procedureId]);
  const entriesForQuestion = [];

  entries.forEach((entry) => {
    const questionEntry = {};

    if (_.has(entry.source, question.id)) {
      const patientAnswer = entry.source[question.id];
      const valid = validateEntry(question, patientAnswer);

      if (valid) {
        questionEntry.answer = patientAnswer;
        questionEntry.date = entry.createdAt;
        entriesForQuestion.push(questionEntry);
      }
    }
  });

  // sort by oldest first
  return entriesForQuestion.sort((a, b) => moment(a.date) - moment(b.date));
}

// this helper gets all chart questions from all surveys with the properties that we want
export function copyAndformatVisualQuestions(procedures, surveyInstances) {
  const formattedQuestions = [];

  procedures.forEach((procedure) => {
    const questions = procedure.questions.filter((question) => question.chart);

    questions.forEach((question) => {
      const entriesForQuestion = pickEntriesForQuestion(question, surveyInstances);

      // include questions that have entries
      if (entriesForQuestion.length) {
        const questionCopy = pickVisualPropsForQuestion(question);
        questionCopy.surveyName = procedure.patientDisplayName;
        questionCopy.answers = pickAnswerPropsForQuestionType(question);
        questionCopy.patientEntries = entriesForQuestion;
        formattedQuestions.push(questionCopy);
      }
    });
  });

  return formattedQuestions;
}

// this helper converts the answer from the survey instance into a human readable format
export function answerToChartLabel(chartQuestion, patientAnswer) {
  switch (chartQuestion.type) {
    case 'checkbox': {
      const selectedList = _.filter(chartQuestion.answers, (_checkbox, i) => patientAnswer[i]);
      const labels = _.map(selectedList, 'chartLabel').join(', ');
      return labels;
    }

    case 'radio':
    case 'gen_scale':
    case 'picker':
      return chartQuestion.answers[patientAnswer].chartLabel;

    default:
      return patientAnswer;
  }
}
