import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BiPaperclip } from 'react-icons/bi';
import tw, { styled } from 'twin.macro';

import { ThreadPayload } from '../../../patient-app-common/types/SecureMessageThreads';

interface MsgListItemProps {
  selected: boolean;
  thread: ThreadPayload;
}
export function ThreadListItem({ selected, thread }: MsgListItemProps) {
  const { t } = useTranslation();
  let displayDate = moment(thread.lastMessageSentAt).format('MMM D');

  if (moment(thread.lastMessageSentAt).isSame(moment(), 'day')) {
    displayDate = moment(thread.lastMessageSentAt).format('hh:mm a');
  }

  return (
    <Li
      className="p-4 text-sm"
      selected={selected}
    >
      {thread.unreadMessage && (
        <div className="mb-2"><Label>{t('common.text.new')}</Label></div>
      )}
      <div className="flex justify-between">
        <p className={thread.unreadMessage ? 'font-bold truncate' : 'font-normal truncate'}>
          {_.last(thread.messages)?.sentByName}
        </p>
        <p className={thread.unreadMessage ? 'font-semibold' : 'font-light'}>
          <span className="text-sm">
            {displayDate}
          </span>
        </p>
      </div>
      <p className={thread.unreadMessage ? 'font-semibold' : 'font-light'}>
        <span className="truncate block">
          {_.last(thread.messages)?.text}
        </span>
        {_.last(thread.messages)?.file && (
          <>
            <BiPaperclip className="inline-flex" />
            <span className="italic">{t('common.text.attachment')}</span>
          </>
        )}
      </p>
    </Li>
  );
}

const Li = styled.li(({ selected } : MsgListItemProps) => [
  tw`block`,
  selected && tw`bg-teal-30`,
  tw`border-gray-400 border-b`,
  tw`hover:bg-teal-10`,
]);

const Label = styled.span(() => [
  tw`bg-blue-10 text-blue-100 text-xs p-1`,
]);
