import React from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import tw, { styled } from 'twin.macro';
import { Modal, NavBar } from '../layout';
import {
  Profile,
  Caregiver,
  Careplans,
  Language,
  Notifications,
  SetPwd,
} from './components';
import Account from './components/Account';
import packageJson from '../../../package.json';
import useModal from '../../hooks/useModal';
import { useGetPatientQuery } from '../../patient-app-common/api/patientApi';
import { useGetProcedureSetsQuery } from '../../patient-app-common/api/procedureApi';


export default function Settings() {
  const { t } = useTranslation();
  const { visible, toggleModal } = useModal();

  const {
    data: patient,
  } = useGetPatientQuery();

  const {
    data: procedureSets,
  } = useGetProcedureSetsQuery();

  const [html, setHTML] = React.useState('<div />');
  const mainRef = React.useRef(null);

  const getHTML = async (url) => {
    try {
      const response = await axios.get(url);
      const data = await response.data;

      return { status: response.status, data };
    } catch (e) {
      console.log(`Setup.js getHTML error: ${e}`);
      if (e.response) {
        return { status: e.response.status };
      }
      return { status: 0 };
    }
  };

  const handleInfoBtnClick = async (option) => {
    const { hostname } = window.location;
    // for local env use http://localhost:9292/ (not https);
    const privacyURL = `https://${hostname}/api/privacy`;
    const termsURL = `https://${hostname}/api/eula`;

    let backupURL = '';
    let html = '';

    if (option === 'terms') {
      backupURL = 'https://seamless.md/eula/';
      html = await getHTML(termsURL);
    }

    if (option === 'privacy') {
      backupURL = 'https://seamless.md/privacy/';
      html = await getHTML(privacyURL);
    }

    if (html.data) {
      setHTML(html.data);
      toggleModal();
    } else {
      window.open(backupURL, '_blank');
    }
  };

  const date = moment(packageJson.buildDate)
    .locale('en')
    .format('MMMM Do YYYY, h:mm a');

  if (!patient || !procedureSets) return null;

  return (
    <>
      <Modal
        visible={visible}
        handleCloseClick={toggleModal}
        // eslint-disable-next-line react/no-danger
        component={<div dangerouslySetInnerHTML={{ __html: html }} />}
      />
      <div data-test="settings-component" className="bg-gray-100 h-full min-h-screen">
        <NavBar skipHandler={() => mainRef.current.focus()} />
        <Container
          aria-label={t('settings.arialabel')}
          role="region"
          tabIndex={0}
          ref={mainRef}
        >
          <SettingsContainer>
            <Profile patient={patient} />
            <Caregiver contacts={patient.contacts} />

            {procedureSets.length > 1 && (
              <Careplans
                procedureSets={procedureSets}
                procedureName={patient.procedureName}
              />
            )}

            {patient.languages.length > 1 && (
              <Language
                languageId={patient.languageId}
                languages={patient.languages}
              />
            )}

            <Notifications
              emailNotification={patient.emailNotification}
              phoneNotification={patient.phoneNotification}
            />

            <Account
              patient={patient}
            />

            <SetPwd />

            <BtnLink onClick={() => handleInfoBtnClick('terms')}>{t('common.terms_of_use')}</BtnLink>
            <BtnLink onClick={() => handleInfoBtnClick('privacy')}>{t('common.privacy_policy')}</BtnLink>
          </SettingsContainer>
        </Container>
        <div className="flex justify-center pb-4">
          <p>
            Last updated: {date}
          </p>
        </div>
      </div>
    </>
  );
}

const Container = styled.div(() => [
  tw`flex justify-center w-full`,
  tw`p-4 md:p-8 `,
  tw`pt-16 md:pt-24`,
]);

const BtnLink = styled.button(() => [
  tw`cursor-pointer p-4 text-left w-full`,
  tw`text-lg sm:text-xl`,
  tw`hover:bg-teal-30`,
]);

const SettingsContainer = styled.div(() => [
  tw`bg-white divide-y rounded-lg shadow`,
  tw`w-full md:w-3/4`,
]);
