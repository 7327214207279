import React, { useState } from 'react';
import moment from 'moment';
import tw, { styled } from 'twin.macro';
import DateInput from '../layout/DateInput';
import LoaderInline from '../layout/LoaderInline';
import Panel from '../layout/Panel';
import { useUpdatePatientMutation } from '../../patient-app-common/api/patientApi';

export default function DemoDates({ patient }) {
  const [error, setError] = useState('');

  const [signupDate, setSignupDate] = useState(null);
  const [surgeryDate, setSurgeryDate] = useState(null);
  const [dischargeDate, setDischargeDate] = useState(null);

  const [updatePatient, { isLoading }] = useUpdatePatientMutation();

  const setProgramDate = (date, dateType) => {
    if (dateType === 'createdAt' && !date) {
      setError('Please select a signup date.');
      return;
    }

    if (dateType === 'surgeryDate' && !date) {
      setError('Please select a surgery date.');
      return;
    }

    if (dateType === 'dischargeDate' && !date) {
      setError('Please select a discharge date.');
      return;
    }

    setError('');

    const params = { completedDate: 0 };
    params[dateType] = moment(date).unix();

    try {
      updatePatient(params);
    } catch (e) {
      setError('A temporary error occurred. Please try again later.');
    }
  };

  return (
    <Panel title="Set patient account dates">
      <p className="px-0 sm:px-12">You can manually set the demo patient account dates here.</p>
      <div className="flex justify-center py-4">
        <Container>
          <label htmlFor="signup">Sign up date</label>
          <FieldDiv>
            <DateDiv>
              <StyledDateInput id="signup" sendDate={(date) => setSignupDate(date)} />
            </DateDiv>

            <BtnDiv>
              <button
                onClick={() => setProgramDate(signupDate, 'createdAt')}
                className="btn-primary"
              >
                Set sign up date
              </button>
            </BtnDiv>
          </FieldDiv>

          {!patient.nonSurgicalProgram && (
            <>
              <label htmlFor="surgery">Surgery date</label>
              <FieldDiv>
                <DateDiv>
                  <StyledDateInput id="surgery" sendDate={(date) => setSurgeryDate(date)} />
                </DateDiv>

                <BtnDiv>
                  <button
                    onClick={() => setProgramDate(surgeryDate, 'surgeryDate')}
                    className="btn-primary"
                  >
                    Set surgery date
                  </button>
                </BtnDiv>
              </FieldDiv>

              <label htmlFor="discharge">Discharge date</label>
              <FieldDiv>
                <DateDiv>
                  <StyledDateInput id="discharge" sendDate={(date) => setDischargeDate(date)} />
                </DateDiv>

                <BtnDiv>
                  <button
                    onClick={() => setProgramDate(dischargeDate, 'dischargeDate')}
                    className="btn-primary"
                  >
                    Set discharge date
                  </button>
                </BtnDiv>
              </FieldDiv>
            </>
          )}

          <div className="text-center">
            {isLoading && <LoaderInline />}
            <p className="error-text">{error}</p>
          </div>
        </Container>
      </div>
    </Panel>
  );
}

const Container = styled.div(() => [
  tw`bg-blue-10 border border-gray-500 flex-col justify-center p-4 rounded`,
  tw`w-full lg:w-3/4`,
]);

const FieldDiv = styled.div(() => [
  tw`pb-4 w-full`,
  tw`flex-none lg:inline-flex`,
]);

const DateDiv = styled.div(() => [
  tw`p-4 lg:p-0`,
  tw`w-full lg:w-2/3`,
]);

const StyledDateInput = styled(DateInput)(() => [
  tw`block form-input w-full`,
  tw`hover:border-blue-100`,
]);

const BtnDiv = styled.div(() => [
  tw`place-self-center px-4`,
  tw`text-sm lg:text-base`,
]);
