import React from 'react';
import _ from 'lodash';
import tw, { styled } from 'twin.macro';
import SurveyHeader from './SurveyHeader';
import SurveyFooter from './SurveyFooter';
import Modal from '../../layout/Modal';
import useModal from '../../../hooks/useModal';

export default function SurveyLayout({
  backHandler,
  children,
  disableClose,
  exitHandler,
  index,
  nextHandler,
  progress,
  showBack,
  subtitle,
  surveyName,
  review,
  title,
  question,
}) {
  const { visible, toggleModal } = useModal();

  return (
    <div className="flex-1 h-full min-h-screen bg-gray-100">
      <SurveyHeader
        disableClose={disableClose}
        exitHandler={exitHandler}
        surveyName={surveyName}
        index={index}
        progress={progress}
      />
      <section
        className="py-12 px-4 lg:px-48"
        style={{ minHeight: '50vh' }}
      >
        <p className="font-bold pb-4 text-lg">
          {title || question?.title}
        </p>
        <Subtitle
          className="pb-4"
          questionType={question?.type || false}
        >
          {subtitle || question?.subtitle}
        </Subtitle>
        {!_.isEmpty(question?.options?.html) && (
          <>
            <Modal
              visible={visible}
              handleCloseClick={toggleModal}
              // eslint-disable-next-line react/no-danger
              component={<div dangerouslySetInnerHTML={{ __html: question.options.html }} />}
              width="1000px"
              showFooter
            />
            <div className="flex justify-center p-4">
              <button className="btn-secondary" onClick={toggleModal}>
                {question.options.exampleText}
              </button>
            </div>
          </>
        )}
        {children}
      </section>
      <SurveyFooter
        backHandler={backHandler}
        nextHandler={nextHandler}
        index={index}
        showBack={showBack}
        review={review}
      />
    </div>
  );
}

// temporarily hide question subtitle from device type question
// as the content for connecting to bluetooth is not available in web yet
const Subtitle = styled.p(({ questionType }) => [
  tw`pb-4`,
  questionType === 'device' && tw`hidden`,
]);
