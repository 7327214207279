import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ImSpinner8 } from 'react-icons/im';
import { AiFillQuestionCircle } from "react-icons/ai"
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

import useSegment from '../../hooks/useSegment';
import {
  useSignupMutation,
  useSetupMutation,
} from '../../patient-app-common/api/authApi';
import { isEmail } from '../../patient-app-common/utils';

import FormContainer from './layout/FormContainer';
import ConsentForm from './layout/ConsentForm';
import { DateInput } from '../layout';


const SUBMIT_FORM_EVENT = 'Signup final button clicked';
const SIGNUP_ERROR = 'Signup failed';

/* eslint-disable no-useless-escape */
/* eslint-disable no-fallthrough */
export default function AccountForm({ fields, token }) {
  const { t } = useTranslation('login');
  const { pathname } = useLocation();
  const { sendTrackEvent } = useSegment();

  let action;

  if (_.startsWith(pathname, '/setup')) {
    action = 'setup';
  }

  if (_.startsWith(pathname, '/signup')) {
    action = 'signup';
  }

  const [signup, { isLoading: isLoadingSignup }] = useSignupMutation();
  const [setup, { isLoading: isLoadingSetup }] = useSetupMutation();

  const isLoading = isLoadingSignup || isLoadingSetup;

  const { token: patientToken } = useSelector((state) => state.auth);

  const errorDiv = useRef(null);

  const [consentInputs, setConsentInputs] = useState([]);
  const [userConsent, setUserConsent] = useState(true);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [inputs, setInputs] = useState(_.cloneDeep(fields));
  const [errorStr, setErrorStr] = useState('');
  const [missingInputs, setMissingInputs] = useState([]);

  const [termsCheckbox, setTermsCheckbox] = useState(false);

  useEffect(() => {
    if (!inputs.length) return;

    const formAfterConsent = [...inputs];
    const extractedConsentFields = _.remove(
      formAfterConsent,
      (input) => input.fieldCode.indexOf('consent') > -1,
    );

    if (extractedConsentFields.length) {
      setUserConsent(false);
      setConsentInputs(extractedConsentFields);
      setInputs(formAfterConsent);
    }
    
  }, []);

  useEffect(() => {
    const { valid, error } = validatePassword();

    if (valid) {
      setErrorStr('');
    } else {
      setErrorStr(error);
    }
  }, [password, confirmPassword]);

  const handleInfoBtnClick = async (option) => {
    let url = '';

    if (option === 'terms') {
      url = 'https://seamless.md/eula/';
    }

    if (option === 'privacy') {
      url = 'https://seamless.md/privacy/';
    }

    window.open(url, '_blank');
  };

  const handleFormChange = (fieldCode, value) => {
    const consentInputsCopy = [...consentInputs];
    const inputsCopy = [...inputs];

    consentInputsCopy.forEach((field) => {
      if (field.fieldCode === fieldCode) {
        field.value = value;
      }
    });
    setConsentInputs(consentInputsCopy);

    inputsCopy.forEach((field) => {
      if (field.fieldCode === fieldCode) {
        field.value = value;
      }
    });

    setInputs(inputsCopy);
  };

  const validateUser = (value, fieldCode) => {
    const result = {
      valid: true,
      error: '',
    };

    if (fieldCode === 'username' && !/^[a-zA-Z0-9]*$/.test(value)) {
      result.error = `${t('accountform.error.invalid_user')}\n`;
      result.valid = false;
    }

    if (fieldCode === 'email' && !isEmail(value)) {
      result.error = `${t('common.error.invalid_email')}\n`;
      result.valid = false;
    }
    return result;
  };

  const validatePassword = () => {
    const result = {
      valid: true,
      error: '',
    };

    const hasNumber = /[0-9]/.test(password);

    if (password && (password.length < 8 || !hasNumber)) {
      result.error += `${t('common.error.invalid_password')}\n`;
      result.valid = false;
    }

    if (password !== confirmPassword) {
      result.error += `${t('common.error.confirm_password')}\n`;
      result.valid = false;
    }

    return result;
  };

  const validateConsent = (consentCheckbox) => {
    const result = {
      valid: true,
      error: '',
    };

    if (consentCheckbox.required && consentCheckbox.value !== 'true') {
      result.error = t('accountform.error.consent_required');
      result.valid = false;
    } else if (!consentCheckbox.value) {
      result.error = t('accountform.error.consent_blank');
      result.valid = false;
    }

    return result;
  };

  const validateFieldFormat = (field) => {
    const result = {
      valid: true,
      error: '',
    };

    if (!_.has(field, 'fieldFormat') || !field.value) return result;

    const regex = new RegExp(field.fieldFormat);
    result.valid = regex.test(field.value);

    if (!result.valid) {
      result.error = `Invalid format for ${field.fieldName} \n`;
    }

    return result;
  };

  const validateForm = (formFields) => {
    let validated = true;
    const missing = [];
    let errors = '';

    const { valid: validPassword, error: errorPassword } = validatePassword();
    if (!validPassword) {
      validated = false;
      errors += errorPassword;
      missing.push('password');
    }

    formFields.forEach((input) => {
      const consentCheck = (input.fieldCode === 'consent' && input.fieldType === 'checkbox');
      const isBlank = input.required && input.visible && !input.value;

      const { valid: validFormat, error: errorFormat } = validateFieldFormat(input);
      if (!validFormat) {
        validated = false;
        errors += errorFormat;
        missing.push(input.fieldCode);
      }

      if (consentCheck) {
        const { valid: validConsent, error: errorConsent } = validateConsent(input);
        if (!validConsent) {
          missing.push(input.fieldCode);
          validated = false;
          errors += errorConsent;
        }
      } else if (isBlank) {
        missing.push(input.fieldCode);
        errors += `${t('accountform.error.blank_input', { input })}\n`;
        validated = false;
      }

      if (input.fieldCode === 'email' || input.fieldCode === 'username') {
        const { valid: validUser, error: errorUser } = validateUser(input.value, input.fieldCode);
        if (!validUser) {
          missing.push(input.fieldCode);
          validated = false;
          errors += errorUser;
        }
      }
    });

    setErrorStr(errors);
    setMissingInputs(missing);
    return validated;
  };

  const handleDateChange = (fieldCode, date) => {
    let dateStr = moment(date).locale('en').format('MM/DD/YYYY');

    if (!moment(dateStr).isValid()) {
      dateStr = '';
    }

    handleFormChange(fieldCode, dateStr);
  };

  const handleConsentPress = () => {
    const validated = validateForm(consentInputs);
    if (!validated) return;
    setUserConsent(true);
  };

  const dispatchSetup = async () => {
    try {
      const result = await setup({ token, fields: inputs.concat(consentInputs) }).unwrap();

      if (result.error) {
        handleValidationError(result.error, result.field);
      }
    } catch (error) {
      setErrorStr(`${t('common.error.server')}: ${JSON.stringify(error)}
      `);
    }
    errorDiv.current && errorDiv.current.focus();
  };

  const dispatchSignup = async () => {
    try {
      const result = await signup({ token, fields: inputs.concat(consentInputs) }).unwrap();

      if (result.error) {
        handleValidationError(result.error);
        sendTrackEvent(SIGNUP_ERROR, { ...result, token });
      }
    } catch (error) {
      setErrorStr(t('common.error.server'));

      if (_.isObject(error)) {
        setErrorStr(`${t('common.error.server')}: ${JSON.stringify(error)}
        `);
        sendTrackEvent(SIGNUP_ERROR, { ...error, token });
      }
    }
    errorDiv.current && errorDiv.current.focus();
  };

  const handleValidationError = (resultError, resultField) => {
    switch (resultError) {
      case 'duplicate_email':
      case 'duplicate_user':
        setMissingInputs(['email', 'username']);
        setErrorStr(t('accountform.error.duplicate_user'));
        break;

      case 'info_verification_failed':
        setMissingInputs(resultField);
        setErrorStr(t('accountform.error.info_verification'));
        break;

      case 'info_blank':
        setMissingInputs(resultField);
        setErrorStr(t('accountform.error.info_blank'));
        break;

      default:
        setErrorStr(`${t('common.error.server')}: ${resultError}
        `);
    }
  };

  const handleSubmitPress = () => {
    const validated = validateForm(inputs);
    if (!validated) {
      errorDiv.current && errorDiv.current.focus();
      return;
    }

    if (!termsCheckbox) {
      setErrorStr(t('accountform.error.terms'));
      setMissingInputs('terms');
      return;
    }

    if (action === 'signup') {
      dispatchSignup();
    }

    if (action === 'setup') {
      dispatchSetup();
    }
  };

  const getInput = (field) => {
    const error = missingInputs.includes(field.fieldCode);
    let inputType = 'text';

    switch (field.fieldType) {
      case 'email':
      case 'phone':
        inputType = field.fieldType;
      case 'string':
        return (
          <div className="pb-4">
            <label htmlFor={field.fieldCode}>
              {field.fieldName}
              <FormFieldHint text={field.hint}>
                <Input
                  id={field.fieldCode}
                  type={inputType}
                  onChange={(e) => handleFormChange(field.fieldCode, e.target.value)}
                  error={error}
                />
              </FormFieldHint>
            </label>
          </div>
        );

      case 'password':
        return (
          <div>
            <div className="pb-4">
              <label htmlFor={field.fieldCode}>
                {field.fieldName}
                <FormFieldHint text={field.hint}>
                  <Input
                    id={field.fieldCode}
                    type="password"
                    onChange={(e) => {
                      setPassword(e.target.value);
                      handleFormChange(field.fieldCode, e.target.value);
                    }}
                    error={error}
                  />
                </FormFieldHint>
              </label>
            </div>

            <div className="pb-4">
              <label htmlFor="confirm">
                {t('common.input.confirm_password')}
                <Input
                  id="confirm"
                  type="password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  error={missingInputs.includes('password')}
                />
              </label>
            </div>
          </div>
        );

      case 'date':
        return (
          <div className="pb-4">
            <label htmlFor={field.fieldCode}>
              {field.fieldName}
              <FormFieldHint text={field.hint}>
                <StyledDateInput
                  id={field.fieldCode}
                  sendDate={(date) => handleDateChange(field.fieldCode, date)}
                  error={error}
                />
              </FormFieldHint>
            </label>
          </div>
        );

      case 'dropdown':
        return (
          <div className="pb-4">
            <label htmlFor={field.fieldCode}>
              {field.fieldName}
              <FormFieldHint text={field.hint}>
                <Select
                  id={field.fieldCode}
                  defaultValue=""
                  onChange={(e) => handleFormChange(field.fieldCode, e.target.value)}
                  error={error}
                >
                  <option disabled hidden value="">
                    {t('common.select.placeholder')}
                  </option>
                  {field.options.map((option) => (
                    <option key={option[1]} value={option[1]}>
                      {option[0]}
                    </option>
                  ))}
                </Select>
              </FormFieldHint>
            </label>
          </div>
        );

      case 'checkbox':
        return (
          <div className="flex justify-center">
            <div className="flex flex-col">
              { field.options.map((option) => (
                <label 
                  className="pt-4" 
                  htmlFor={`consent-${option.checkboxType}`}
                  key={`consent-${option.checkboxType}`}
                >
                  <Checkbox
                    id={`consent-${option.checkboxType}`}
                    name={`consent-${option.checkboxType}`}
                    type="checkbox"
                    checked={field.value === option.checkboxType}
                    onChange={() => handleFormChange(field.fieldCode, option.checkboxType)}
                  />
                  <span className="font-bold">
                    {option.checkboxText}
                  </span>
                </label>
              ))}
            </div>
          </div>
        );

      case 'consent_form':
        return <ConsentForm field={field} />;

      default:
        return <div />;
    }
  };

  if (patientToken) {
    return <Navigate to="/" replace />;
  }

  const ConsentFragment = () => (
    <>
      <div>
        {consentInputs
          .filter((field) => field.visible === true)
          .map((field) => (
            <div key={field.fieldName}>{getInput(field)}</div>
          ))}
      </div>

      <div className="p-4 text-center">
        <NextBtn
          onClick={() => handleConsentPress()}
          className="btn-primary w-1/2"
        >
          {t('common.action.next')}
        </NextBtn>
      </div>
    </>
  );

  const FormFragment = () => (
    <>
      <div>
        {inputs
          .filter((field) => field.visible === true)
          .map((field) => (
            <div key={field.fieldName}>{getInput(field)}</div>
          ))}
      </div>

      <div className="py-4 text-center">
        <p>{t('accountform.text.review_terms')}</p>
        <div className="flex flex-col py-4">
          <InfoBtn onClick={() => handleInfoBtnClick('terms')}>
            {t('common.terms_of_use')}
          </InfoBtn>
          <InfoBtn onClick={() => handleInfoBtnClick('privacy')}>
            {t('common.privacy_policy')}
          </InfoBtn>
        </div>
        <label className="block" htmlFor="terms">
          <Checkbox
            id="terms"
            name="terms"
            type="checkbox"
            checked={termsCheckbox}
            onChange={() => setTermsCheckbox(true)}
            error={missingInputs.includes('terms')}
          />
          <span>
            {t('accountform.checkbox')}
          </span>
        </label>

        <button
          onClick={() => {
            handleSubmitPress();
            sendTrackEvent(SUBMIT_FORM_EVENT);
          }}
          className="btn-primary w-full md:w-1/2 mt-4"
          disabled={isLoading}
        >
          {isLoading && (
            <span className="inline-flex mr-2">
              <ImSpinner8 className="animate-spin" />
            </span>
          )}
          {t('accountform.button.submit')}
        </button>
      </div>
    </>
  );

  return (
    <>
      <FormContainer role="form" testID="account-form-component">
        <h1 className="text-3xl p-3">
          {t('accountform.title')}
        </h1>
        <div className="text-left mx-10">
          {(!_.isEmpty(errorStr)) && (
            <div className="p-6" ref={errorDiv} role="alert" tabIndex={0}>
              <p className="error-text whitespace-pre-line">
                {errorStr}
              </p>
            </div>
          )}
          {!userConsent ? ConsentFragment() : FormFragment()}
        </div>
      </FormContainer>
    </>
  );
}

export const FormFieldHint = ({ children, text }) => {
  const [visible, setVisible] = useState(false);

  if (!text) {
    return (
      <div>{children}</div>
    )
  }

  return (
    <div>
      <div className='inline-flex w-full items-center'>
        {children}
        <button className="ml-1 text-lg" onClick={() => setVisible(!visible)}>
          <AiFillQuestionCircle className="text-blue-100" />
        </button>
      </div>
      {visible && (
        <div className='flex-none'>
          <p>
            {text}
          </p>
        </div>
      )}
    </div>
  )
}

const Checkbox = styled.input(({ error }) => [
  tw`form-checkbox h-4 text-blue-100 mr-4 w-4`,
  tw`border-2 border-black`,
  error && tw`border-red`,
]);

const NextBtn = styled.button(({ disabled }) => [
  tw`w-1/2`,
  disabled && tw`bg-gray-500`,
]);

const Input = styled.input(({ error }) => [
  tw`form-input block w-full`,
  tw`hover:border-blue-100`,
  error && tw`border-red`,
]);

const StyledDateInput = styled(DateInput)(({ error }) => [
  tw`form-input block w-full`,
  tw`hover:border-blue-100`,
  error && tw`border-red`,
]);

const Select = styled.select(({ error }) => [
  tw`form-select block w-full`,
  error && tw`border-red`,
]);

const InfoBtn = styled.button(() => [
  tw`text-blue-100 text-center`,
  tw`hover:underline`,
]);
