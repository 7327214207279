import React from "react";
import { Link, useLocation } from "react-router-dom";
import tw, { css, styled } from "twin.macro";
import useModal from "../../../hooks/useModal";
import Modal from "../../layout/Modal";
import OpioidChart from "./OpioidChart";

const icon = `${process.env.PUBLIC_URL}/assets/icons/med-line.png`;

export default function OpioidResult() {
  const { state } = useLocation();
  const { visible, toggleModal } = useModal();

  const mainRef = React.useRef(null);

  const result = state?.result ?? 0;
  const average = state?.average ?? 0;
  const education = state?.education ?? [];

  const patientScore = Math.round(result * 100);
  const peerAvg = average * 100;
  const scoreColor = patientScore > peerAvg ? "red" : "green";
  const altText = `A bar chart compares your risk of opioid use to your peers. Your score of ${patientScore}%
    is represented by the ${scoreColor} bar. The average score of your peers is ${peerAvg}% as represented by
    the orange bar.`;

  const createEduModal = () => {
    let html = "";

    if (!education) {
      return (<div />);
    }

    education.forEach((component, i) => {
      html += component.html;

      if (i !== education.length - 1) {
        html += "<hr>";
      }
    });

    return (
      <div
        data-test="rec-edu-html"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: html }}
      />
    );
  };

  const eduModal = createEduModal();

  const getChartData = () => {
    const data = [
      { group: "me", answer: patientScore, fill: scoreColor },
      { group: "peers", answer: peerAvg, fill: "#FF8C26" },
    ];
    return data;
  };

  return (
    <div>
      <Modal
        visible={visible}
        handleCloseClick={toggleModal}
        component={eduModal}
      />

      <HeaderContainer>
        <div className="flex-1">
          <h1 className="text-xl">Your recommendations</h1>
        </div>
        <div>
          <SkipBtn onClick={() => mainRef.current.focus()}>
            Skip to content
          </SkipBtn>
          <button className="btn-secondary mx-1">Help</button>
        </div>
      </HeaderContainer>

      <ResultsContainer
        aria-label="recommendations"
        ref={mainRef}
        role="region"
        tabIndex={0}
      >
        <div className="flex justify-center pt-8">
          <CardContainer>
            <HeaderDiv>
              <IconDiv>
                <img className="h-16 md:mt-4 w-16" src={icon} alt="" />
              </IconDiv>

              <TitleDiv>
                <p className="text-2xl md:text-3xl text-white">
                  Risk for opioid use after discharge
                </p>
              </TitleDiv>
            </HeaderDiv>

            <div className="p-6">
              <p>
                You are at {patientScore}% risk of opioid use after discharge. The average risk of your peer group is {peerAvg}%.
              </p>

              <div className="mx-auto w-full" role="figure">
                <OpioidChart
                  altText={altText}
                  data={getChartData()}
                  yLimits={[0, 100]}
                />
              </div>

              <p> Learn more about your score and opioid risk by tapping the 'Learn more' button.</p>

              <div className="flex justify-center pt-6">
                <button
                  data-test="edu-btn"
                  className="btn-secondary hover:bg-teal-10 w-3/4"
                  onClick={toggleModal}
                >
                  Learn more
                </button>
              </div>

              <p className="pt-6">Lifestyle modifications can change this score. Check how your score changes when you answer in a different way!</p>
            </div>
          </CardContainer>
        </div>

        <div className="h-1/4" />
      </ResultsContainer>

      <FooterContainer>
        <Link className="flex justify-center p-3 w-full" to="/">
          <FooterBtn className="btn-primary">Check your score again!</FooterBtn>
        </Link>
      </FooterContainer>
    </div>
  );
}

const HeaderContainer = styled.header(() => [
  tw`bg-white border-b border-gray-500 py-2`,
  tw`flex flex-wrap items-center`,
  tw`lg:px-16 px-6`,
  tw`top-0`,
]);

const SkipBtn = styled.button(() => [
  tw`text-white`,
  tw`focus:text-black`,
]);

const ResultsContainer = styled.div(() => [
  tw`bg-gray-100`,
  css`height: calc(100vh - 3.6875rem)`,
]);

const CardContainer = styled.div(() => [
  tw`bg-white border border-gray-500 rounded shadow`,
  tw`w-full lg:w-1/2`,
]);

const HeaderDiv = styled.div(() => [
  tw`inline-flex w-full p-2 border-b border-gray-500`,
  css`background-color: #9B51E0`,
]);

const IconDiv = styled.div(() => [
  tw`flex justify-center`,
  css`width: 15%`,
]);

const TitleDiv = styled.div(() => [
  tw`p-6`,
  css`width: 85%`,
]);

const FooterContainer = styled.footer(() => [
  tw`bg-white border-gray-300 border-t p-4 shadow-top w-full`,
  tw`bottom-0 fixed z-10`,
]);

const FooterBtn = styled.button(() => [
  tw`shadow w-1/3`,
  tw`text-lg md:text-2xl`,
]);
