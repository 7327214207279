// TODO: run script on rails side to standardize knee picker question pieces
export const ORTHO_ASSETS = {
  'knee-bend-0.png': '/assets/trackerassets/ortho/knee-bend-angles-0.png',
  'knee-bend-10.png': '/assets/trackerassets/ortho/knee-bend-angles-10.png',
  'knee-bend-20.png': '/assets/trackerassets/ortho/knee-bend-angles-20.png',
  'knee-bend-30.png': '/assets/trackerassets/ortho/knee-bend-angles-30.png',
  'knee-bend-40.png': '/assets/trackerassets/ortho/knee-bend-angles-40.png',
  'knee-bend-50.png': '/assets/trackerassets/ortho/knee-bend-angles-50.png',
  'knee-bend-60.png': '/assets/trackerassets/ortho/knee-bend-angles-60.png',
  'knee-bend-70.png': '/assets/trackerassets/ortho/knee-bend-angles-70.png',
  'knee-bend-80.png': '/assets/trackerassets/ortho/knee-bend-angles-80.png',
  'knee-bend-90.png': '/assets/trackerassets/ortho/knee-bend-angles-90.png',
  'knee-bend-100.png': '/assets/trackerassets/ortho/knee-bend-angles-100.png',
  'knee-bend-110.png': '/assets/trackerassets/ortho/knee-bend-angles-110.png',
  'knee-bend-120.png': '/assets/trackerassets/ortho/knee-bend-angles-120.png',
  'knee-bend-angles-0.png': '/assets/trackerassets/ortho/knee-bend-angles-0.png',
  'knee-bend-angles-10.png': '/assets/trackerassets/ortho/knee-bend-angles-10.png',
  'knee-bend-angles-20.png': '/assets/trackerassets/ortho/knee-bend-angles-20.png',
  'knee-bend-angles-30.png': '/assets/trackerassets/ortho/knee-bend-angles-30.png',
  'knee-bend-angles-40.png': '/assets/trackerassets/ortho/knee-bend-angles-40.png',
  'knee-bend-angles-50.png': '/assets/trackerassets/ortho/knee-bend-angles-50.png',
  'knee-bend-angles-60.png': '/assets/trackerassets/ortho/knee-bend-angles-60.png',
  'knee-bend-angles-70.png': '/assets/trackerassets/ortho/knee-bend-angles-70.png',
  'knee-bend-angles-80.png': '/assets/trackerassets/ortho/knee-bend-angles-80.png',
  'knee-bend-angles-90.png': '/assets/trackerassets/ortho/knee-bend-angles-90.png',
  'knee-bend-angles-100.png': '/assets/trackerassets/ortho/knee-bend-angles-100.png',
  'knee-bend-angles-110.png': '/assets/trackerassets/ortho/knee-bend-angles-110.png',
  'knee-bend-angles-120.png': '/assets/trackerassets/ortho/knee-bend-angles-120.png',
  'knee-straightening-0.png': '/assets/trackerassets/ortho/knee-straightening-0.png',
  'knee-straightening-05.png': '/assets/trackerassets/ortho/knee-straightening-5.png',
  'knee-straightening-5.png': '/assets/trackerassets/ortho/knee-straightening-5.png',
  'knee-straightening-10.png': '/assets/trackerassets/ortho/knee-straightening-10.png',
  'knee-straightening-15.png': '/assets/trackerassets/ortho/knee-straightening-15.png',
  'knee-straightening-20.png': '/assets/trackerassets/ortho/knee-straightening-20.png',
  'knee-straightening-25.png': '/assets/trackerassets/ortho/knee-straightening-25.png',
  'knee-straightening-30.png': '/assets/trackerassets/ortho/knee-straightening-30.png',
};
