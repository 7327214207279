import { useEffect, useState } from 'react';
import { useAppSelector } from '../redux/hooks';
import { useGetHospitalQuery } from '../patient-app-common/api/hospitalApi';
import { DOMAINS } from '../constants/domains';

export default function useHospitalFlags() {
  const { hostname } = window.location;
  let subdomain = hostname.split('.')[0];

  if (DOMAINS.LOCAL.includes(hostname)) {
    subdomain = 'test-hospital';
  }

  const { route } = useAppSelector(({ app }) => app);
  const { data: hospital } = useGetHospitalQuery(subdomain, { skip: (route === null) });

  const [hasOTALink, setHasOTALink] = useState(false);
  const [hasRecaptcha, setHasRecaptcha] = useState(false);
  const [hasSignup, setHasSignup] = useState(false);
  const [langSelector, setLangSelector] = useState(false);
  const [hideMobileLink, setHideMobileLink] = useState(false);

  const setFlags = (features) => {
    features.forEach((feature) => {
      switch (feature) {
        case 'sf_hospital_web_ontario_trusted_account':
          setHasOTALink(true);
          break;

        case 'sf_hospital_web_recaptcha':
          setHasRecaptcha(true);
          break;

        case 'sf_hospital_web_signup':
          setHasSignup(true);
          break;

        case 'sf_hospital_web_language_selection':
          setLangSelector(true);
          break;

        case 'sf_hospital_web_no_mobile_client':
          setHideMobileLink(true);
          break;

        default:
          break;
      }
    });
  };

  useEffect(() => {
    if (!hospital) return;
    setFlags(hospital.features);
  }, [hospital]);

  return {
    hospital,
    hasOTALink,
    hasRecaptcha,
    hasSignup,
    langSelector,
    hideMobileLink,
  };
}
