import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import tw, { styled } from 'twin.macro';
import _ from 'lodash';
import WeeklyLineData from './WeeklyLineData';
import LineChart from './LineChart';
import DailyLineData from './DailyLineData';
import EntryList from '../EntryList';
import useSegment from '../../../../hooks/useSegment';

const EVENT = 'Progress chart all entries viewed';

export default function LineChartModal({ question }) {
  const { t } = useTranslation('progress');
  const { sendTrackEvent } = useSegment();
  const { pathname } = useLocation();

  const [mode, setMode] = useState('week');

  useEffect(() => {
    const eventData = {
      id: question.id,
      timeFrame: mode,
      pathname,
      procedureId: question.procedureId,
      procedureName: question.surveyName,
      questionType: question.type,
      title: question.title,
    };
    sendTrackEvent(EVENT, eventData);
  }, [mode]);

  const SelectedChart = useMemo(() => {
    switch (mode) {
      case 'day':
        return <DailyLineData question={question} />;

      case 'week':
        return <WeeklyLineData question={question} />;

      case 'all': {
        const { patientEntries } = question;
        const firstEntryDate = _.first(patientEntries).date;
        const isFirstEntry = (_.every(patientEntries, ({ date }) => moment(firstEntryDate).isSame(date, 'day')));
        const chartMode = isFirstEntry ? 'day' : 'all';

        return (
          <>
            <LineChart
              mode={chartMode}
              question={question}
            />
            <EntryList
              question={question}
              mode={chartMode}
            />
          </>
        );
      }

      default:
        return null;
    }
  }, [mode, question]);

  return (
    <>
      <h1 className="font-semibold text-lg">{question.chartTitle}</h1>
      <h2 className="font-light">{question.unit}</h2>
      <div className="flex my-4">
        <Btn
          className="rounded-l"
          selected={mode === 'day'}
          onClick={() => setMode('day')}
        >
          {t('linechartview.tab.day')}
        </Btn>
        <Btn
          selected={mode === 'week'}
          onClick={() => setMode('week')}
        >
          {t('linechartview.tab.week')}
        </Btn>
        <Btn
          className="rounded-r"
          selected={mode === 'all'}
          onClick={() => setMode('all')}
        >
          {t('linechartview.tab.all')}
        </Btn>
      </div>

      {SelectedChart}
    </>
  );
}

const Btn = styled.button(({ selected }) => [
  tw`border border-gray-500 cursor-pointer font-semibold text-sm py-1 px-4 w-full`,
  selected && tw`bg-blue-10 text-blue-100`,
]);
