import _ from 'lodash';
import React, {
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import tw, { css, styled } from 'twin.macro';
import { useTranslation } from 'react-i18next';

import { ActionCableContext } from '../../context/ActionCableContext';
import { useReadSecureMessageThreadMutation } from '../../patient-app-common/api/secureMessageApi';
import { PatientReply } from './components/PatientReply';
import { ProviderReply } from './components/ProviderReply';
import { LoaderInline } from '../layout';

interface ThreadProps {
  id: number | null | undefined,
  disableReply: boolean,
}

export default function Thread({ id, disableReply }: ThreadProps) {
  const { t } = useTranslation('inbox');

  const {
    secureMessageThreads,
    error,
    isLoading,
    markThreadAsRead,
  } = useContext(ActionCableContext);

  const openedThread = useMemo(() => {
    if (!secureMessageThreads.length) return null;
    const thread = _.find(secureMessageThreads, ['id', id]);

    return thread;
  }, [secureMessageThreads, id]);

  const [readThread] = useReadSecureMessageThreadMutation();

  useEffect(() => {
    if (!openedThread || !openedThread?.unreadMessage) return;

    const read = async () => {
      try {
        const result = await readThread(openedThread.id).unwrap();

        if (result.success) {
          markThreadAsRead(openedThread.id);
        }
      } catch (e) {
        console.log(e);
      }
    }

    read();
  }, [openedThread]);

  const messagesEndRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current && containerRef.current) {
      const anchorEl = messagesEndRef.current;
      const containerEl = containerRef.current;

      containerEl.scrollTo({
        top: anchorEl.offsetTop,
        // behaviour smooth might not scroll all the way to bottom
        behavior: 'smooth',
      });
    }
  }

  useEffect(() => {
    if (!messagesEndRef.current) {
      return;
    }

    scrollToBottom();
  }, [openedThread]);

  const messages = openedThread?.messages || [];

  return (
    <Container disableReply={disableReply} ref={containerRef}>
      <Notice>
        {!error ? (
          <p className="text-sm">
            {t('thread.banner_text')}
          </p>
        ) : (
          <p className="text-sm">
            {t('thread.error')}
          </p>
        )}
      </Notice>
      {isLoading && (
        <div className="flex justify-center"> 
          <LoaderInline />
        </div>
      )}
      {!_.isEmpty(messages) && messages.map((message, i) => {
        const isProvider = message.sentBy === 'provider';

        if (!isProvider) {
          return (
            <PatientReply key={message.id} message={message} />
          );
        }

        return (
          <ProviderReply
            key={message.id}
            message={message}
          />
        );
      })}
      <div ref={messagesEndRef} />
    </Container>
  );
}

const Notice = styled.div(() => [
  tw`border-gray-500 my-4 p-4 rounded-lg`,
  css`background-color: #FCF8E4`,
]);

const Container = styled.div(({ disableReply }: { disableReply: boolean }) => [
  tw`float-right w-full md:w-edutopic`,
  tw`bg-white overflow-y-scroll pl-4 pb-12`,
  css`
    height: calc(60vh - 3.875rem);
    position:relative;
    margin-top: 3.875rem;
  `,
  disableReply && css`height: calc(84vh - 3.875rem)`,
]);
