import _ from 'lodash';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import tw, { styled } from 'twin.macro';
import EduEvent from './EduEvent';
import SurveyEvent from './SurveyEvent';
import { AppEvent } from '../../../patient-app-common/types/ApplicationEvent';
import useAppEvents from '../../../patient-app-common/hooks/useAppEvents';

export default function MessageList() {
  const { t } = useTranslation('home');
  const { clinicalContact, isLoading, unread } = useAppEvents();
  const [messages, setMessages] = useState(unread);
  const [mode, setMode] = useState('all');

  const surveys = useMemo(() => {
    return _.filter(unread, ['eventType', 'survey']);
  }, [unread]);

  useEffect(() => {
    if (mode === 'survey') {
      setMessages(surveys);
    } else {
      setMessages(unread);
    }
  }, [mode, unread]);

  const getEventCard = (appEvent: AppEvent, index: number) => {
    switch (appEvent.eventType) {
      case 'education':
        return (
          <EduEvent
            avatar={clinicalContact?.profilePicture}
            event={appEvent}
            index={index}
            size={unread.length}
          />
        );

      case 'survey':
        return (
          <SurveyEvent
            avatar={clinicalContact?.profilePicture}
            event={appEvent}
            index={index}
            size={unread.length}
          />
        );

      default:
        return <div />;
    }
  };

  if (isLoading) return null;

  return (
    <>
      <H2 id="messages">
        {t('messagelist.title')}
      </H2>
      <div className="flex my-4">
        <Btn
          className="rounded-l"
          selected={mode === 'all'}
          onClick={() => setMode('all')}
        >
          {t('messagelist.button.all')}
        </Btn>
        <Btn
          className="rounded-r"
          selected={mode === 'survey'}
          onClick={() => setMode('survey')}
        >
          {t('messagelist.button.survey')} {!_.isEmpty(surveys) && `(${surveys.length})`}
        </Btn>
      </div>
      {_.isEmpty(messages) && (
        <>
          <img alt="" className="mx-auto w-1/2" src="/assets/images/activities-complete.png" />
          <p className="py-4 text-center">
            {t('messagelist.empty', { context: mode })}
          </p>
        </>
      )}
      { messages.map((appEvent, index) => (
        <Fragment key={appEvent.id}>
          {getEventCard(appEvent, index)}
        </Fragment>
      ))}
    </>
  );
}

const H2 = styled.h2(() => [
  tw`font-light`,
  tw`text-lg sm:text-2xl`,
]);

const Btn = styled.button(({ selected = false }) => [
  tw`border border-blue-100 cursor-pointer py-1 px-4 w-full`,
  tw`font-semibold text-blue-100 text-sm`,
  selected && tw`bg-blue-10`,
]);
