import React from 'react';
import { Navigate } from 'react-router-dom';
import tw, { styled } from 'twin.macro';
import NavBar from '../layout/NavBar';
import DemoDates from './DemoDates';
import DemoSurveys from './DemoSurveys';
import DemoProfile from './DemoProfile';
import { useGetPatientQuery } from '../../patient-app-common/api/patientApi';

export default function Demo() {
  const { data: patient } = useGetPatientQuery();

  if (!patient || !patient.demo) {
    return (
      <Navigate to="/" replace />
    );
  }

  return (
    <div className="bg-gray-100 h-full min-h-screen">
      <NavBar />
      <Container>
        <DemoContainer>
          <DemoDates patient={patient} />
          <DemoProfile initProfile={patient.profile} />
          <DemoSurveys surveys={patient.surveys} />
        </DemoContainer>
      </Container>
    </div>
  );
}

const Container = styled.div(() => [
  tw`flex justify-center w-full`,
  tw`p-4 md:p-8 `,
  tw`pt-16 md:pt-24`,
]);

const DemoContainer = styled.div(() => [
  tw`bg-white divide-y rounded-lg shadow`,
  tw`w-full md:w-3/4`,
]);
