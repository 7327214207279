import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';
import tw, { styled } from 'twin.macro';
import { MATERNITY_ASSETS } from '../../../../constants/maternity-assets';

interface PreopCardMatProps {
  dueDate: moment.Moment | null;
}

export default function PreopCardMat({ dueDate }: PreopCardMatProps) {
  const [counter, setCounter] = React.useState(0);
  const { t } = useTranslation('home');

  const hasDueDate = !_.isEmpty(dueDate);

  const conceptionDate = moment(dueDate)
    .subtract(280, 'd');

  React.useEffect(() => {
    if (!hasDueDate) {
      return;
    }

    const daysPregnant = moment()
      .diff(moment(conceptionDate)
        .startOf('day'), 'days');

    const weeksPregnant = Math.floor(daysPregnant / 7);

    if (weeksPregnant >= 40) {
      setCounter(40);
    } else {
      setCounter(weeksPregnant);
    }
  }, [conceptionDate, dueDate, hasDueDate]);

  if (!hasDueDate || counter < 2) {
    return null;
  }

  const description = MATERNITY_ASSETS[counter]?.alt;
  const path = MATERNITY_ASSETS[counter]?.src;

  if (!path) {
    return null;
  }

  return (
    <>
      <div
        aria-labelledby="maternity-title"
        className="py-4"
        role="dialog"
      >
        <Container>
          <img src={path} alt="" className="w-20" />
          <div className="flex flex-col justify-center p-4">
            <h2 className="font-bold text-base sm:text-lg" id="maternity-title">
              {t(description)}
            </h2>
          </div>
        </Container>
      </div>
    </>
  );
}

const Container = styled.div(() => [
  tw`flex justify-center w-full`,
  tw`bg-white border-gray-500 p-4 rounded-lg shadow-md`,
]);
