import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import tw, { styled } from 'twin.macro';
import NavBar from '../layout/NavBar';
import ChartsList from './charts/ChartsList';
import TrackerList from './trackers/TrackerList';
import PastEventList from './messages/PastEventList';
import { Loader } from '../layout';
import { copyAndformatVisualQuestions } from '../../patient-app-common/helpers/progress_helper';
import useTrackers from '../../patient-app-common/hooks/useTrackers';
import useSegment from '../../hooks/useSegment';
import useSurveys from '../../patient-app-common/hooks/useSurveys';

const CHARTS_EVENT = 'Progress chart list viewed';
const SURVEYS_EVENT = 'Past survey list viewed';
const MESSAGES_EVENT = 'Past message list viewed';

export default function ProgressTabs() {
  const { t } = useTranslation('progress');
  const { pathname, state } = useLocation();
  const { sendTrackEvent } = useSegment();

  const { allSurveys, isLoading: isLoadingSurveys } = useSurveys();
  const { surveyEntries, isLoading: isLoadingEntries } = useTrackers();
  const isLoading = isLoadingEntries || isLoadingSurveys;

  const mainRef = React.useRef(null);

  const defaultTab = state?.tab || 'charts';
  const [tab, setTab] = React.useState(defaultTab);

  const chartQuestions = useMemo(() => {
    if (!surveyEntries?.length || !allSurveys) return [];
    return copyAndformatVisualQuestions(allSurveys, surveyEntries);
  }, [allSurveys, surveyEntries]);

  useEffect(() => {
    let event;
    const eventData = { pathname };

    switch (tab) {
      case 'charts':
        event = CHARTS_EVENT;
        break;
      case 'surveys':
        event = SURVEYS_EVENT;
        break;
      case 'messages':
        event = MESSAGES_EVENT;
        break;
      default:
    }
    sendTrackEvent(event, eventData);
  }, [tab]);

  return (
    <div className="bg-gray-100 h-full min-h-screen">
      <NavBar skipHandler={() => mainRef.current.focus()} />
      <Loader text="" visible={isLoading} />
      <div
        aria-label={t('progresstabs.arialabel')}
        className="pb-8 pt-24"
        role="region"
        tabIndex={0}
        ref={mainRef}
      >
        <div className="mx-auto w-full md:w-1/2 px-4">
          <div className="flex-col">
            <h1 className="font-semibold text-xl">
              {t('common.link.progress')}
            </h1>
          </div>

          <TabBar
            role="tablist"
          >
            <TabBtn
              aria-selected={tab === 'charts'}
              onClick={() => setTab('charts')}
              role="tab"
              selected={tab === 'charts'}
            >
              {t('common.link.charts')}
            </TabBtn>

            <TabBtn
              aria-selected={tab === 'surveys'}
              onClick={() => setTab('surveys')}
              role="tab"
              selected={tab === 'surveys'}
            >
              {t('chartslist.link.past_surveys')}
            </TabBtn>

            <TabBtn
              aria-selected={tab === 'messages'}
              onClick={() => setTab('messages')}
              role="tab"
              selected={tab === 'messages'}
            >
              {t('pasteventlist.title')}
            </TabBtn>
          </TabBar>
        </div>

        { tab === 'charts'
          && <ChartsList chartQuestions={chartQuestions} />}

        { tab === 'surveys'
          && <TrackerList surveys={allSurveys} surveyEntries={surveyEntries} />}

        { tab === 'messages'
          && <PastEventList />}

      </div>
    </div>
  );
}

const TabBar = styled.div(() => [
  tw`inline-flex py-4`,
]);

const TabBtn = styled.button(({ selected }) => [
  tw`mt-4 mr-8`,
  selected ? tw`text-blue-100 border-b-2 border-blue-100` : tw`text-gray-700`,
  tw`hover:text-blue-100`,
]);
