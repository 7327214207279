import React from "react";
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryContainer,
  VictoryLabel,
} from "victory";
import { isIE } from "react-device-detect";

export default function OpioidChart({ altText, yLimits, data }) {
  const divIEStyle = {
    position: "relative",
    height: 0,
    width: "100%",
    padding: 0,
    paddingBottom: "50%",
  };

  const chartIEStyle = {
    parent: {
      position: "absolute",
      height: "100%",
      width: "100%",
      left: 0,
      top: 0,
      overflow: "hidden",
    },
  };

  return (
    <div style={isIE ? divIEStyle : undefined}>
      {data.length > 1 && (
        <VictoryChart
          // width={wp('100%')}
          height={200}
          minDomain={{ y: 0 }}
          maxDomain={{ y: 100 }}
          domainPadding={{ x: 100 }}
          style={isIE ? chartIEStyle : undefined}
          containerComponent={(
            <VictoryContainer
              desc={altText}
            />
          )}
        >
          <VictoryBar
            data={data}
            x="group"
            y="answer"
            alignment="middle"
            labels={({ datum }) => (`${datum.answer}%`)}
            labelComponent={<VictoryLabel verticalAnchor="end" />}
            // barWidth={wp('8%')}
            style={{
              data: { fill: ({ datum }) => datum.fill },
              labels: { fill: "#000", fontSize: 10 },
            }}
          />

          <VictoryAxis
            crossAxis
            style={{
              axis: { stroke: "black" },
              tickLabels: { fontSize: 10 },
            }}
          />

          <VictoryAxis
            dependentAxis
            tickValues={yLimits}
            tickFormat={["0%", "100%"]}
            style={{
              axis: { stroke: "#fff" },
              axisLabel: { fontSize: 10, padding: 30 },
              grid: { stroke: "#000" },
              tickLabels: { fontSize: 10 },
            }}
          />
        </VictoryChart>
      )}
    </div>
  );
}
