import React from "react";
import tw, { styled } from "twin.macro";
import { BiCaretRight, BiCaretDown } from "react-icons/bi";

export default function Panel({ title, children, initToggle }) {
  const [expand, toggleExpand] = React.useState(initToggle);
  const divRef = React.useRef(null);

  const handleExpandClick = (expand) => {
    toggleExpand(!expand);
    divRef.current.focus();
  };

  return (
    <div>
      <Btn
        aria-label={`${title}: click to ${expand ? "hide" : "show"} details`}
        expand={expand}
        onClick={() => handleExpandClick(expand)}
      >
        <span className="inline-flex">
          <span className="text-2xl pr-4">
            {expand ? <BiCaretDown /> : <BiCaretRight />}
          </span>
          <h2 className="pb-2">{title}</h2>
        </span>
      </Btn>
      <Container
        expand={expand}
        tabIndex={0}
        ref={divRef}
      >
        {children}
      </Container>
    </div>
  );
}

const Btn = styled.button(({ expand }) => [
  tw`cursor-pointer p-4 text-left w-full`,
  tw`text-lg sm:text-xl`,
  !expand && tw`hover:bg-teal-30`,
]);

const Container = styled.div(({ expand }) => [
  tw`px-4 pb-4`,
  tw`text-sm sm:text-base`,
  expand ? tw`block` : tw`hidden`,
]);
