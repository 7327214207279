import * as React from 'react';
import useModal from '../../../../hooks/useModal';
import DateModal from '../../prompts/DateModal';
import { Modal } from '../../../layout';
import EndCard from '../surgical/EndCard';
import EndSurveyCardMat from './EndSurveyCardMat';
import HealthCheckCard from '../surgical/HealthCheckCard';
import PostopCard from '../surgical/PostopCard';
import MaternityCard from './MaternityCard';
import PreopCardMat from './PreopCardMat';
import PeriopCardMat from './PeriopCardMat';
import { Patient } from '../../../../patient-app-common/types/Patient';
import { PatientDates } from '../../../../patient-app-common/hooks/usePatientDates';

interface MaternityProgramProps {
  patient: Patient;
  patientDates: PatientDates;
  programPhase: undefined | string;
}

export default function MaternityProgram({
  patient,
  patientDates,
  programPhase,
}: MaternityProgramProps) {
  const { visible, toggleModal } = useModal();

  return (
    <>
      <Modal
        visible={visible}
        handleCloseClick={toggleModal}
        component={(
          <DateModal
            programPhase={programPhase}
            patientDates={patientDates}
            callbackFn={toggleModal}
          />
        )}
      />
      <MaternityPhaseCard
        patient={patient}
        patientDates={patientDates}
        programPhase={programPhase}
        toggleModal={toggleModal}
      />
    </>
  );
}

function MaternityPhaseCard({
  patient,
  patientDates,
  programPhase,
  toggleModal,
}: MaternityProgramProps & { toggleModal: () => void }) {
  const { confirmedSurgery } = patient;
  const { surgeryDate, dischargeDate } = patientDates;

  if (programPhase === 'inPreop' || !confirmedSurgery) {
    return (
      <>
        <PreopCardMat dueDate={surgeryDate} toggleModal={toggleModal} />
        <MaternityCard dueDate={surgeryDate} />
      </>
    );
  }

  switch (programPhase) {
    case 'inPeriop':
      return <PeriopCardMat dueDate={surgeryDate} toggleModal={toggleModal} />;
    case 'inPostop':
      return (
        <>
          <PostopCard patient={patient} dischargeDate={dischargeDate} />
          <HealthCheckCard
            patient={patient}
            patientDates={patientDates}
          />
        </>

      );
    case 'end':
      return (
        <>
          <EndCard />
          <EndSurveyCardMat
            endSurveyId={patient.endSurveyId}
            endSurveyCompleted={patient.endSurveyCompleted}
          />
        </>
      );
    default:
      return <div />;
  }
}
