import React from 'react';
import Card from '../../layout/Card';
import { Patient } from '../../../../patient-app-common/types/Patient';

export default function PreopCardHL7({ patient }: { patient: Patient}) {
  const {
    firstName,
    procedureName,
    surgicalDetails,
  } = patient;

  const {
    surgeryDate,
    surgeonName,
    surgeryScheduleStatus,
    surgerySiteLocation,
    surgeryTime,
  } = surgicalDetails;

  if (surgeryDate && surgeryScheduleStatus === 'surgery_waitlist') {
    surgicalDetails.surgeryScheduleStatus = 'surgery_scheduled';
  }

  const getScheduleStatus = () => {
    const strings = {
      titleText: '',
      bodyText: '',
    };

    switch (surgeryScheduleStatus) {
      case 'surgery_waitlist':
        strings.titleText = `Hi ${firstName}`;
        strings.bodyText = `You are on the waitlist for ${procedureName}`;
        break;

      case 'surgery_soft_cancellation':
        strings.titleText = `Hi ${firstName}, your ${procedureName} is cancelled`;
        strings.bodyText = `Your ${procedureName} will be rebooked in the near future, you will receive notifications from this app and from your Surgeon’s Office regarding changes.`;
        break;

      case 'surgery_hard_cancellation':
        strings.titleText = `Hi ${firstName}, your ${procedureName} is cancelled`;
        strings.bodyText = `Your ${procedureName} has been cancelled. Please contact your surgeon’s office if you require more information.`;
        break;

      case 'surgery_unverified':
        strings.titleText = `Hi ${firstName},`;
        strings.bodyText = `Our system has identified that there may have been a change to your ${procedureName} date. If you are not aware of a change in your ${procedureName} date, please contact your surgeon’s office to verify your date.`;
        break;

      case 'surgery_scheduled':
        strings.titleText = `Hi ${firstName}, your ${procedureName} has been scheduled`;
        strings.bodyText = `Your ${procedureName} with ${surgeonName} is scheduled for ${surgeryDate}.`;

        if (surgeryTime) {
          const bodyText = `${strings.bodyText.split('.').join(' ')}at ${surgeryTime}.`;
          strings.bodyText = bodyText;
        }

        if (surgerySiteLocation) {
          const bodyText = `${strings.bodyText.split('.').join(' ')}at ${surgerySiteLocation}.`;
          strings.bodyText = bodyText;
        }

        strings.bodyText += ' Arrive before your procedure (at the recommended time given by your healthcare team). If you are late, your procedure may be canceled.';

        break;

      default:
        strings.titleText = `Hi ${firstName}, your ${procedureName} date is not available`;
        strings.bodyText = `Your ${procedureName} date is not available on SeamlessMD. Please contact your surgeon’s office if you require more information.`;
        break;
    }

    return strings;
  };

  const { titleText, bodyText } = getScheduleStatus();

  return (
    <div
      aria-labelledby="preop-title"
      aria-describedby="preop-body"
      data-test="phase-card"
      className="py-4"
      role="dialog"
    >
      <Card
        headerComp={(
          <>
            <h2 className="font-bold text-xl" role="status" id="preop-title">
              {titleText}
            </h2>
          </>
        )}
        bodyComp={(
          <>
            <p id="preop-body">{bodyText}</p>
          </>
        )}
      />
    </div>
  );
}
