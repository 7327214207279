import _ from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import tw, { styled } from 'twin.macro';
import { Loader, NavBar } from '../layout';
import {
  AppointmentCard,
  Footer,
  OTALink,
  ZoneAlert,
} from './layout';
import MessageList from './messages/MessageList';
import { useGetPatientQuery } from '../../patient-app-common/api/patientApi';
import usePatientDates from '../../patient-app-common/hooks/usePatientDates';
import ChronicProgram from './programs/chronic/ChronicProgram';
import SurgicalProgram from './programs/surgical/SurgicalProgram';
import MaternityProgram from './programs/maternity/MaternityProgram';
import { useAppSelector } from '../../redux/hooks';
import useAppEvents from '../../patient-app-common/hooks/useAppEvents';

export default function Home() {
  const { t } = useTranslation('home');
  const navigate = useNavigate();

  const mainRef = React.useRef<HTMLDivElement>(null);
  const { dischargePrompt } = useAppSelector((state) => state.app);

  const { programPhase, isLoading: isLoadingAppEvents } = useAppEvents();
  const {
    data: patient,
    isLoading: isLoadingPatient,
  } = useGetPatientQuery();
  const isLoading = isLoadingPatient || isLoadingAppEvents;

  const { patientDates, sameTimeZone } = usePatientDates();

  useEffect(() => {
    if (!patient || !programPhase) return;

    const {
      confirmedSurgery,
      hasPeriop,
      hasPostop,
      maternityProgram,
    } = patient;

    const setDeliveryDate = programPhase === 'inPeriop'
      && maternityProgram
      && !confirmedSurgery
      && dischargePrompt;

    if (setDeliveryDate) {
      navigate('/maternity');
      return;
    }

    const setDischargeDate = programPhase === 'inPeriop'
      && !maternityProgram
      && !hasPeriop
      && hasPostop
      && dischargePrompt;

    if (setDischargeDate) {
      navigate('/discharge');
    }
  }, [dischargePrompt, navigate, patient, programPhase]);

  const programCard = useMemo(() => {
    if (!patient || !patientDates) {
      return null;
    }

    const { maternityProgram, nonSurgicalProgram } = patient;

    if (nonSurgicalProgram) {
      return (
        <ChronicProgram
          patient={patient}
          programPhase={programPhase}
        />
      );
    }

    if (maternityProgram) {
      return (
        <MaternityProgram
          patient={patient}
          patientDates={patientDates}
          programPhase={programPhase}
        />
      );
    }

    return (
      <SurgicalProgram
        patient={patient}
        patientDates={patientDates}
        programPhase={programPhase}
      />
    );
  }, [patient, patientDates, programPhase]);

  return (
    <div className="bg-gray-100 h-full min-h-screen">
      <NavBar skipHandler={() => mainRef?.current && mainRef.current.focus()} />
      <Loader text="" visible={isLoading} />
      {!_.isEmpty(patient) && (
        <>
          <Container
            aria-label={t('common.link.home')}
            role="region"
            tabIndex={0}
            ref={mainRef}
          >
            <section aria-label={t('home.section.program')}>
              <h1 className="text-xl sm:text-3xl">
                {t('common.text.greeting_name', { firstName: patient.firstName })}
              </h1>
              <OTALink
                hospitalDisplayName={patient.hospitalDisplayName}
                integration={patient.integration}
              />
              <ZoneAlert
                hospitalDisplayName={patient.hospitalDisplayName}
                sameTimeZone={sameTimeZone}
                timezone={patient.timezone}
              />
              <AppointmentCard appointments={patient.appointments} />

              {programCard}

            </section>

            <section
              className="flex flex-col pt-8 justify-center"
              aria-labelledby="messages"
            >
              <MessageList />
              <button onClick={() => navigate('/progress')} className="text-blue-100">
                {t('home.button.progress')}
              </button>
            </section>
          </Container>
          <Footer />
        </>
      )}
    </div>
  );
}

const Container = styled.div(() => [
  tw`p-4 md:p-8 mx-auto`,
  tw`pt-16 md:pt-24`,
  tw`w-full lg:w-1/2`,
]);
