import moment from 'moment';
import _ from 'lodash';
import { getOffsetStart } from './logic_helpers';
import { PatientDates } from '../hooks/usePatientDates';
import { AppEvent } from '../types/ApplicationEvent';
import { CompletedEvent } from '../types/Patient';

export function patientProgramPhase(patientDates: PatientDates, daysPostOp: number) {
  let phase;
  const currentDate = new Date();
  const {
    surgeryDate,
    dischargeDate,
    completedDate,
  } = patientDates;
  const endDate = completedDate || moment(dischargeDate).add(daysPostOp, 'days');

  if (moment(currentDate).isAfter(endDate)) {
    phase = 'end';
  } else if (
    !moment(surgeryDate).isValid()
    || moment(currentDate).isBefore(surgeryDate)
  ) {
    phase = 'inPreop';
  } else if (
    moment(currentDate).isSameOrAfter(surgeryDate)
    && (!moment(dischargeDate).isValid()
    || moment(currentDate).isBefore(dischargeDate))
  ) {
    phase = 'inPeriop';
  } else if (
    moment(currentDate).isSameOrAfter(dischargeDate)
    && (!moment(endDate).isValid()
    || moment(currentDate).isSameOrBefore(endDate))
  ) {
    phase = 'inPostop';
  }
  return phase;
}

// checks whether the phase(s) configured for the event matches the current program phase
// returns a boolean
export function eventPhaseIsActive(appEvent: AppEvent, phase: string) {
  const { inPreop, inPeriop, inPostop } = appEvent;

  const activePhases = _.pickBy({ inPreop, inPeriop, inPostop }, ((value) => value));
  const phaseKeys = _.keys(activePhases);

  if (phaseKeys.includes(phase)) return true;

  // allow postop events to show past end of program
  if (phase === 'end' && phaseKeys.includes('inPostop')) return true;

  return false;
}

// returns the offset event date object
// note patient dates are moment objects set to 12am in hospital timezone
function eventOffsetDate(appEvent: AppEvent, patientDates: PatientDates) {
  const { offsetEvent } = appEvent;

  const {
    signupDate,
    preOpDate,
    surgeryDate,
    dischargeDate,
  } = patientDates;

  let date;

  switch (offsetEvent) {
    case 'signup':
      date = signupDate;
      break;

    case 'pre_op_visit':
      date = preOpDate;
      break;

    case 'surgery':
      date = surgeryDate;
      break;

    case 'discharge':
      date = dischargeDate;
      break;

    default:
      return null;
  }

  if (!moment(date).isValid()) {
    return null;
  }

  return moment(date).startOf('day');
}

// calculates start date and end date in milliseconds for the event
export function eventSchedule(appEvent: AppEvent, patientDates: PatientDates) {
  const referenceDate = eventOffsetDate(appEvent, patientDates);

  const schedule: { [index: string]: null | number } = {
    startDateTime: null,
    notificationTime: null,
    endDateTime: null,
  };

  if (!referenceDate) {
    return schedule;
  }

  schedule.startDateTime = getOffsetStart(
    referenceDate.valueOf(),
    appEvent.offsetStart,
    appEvent.offsetType,
  );

  if (appEvent.messageDeliveryTime) {
    schedule.notificationTime = moment()
      .startOf('day')
      .add(moment.duration(appEvent.messageDeliveryTime, 'minutes'))
      .valueOf();
  } else {
    schedule.notificationTime = schedule.startDateTime;
  }

  schedule.endDateTime = getOffsetStart(
    referenceDate.valueOf(),
    appEvent.offsetEnd,
    appEvent.offsetType,
  );

  return schedule;
}

// checks whether normal application events meets date dependencies
// pass in an event with startDateTime and endDateTime appended
// returns a boolean
export function eventIsActive(appEvent: AppEvent) {
  let meetsDependency = false;

  const currentDateTime = new Date().getTime();
  const { startDateTime, endDateTime } = appEvent;

  if (!startDateTime || !endDateTime) return false;

  if (currentDateTime >= startDateTime) {
    if (appEvent.offsetEnd) {
      // 'if the current date time is before the offset end time of the event'
      if (currentDateTime < endDateTime) {
        meetsDependency = true;
      }
    } else {
      // 'if the event does not expire'
      meetsDependency = true;
    }
  } else {
    meetsDependency = false;
  }

  return meetsDependency;
}

// checks whether repeat application events meet daily start and end time dependencies
// returns a boolean
function inTimeRange(repeatAppEvent: AppEvent) {
  const minAfter12AM = moment().diff(moment().startOf('day'), 'minutes');

  // if the current time is past the start time and it has not passed the end time
  if (
    minAfter12AM > _.toNumber(repeatAppEvent.dailyStartTime)
    && minAfter12AM < _.toNumber(repeatAppEvent.dailyEndTime)
  ) {
    return true;
  }

  return false;
}

// checks whether repeat application events meets date and time dependencies
// pass in an event with startDateTime and endDateTime appended
// updates start date time if the interval is met
// returns a boolean
export function repeatEventIsActive(repeatEvent: AppEvent) {
  // check date dependency
  const meetsDateDependency = eventIsActive(repeatEvent);

  if (!meetsDateDependency) {
    return false;
  }

  const { startDateTime } = repeatEvent;

  const daysFromStart = Math.abs(
    moment(startDateTime)
      .startOf('day')
      .diff(moment().startOf('day'), 'days'),
  );

  // check interval dependency
  const { interval, duration } = repeatEvent;
  if (_.isNil(interval) || _.isNil(duration)) return false;

  let meetsInterval = false;

  if (interval >= 1) {
    if (daysFromStart % interval === 0) {
      meetsInterval = true;

      repeatEvent.startDateTime = getOffsetStart(
        moment().startOf('day'),
        repeatEvent.dailyStartTime,
        1,
      );
    }
  }

  // check duration dependency
  const repeatDuration = duration - 1;
  let meetsDuration = false;

  if (repeatDuration > 1) {
    const currentInterval = daysFromStart - (daysFromStart % interval);
    const intervalDate = moment(startDateTime)
      .startOf('day')
      .add(currentInterval, 'd');
    const eventExpireDate = moment(intervalDate).add(repeatDuration, 'd');

    meetsDuration = moment().startOf('day').isSameOrBefore(eventExpireDate);
  }

  // check daily start and end time dependency
  const meetsTimeDependency = inTimeRange(repeatEvent);

  if ((meetsInterval || meetsDuration) && meetsTimeDependency) {
    return true;
  }
  return false;
}

// checks whether the repeat event's completed date is for the current repeat interval
// returns a boolean
function repeatEventIsDone(repeatEvent: AppEvent, startDateTime: number, completedDate: string) {
  let completed = false;

  const daysFromCompleted = moment(new Date(completedDate))
    .startOf('day')
    .diff(moment().startOf('day'), 'days');

  if (daysFromCompleted === 0) {
    completed = true;
    return completed;
  }

  const { interval, duration } = repeatEvent;
  if (_.isNil(interval) || _.isNil(duration)) return false;

  const repeatDuration = duration - 1;

  if (repeatDuration > 1) {
    const daysFromStart = Math.abs(
      moment(startDateTime)
        .startOf('day')
        .diff(moment().startOf('day'), 'days'),
    );

    const currentInterval = daysFromStart - (daysFromStart % interval);
    const intervalDate = moment(startDateTime)
      .startOf('day')
      .add(currentInterval, 'd');
    const eventExpireDate = moment(intervalDate).add(repeatDuration, 'd');

    completed = moment(completedDate).isBetween(
      intervalDate,
      eventExpireDate,
    );
  }

  return completed;
}

// returns the event's completed date
export function eventCompletedDate(
  event: AppEvent,
  startDateTime: number,
  completedEvents: CompletedEvent[],
) {
  if (!completedEvents.length) return null;

  let completedDate = null;

  const readIds = completedEvents.map((AE) => AE.applicationEventId);

  if (event.shouldPersistAfterReading
    || !readIds.includes(_.toNumber(event.id))) {
    return completedDate;
  }

  // TODO: order complete events log first (oldest to newest)
  completedDate = _.findLast(completedEvents, ['applicationEventId', _.toNumber(event.id)])?.createdAt
    || null;

  if (event.interval && completedDate) {
    const completed = repeatEventIsDone(event, startDateTime, completedDate);

    if (!completed) {
      completedDate = null;
    }
  }

  return completedDate;
}
