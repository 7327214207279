import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Panel, LoaderInline } from '../../layout';
import { setToken } from '../../../patient-app-common/slices/authSlice';
import { useSetProcedureSetMutation } from '../../../patient-app-common/api/procedureApi';


export default function Careplans({ procedureSets }) {
  const { t } = useTranslation('settings')
  const dispatch = useDispatch();
  const [setProcedureSet, { isLoading, isSuccess, isError }] = useSetProcedureSetMutation();

  const { token } = useSelector((state) => state.auth);
  const [selectedToken, selectToken] = React.useState(token);

  const handleTokenSelect = (e) => {
    selectToken(e.target.value);
  };

  const handleConfirmClick = async () => {
    dispatch(setToken(selectedToken));
    await setProcedureSet();
  };

  return (
    <Panel title={t('settings.list.programs')}>
      <div className="inline-flex w-full">
        <select
          name="careplans"
          id="careplans"
          defaultValue={token}
          onChange={(e) => handleTokenSelect(e)}
          className="form-select w-3/4"
        >
          {procedureSets.map((set, i) => {
            const name = set.procedureSetName;
            let dateStr = 'TBD';

            if (set.surgeryDate) {
              dateStr = moment(set.surgeryDate).format('LL').toString();
            }

            return (
              <option key={i} value={set.token}>{`${name} (${dateStr})`}</option>
            );
          })}
        </select>
        <div className="px-4">
          <button className="btn-primary" onClick={() => handleConfirmClick()}>
            {t('common.action.save')}
          </button>
        </div>
      </div>
      { isLoading && <LoaderInline /> }
      { !isLoading && isError && <p className="error-text">{t('common.error.server')}</p>}
      { !isLoading && isSuccess && <p className="italic">{t('common.text.update_success')}</p>}
    </Panel>
  );
}
