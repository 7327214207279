import React, { ChangeEvent, FormEvent, useState } from 'react';
import { ImSpinner8 } from 'react-icons/im';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import tw, { styled } from 'twin.macro';
import { useResetPasswordMutation } from '../../patient-app-common/api/authApi';
import ReCaptcha from './ReCaptcha';

export default function ResetPassword() {
  const { token } = useParams<{ token: string }>();
  const { t } = useTranslation('login');
  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  const [isHuman, setIsHuman] = useState(true);
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [error, setError] = useState('');
  const [serverError, setServerError] = useState('');
  const [success, setSuccess] = useState(false);

  const [state, setState] = useState({
    new: '',
    confirm: '',
  });

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setError('');
    setErrorFields([]);
    setSuccess(false);

    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validatePwd = () => {
    for (const [key, value] of Object.entries(state)) {
      if (!value) {
        errorFields.push(key);
      }
    }

    const hasNumber = /[0-9]/.test(state.new);

    if (state.new && (state.new.length < 8 || !hasNumber)) {
      setError(t('common.error.invalid_password'));
      setErrorFields(['new']);
      return false;
    }

    if (errorFields.length && (!state.new || !state.confirm)) {
      setErrorFields(errorFields);
      setError(t('common.error.blank_input'));
      return false;
    }

    if (state.new !== state.confirm) {
      setErrorFields(['new', 'confirm']);
      setError(t('common.error.confirm_password'));
      return false;
    }

    return true;
  };

  const handleBtnClick = async (e: FormEvent) => {
    e.preventDefault();

    setErrorFields([]);
    setError('');
    setSuccess(false);

    const valid = validatePwd();

    if (!valid) {
      return;
    }

    if (!isHuman) {
      setError(t('common.error.recaptcha'));
      return;
    }

    setError('');

    const params = {
      token,
      password: state.new,
    };

    try {
      const result = await resetPassword(params).unwrap();

      if (result.success) {
        setSuccess(true);
      } else {
        setServerError(t('common.error.server'));
      }
    } catch (_e) {
      setServerError(t('common.error.server'));
    }
  };

  const handleHelpBtnClick = () => {
    const url = 'mailto:support@seamless.md?subject=SeamlessMD%20Support%20request%20-%20Password%20Reset&body=Please%20include%20the%20following%20information%3A%0A%0APatient%20Name%3A%0AHospital%20Name%3A%0AEmail%3A%0APhone%20number%3A';
    window.open(url, '_blank');
  };

  return (
    <div className="bg-gray-100 h-screen">
      <div className="flex justify-center">
        <ColumnContainer>
          <FormContainer>
            <h1 className="font-semibold text-lg">
              {t('resetpassword.title')}
            </h1>

            <div className="pt-6">
              <label htmlFor="new" className="block">
                <p className="font-semibold">
                  {t('common.input.password')}
                </p>
                <Input
                  autoComplete="new-password"
                  error={errorFields.includes('new')}
                  id="new"
                  name="new"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(e)}
                  type="password"
                />
              </label>
            </div>

            <div className="pt-6">
              <label htmlFor="confirm" className="block">
                <p className="font-semibold">
                  {t('common.input.confirm_password')}
                </p>
                <Input
                  autoComplete="new-password"
                  error={errorFields.includes('confirm')}
                  id="confirm"
                  name="confirm"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(e)}
                  type="password"
                />
              </label>
            </div>

            <ReCaptcha
              setIsHuman={(result: boolean) => setIsHuman(result)}
            />

            <div className="pt-4">
              <button
                className="btn-primary"
                onClick={(e) => handleBtnClick(e)}
                disabled={isLoading}
              >
                {isLoading && (
                  <span className="inline-flex mr-2">
                    <ImSpinner8 className="animate-spin" />
                  </span>
                )}
                {t('common.action.submit')}
              </button>
            </div>
            {error && <p className="error-text pt-4">{error}</p>}
            {success && <p className="italic pt-4">{t('common.text.update_success')}</p>}
            {serverError && (
              <>
                <p className="pt-4">
                  {serverError}
                </p>
                <button
                  className="text-blue-100"
                  onClick={handleHelpBtnClick}
                >
                  {t('common.link.help')}
                </button>
              </>
            )}
          </FormContainer>
        </ColumnContainer>
      </div>
    </div>
  );
}

const ColumnContainer = styled.div(() => [
  tw`text-left p-8 pt-16`,
  tw`w-full md:w-1/2`,
]);

const FormContainer = styled.form(() => [
  tw`bg-white border border-gray-500 p-12 rounded shadow`,
]);

const Input = styled.input(({ error } : { error: boolean }) => [
  tw`block form-input mt-1 w-full`,
  tw`hover:border-blue-100`,
  error && tw`border-red`,
]);
