import React, { useEffect } from "react";
import tw, { css, styled } from "twin.macro";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useSegment from "../../hooks/useSegment";

const image = `${process.env.PUBLIC_URL}/assets/images/launch.png`;
const EVENT = 'Invalid link opened';

export default function NoMatch() {
  const navigate = useNavigate();
  const { sendTrackEvent } = useSegment();
  const { t } = useTranslation();

  useEffect(() => {
    sendTrackEvent(EVENT, { pathname: window.location.hash });
  }, []);

  return (
    <Container>
      <TextColumn>
        <H1>
          {t('common.error.not_found')}
        </H1>
        <p className="py-4 px-12">
          {t('common.layout.not_found')}
        </p>

        <ButtonDiv>
          <button onClick={() => navigate('/', { replace: true })} className="btn-primary">
            {t('common.link.home')}
          </button>
          <button 
            onClick={(e) => {
              window.location = 'mailto:support@seamless.md';
              e.preventDefault();
            }}
            className="btn-secondary"
          >
            {t('common.link.help')}
          </button>
        </ButtonDiv>
      </TextColumn>

      <ImgColumn>
        <img alt="" src={image} />
      </ImgColumn>
    </Container>
  );
}

const H1 = styled.h1(() => [
  tw`font-bold text-gray-700`,
  tw`text-4xl sm:text-5xl`,
]);

const Container = styled.div(() => [
  tw`bg-white h-screen`,
  tw`flex flex-col md:flex-row`,
  css`
    max-height: -webkit-fill-available;
  `,
]);

const TextColumn = styled.div(() => [
  tw`items-center p-12`,
  tw`flex-none md:flex flex-col justify-center`,
  tw`w-full md:w-1/2`,
]);

const ImgColumn = styled.div(() => [
  tw`w-full md:w-1/2`,
  tw`flex-none md:flex items-center`,
]);

const ButtonDiv = styled.div(() => [
  tw`inline-flex flex justify-center`,
  tw`text-center space-x-8 m-4`,
]);
