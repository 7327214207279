import React, {
  useMemo,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import tw, { css, styled } from 'twin.macro';
import {
  BiChevronLeft,
  BiPaperclip,
  BiPaperPlane,
  BiTrash,
} from 'react-icons/bi';
import { ImSpinner8 } from 'react-icons/im';
import { ThreadPayload, MutationParams } from '../../patient-app-common/types/SecureMessageThreads';
import {
  useAddSecureMessageThreadMutation,
  useUpdateSecureMessageThreadMutation,
} from '../../patient-app-common/api/secureMessageApi';
import { useAppSelector } from '../../redux/hooks';

interface ThreadInputProps {
  onClose: () => void;
  setSelectedThread: React.Dispatch<React.SetStateAction<number | null>>;
  thread: undefined | ThreadPayload;
}

export default function ThreadInput({
  onClose,
  setSelectedThread,
  thread,
}: ThreadInputProps) {
  const { t } = useTranslation('inbox');

  const [addThread, {
    isLoading: isAdding,
    isError: isAddError,
  }] = useAddSecureMessageThreadMutation();

  const [updateThread, {
    isLoading: isUpdating,
    isError: isUpdateError,
  }] = useUpdateSecureMessageThreadMutation();

  const isLoading = isAdding || isUpdating;
  const isError = isAddError || isUpdateError;

  // TODO: check if token is received in the header on rails side, if so can remove here
  const { token } = useAppSelector(({ auth }) => auth);

  const [text, setText] = useState('');

  const fileInput = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);

  const handleSubmitClick = async () => {
    if (!token) return;

    const params: MutationParams = {
      token,
      text,
    };

    if (file) {
      params.file = file;
    }

    if (!thread) {
      const result = await addThread(params).unwrap();
      if (result.id) {
        setSelectedThread(result.id);
        setText('');
        setFile(null);
      }
    } else {
      params.secureMessageThreadId = thread.id.toString();
      const result = await updateThread(params).unwrap();
      if (result.success) {
        setText('');
        setFile(null);
      }
    }
  };

  const disableReply = thread?.resolved || thread?.patientReplyEnabled === false;

  const footerText = useMemo(() => {
    if (!thread) return ''

    let text = '';

    if (thread.resolved) {
      text = t('thread.footer_resolved')
    } else if (!thread.patientReplyEnabled) {
      text = t('thread.footer_disabled');
    }

    return text;
  }, [thread]);

  if (disableReply) {
    return (
      <Footer>
        <div className="p-2">
          <NavBtn onClick={onClose}>
            <BiChevronLeft className="text-lg inline-flex" />
            {t('common.action.back')}
          </NavBtn>
        </div>
        <div style={{ backgroundColor: '#FCF8E4' }} className="flex justify-center py-2">
          <p className="font-semibold">
            {footerText}
          </p>
        </div>
      </Footer>
    );
  }

  return (
    <Container>
      {file && (
        <p className="text-blue-100 text-xs">
          <a href={URL.createObjectURL(file)} target="_blank" rel="noreferrer">
            {file.name}
          </a>
        </p>
      )}
      {isError && (
        <p className="text-xs error-text">
          {t('threadinput.error')}
        </p>
      )}
      <div className="pt-2 flex justify-between">
        <div className="pr-4 inline-flex">
          <NavBtn onClick={onClose}>
            <BiChevronLeft className="inline-flex text-lg" />
            {t('common.action.back')}
          </NavBtn>
          <ToolBtn onClick={() => {
            if (fileInput.current) {
              fileInput.current.click();
            }
          }}
          >
            <BiPaperclip className="text-lg inline-flex" />
            {t('common.action.attach')}
          </ToolBtn>
          <input
            id="upload"
            type="file"
            accept="image/*, application/pdf"
            onChange={(e) => {
              if (e.target?.files?.length) {
                setFile(e.target.files[0]);
              }
            }}
            ref={fileInput}
            className="hidden"
          />
        </div>

        <div className="inline-flex">
          <ToolBtn
            onClick={() => {
              setText('');
              setFile(null);

              if (fileInput.current?.value) {
                fileInput.current.value = '';
              }
            }}
          >
            <BiTrash
              className="text-lg inline-flex"
            />
            {t('common.action.clear')}
          </ToolBtn>
          <button className="btn-primary text-sm" onClick={handleSubmitClick}>
            {t('common.action.send')}
            { isLoading ? (
              <ImSpinner8 className="inline-flex animate-spin ml-1" />
            ) : (
              <BiPaperPlane className="text-lg inline-flex ml-1" />
            )}
          </button>
        </div>
      </div>
      <TextArea
        className="mt-2"
        value={text}
        maxLength={300}
        onChange={(e: React.FormEvent<HTMLInputElement>) => setText(e.currentTarget.value)}
        placeholder={t('common.input.text_placeholder')}
        rows="4"
      />
    </Container>
  );
}

const Footer = styled.div(() => [
  tw`float-right w-full md:w-edutopic`,
  css`
    height: 16vh;
    position:relative;
  `,
]);

const Container = styled.div(() => [
  tw`float-right p-2 w-full md:w-edutopic bg-gray-100`,
  css`
    height: 40vh;
    position:relative;
  `,
]);

const NavBtn = styled.button(({ disabled } : { disabled : boolean}) => [
  disabled ? tw`text-gray-400` : tw`text-gray-800`,
  tw`
    border border-gray-400 
    hover:bg-teal-10
    mr-2
    p-2
    rounded-full
    text-sm
  `,
]);

const ToolBtn = styled.button(() => [
  tw`
    bg-white
    border border-gray-400 
    hover:bg-teal-10
    mr-2
    p-2 
    shadow
    rounded
    text-gray-800  
    text-sm
  `,
]);

const TextArea = styled.textarea(() => [
  tw`form-textarea block rounded w-full`,
  tw`border border-gray-500 hover:border-blue-100`,
]);
