import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { resources } from "../patient-app-common/localization/localization";

i18next
  .use(initReactI18next) 
  .init({
    resources,
    lng: 'en',
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    ns: [
      "common",
      "education",
      "home",
      "login", 
      "progress",
      "settings",
      "survey",
      "todo"
    ],
    defaultNS: 'common',
    fallbackNS: 'common',
  });

export default i18next;
