import React from 'react';
import { useTranslation } from 'react-i18next';
import tw, { styled } from 'twin.macro';
import { Panel, Toggle } from '../../layout';
import { useUpdatePatientMutation } from '../../../patient-app-common/api/patientApi';

export default function Notifications({ emailNotification, phoneNotification }) {
  const { t } = useTranslation('settings');

  const [updatePatient, { isLoading, isSuccess, isError }] = useUpdatePatientMutation();

  const [emails, setEmails] = React.useState(emailNotification);
  const [phone, setPhone] = React.useState(phoneNotification);

  const handleSubmit = async () => {
    await updatePatient({
      emailNotification: emails,
      phoneNotification: phone,
    });
  };

  return (
    <Panel title={t('account.section.notification')}>
      <Container>
        <ToggleContainer htmlFor="email-toggle">
          {t('account.toggle.email_notification')}
          <Toggle
            handleToggle={() => setEmails(!emails)}
            toggle={emails}
            id="email-toggle"
          />
        </ToggleContainer>

        <ToggleContainer>
          {t('account.toggle.sms_notification')}
          <Toggle
            handleToggle={() => setPhone(!phone)}
            toggle={phone}
            id="phone-toggle"
          />
        </ToggleContainer>

        <button className="btn-secondary mx-auto mt-8 mb-4" onClick={() => handleSubmit()}>
          {t('common.action.save')}
        </button>

        {!isLoading && isSuccess && (
          <p className="italic text-center">
            {t('common.text.update_success')}
          </p>
        )}

        {!isLoading && isError && (
          <p className="error-text text-center">
            {t('common.error.server')}
          </p>
        )}
      </Container>
    </Panel>
  );
}

const Container = styled.div(() => [
  tw`flex flex-col justify-center w-full`,
]);

const ToggleContainer = styled.label(() => [
  tw`border-b border-gray-300 cursor-pointer flex flex-row justify-between py-4`,
  tw`hover:bg-teal-30`,
  tw`px-0 sm:px-12 `,
]);
