import React from "react";
import { useTranslation } from "react-i18next";
import tw, { styled } from "twin.macro";

export default function Text({ answerHistory, options, sendAnswer }) {
  const { t } = useTranslation('survey');

  const [count, setCount] = React.useState(0);
  const [error, setError] = React.useState("");

  const handleChange = (event) => {
    const count = event.target.value.length;
    setCount(count);
    setError("");

    if (count >= 1000) {
      setError(t("text.error"));
    }

    sendAnswer(event.target.value);
  };

  React.useEffect(() => {
    if (answerHistory) {
      sendAnswer(answerHistory);
    } else {
      sendAnswer("");
    }
  
  }, []);

  React.useEffect(() => {
    if (answerHistory && answerHistory.length > 0) {
      setCount(answerHistory.length);
    }
    
  }, []);

  return (
    <div>
      <TextArea
        defaultValue={answerHistory}
        maxLength={1000}
        onChange={(e) => handleChange(e)}
        placeholder={t("common.input.text_placeholder")}
        rows="3"
        wrap="hard"
      />
      <Count>{t("text.character_limit", { count })}</Count>
      <ErrorText>{error}</ErrorText>

      {options.image !== "" && (
        <Img src={options.image} alt={options.alt_text} />
      )}
    </div>
  );
}

const TextArea = styled.textarea(() => [
  tw`form-textarea block rounded w-full`,
  tw`border border-gray-500 hover:border-blue-100`,
]);

const Count = styled.p(() => [
  tw`font-light`,
]);

const ErrorText = styled.p(() => [
  tw`text-sm italic text-red`,
]);

const Img = styled.img(() => [
  tw`mx-auto object-contain py-4`,
]);
