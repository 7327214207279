import { useEffect, useMemo } from 'react';
import _ from 'lodash';
import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/ar';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/hi';
import 'moment/locale/it';
import 'moment/locale/pa-in';
import 'moment/locale/pl';
import 'moment/locale/pt';
import 'moment/locale/ur';
import 'moment/locale/zh-hk';
import 'moment/locale/en-ca';
import { useTranslation } from 'react-i18next';
import { useGetPatientQuery } from '../patient-app-common/api/patientApi';

export default function useLocale(options = { skip: false }) {
  const { i18n } = useTranslation();

  const {
    data,
    error,
  } = useGetPatientQuery(undefined, { skip: options.skip });

  const patientLocale = useMemo(() => {
    if (!data || error) return null;

    const {
      languageId,
      languages,
    } = data;

    const language = _.find(languages, { id: languageId });
    const locale = language?.languageCode;

    if (!locale) {
      return 'en';
    }

    return locale;
  }, [data, error]);

  useEffect(() => {
    if (!patientLocale) {
      return;
    }

    switch (patientLocale) {
      case 'pa':
        moment.locale('pa-in');
        break;
      case 'zh-HK':
        moment.locale('zh-hk');
        break;
      default:
        moment.locale(patientLocale);
    }

    i18n.changeLanguage(patientLocale);
    // document.documentElement.lang = patientLocale;
  }, [patientLocale]);

  return {
    patientLocale,
  };
}
