import React from 'react';
import Camera from './Camera';
import Checkbox from './Checkbox';
import Device from './Device';
import DateText from './DateText';
import FaceScale from './FaceScale';
import GenScale from './GenScale';
import Medicine from './Medicine';
import NumInput from './NumInput';
import HeightInput from './HeightInput';
import WeightInput from './WeightInput';
import Picker from './Picker';
import RadioBtn from './RadioBtn';
import Text from './Text';

export default function QuestionType({
  answers,
  getAnswer,
  index,
  medicineHistory,
  questions,
}) {
  if (!questions[index].answers) {
    return <div />;
  }

  const key = questions[index].id;

  const answerHistory = answers[questions[index].id];

  const data = questions[index].answers.map((a, i) => ({
    key: i.toString(),
    text: a.text?.trim(),
    options: a.options,
  }));

  const getNumInput = () => {
    switch (questions[index].options.unit) {
      case 'height':
        return (
          <HeightInput
            key={key}
            answerHistory={answerHistory}
            sendAnswer={(value) => getAnswer(value)}
            options={questions[index].options}
          />
        );

      case 'weight':
        return (
          <WeightInput
            key={key}
            answerHistory={answerHistory}
            sendAnswer={(value) => getAnswer(value)}
            options={questions[index].options}
          />
        );

      default:
        return (
          <NumInput
            key={key}
            answerHistory={answerHistory}
            sendAnswer={(value) => getAnswer(value)}
            options={questions[index].options}
          />
        );
    }
  };

  switch (questions[index].type) {
    case 'boolean':
    case 'radio':
    case 'scale':
      return (
        <RadioBtn
          key={key}
          data={data}
          answerHistory={answerHistory}
          sendAnswer={(value) => getAnswer(value)}
          options={questions[index].options}
        />
      );

    case 'camera':
      return (
        <Camera
          key={key}
          answerHistory={answerHistory}
          sendAnswer={(value) => getAnswer(value)}
          questionID={questions[index].id}
        />
      );

    case 'checkbox':
      return (
        <Checkbox
          key={key}
          data={data}
          answerHistory={answerHistory}
          sendAnswer={(value) => getAnswer(value)}
          options={questions[index].options}
        />
      );

    case 'date':
      return (
        <DateText
          key={key}
          answerHistory={answerHistory}
          sendAnswer={(value) => getAnswer(value)}
          options={questions[index].options}
        />
      );

    case 'device':
      return (
        <Device
          sendAnswer={(value) => getAnswer(value)}
        />
      );

    case 'face_scale':
      return (
        <FaceScale
          key={key}
          answerHistory={answerHistory}
          sendAnswer={(value) => getAnswer(value)}
        />
      );

    case 'gen_scale':
      return (
        <GenScale
          key={key}
          data={data}
          answerHistory={answerHistory}
          sendAnswer={(value) => getAnswer(value)}
          options={questions[index].options}
        />
      );

    case 'medicine':
      return (
        <Medicine
          key={key}
          data={data}
          answerHistory={answerHistory}
          medicineHistory={medicineHistory}
          sendAnswer={(value) => getAnswer(value)}
          options={questions[index].options}
        />
      );

    case 'numerical_input':
      return getNumInput();

    case 'picker':
      return (
        <Picker
          key={key}
          data={data}
          answerHistory={answerHistory}
          sendAnswer={(value) => getAnswer(value)}
        />
      );

    case 'text':
      return (
        <Text
          key={key}
          answerHistory={answerHistory}
          sendAnswer={(value) => getAnswer(value)}
          options={questions[index].options}
        />
      );

    default:
      return <div />;
  }
}
