import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { FiEdit } from 'react-icons/fi';
import tw, { styled } from 'twin.macro';
import { getImageNumber } from '../../../patient-app-common/helpers/survey_helper';

export default function QuestionAnswer({
  answers,
  handleEdit,
  questions,
}) {
  const { t } = useTranslation('survey');

  const getQuestionAnswer = questions.map((question, i) => {
    if (!answers.hasOwnProperty(`q${i + 1}`)) {
      return null;
    }

    let answer;
    switch (question.type) {
      case 'camera':
        answer = t('common.text.no_entry');

        if (answers[question.id]) {
          answer = (
            <img
              alt={t('common.text.attachment')}
              width="150px !important"
              src={URL.createObjectURL(answers[question.id])}
            />
          );
        }
        break;

      case 'checkbox':
        answer = [];
        answers[question.id].forEach(
          (value, i) => value
            && answer.push(
              <li key={question.answers[i].text} className="ml-2">
                {question.answers[i].text}
              </li>,
            ),
        );
        break;

      case 'device':
        break;

      case 'face_scale':
        answer = `${answers[question.id] * 2
          } - ${t(`facescale.value_${answers[question.id]}`)}`;
        break;

      case 'gen_scale':
      case 'radio':
        answer = question.answers[answers[question.id]].text;
        break;

      case 'medicine':
        answer = [];
        answers[question.id].forEach((value, i) => (
          !_.isEmpty(value)
          && answer.push(
            <li key={question.answers[i].text} className="ml-2">
              {`${question.answers[i].text} ${value.dose} - ${value.pills} pills`}
            </li>,
          )
        ));
        break;

      case 'numerical_input':
        answer = answers[question.id];
        break;

      case 'picker':
        answer = t('picker.selected_hint', {
          answer: getImageNumber(question.answers[answers[question.id]].text),
        });
        break;

      default:
        answer = answers[question.id] || t('common.text.no_entry');
    }

    return (
      <Container key={i}>
        <ContentDiv>
          <div className="flex flex-col">
            <p className="font-semibold pb-4">{question.title}</p>
            <p className="pb-4 whitespace-pre-wrap">{answer}</p>
          </div>
          <div>
            <button
              aria-label={question.title}
              className="btn-secondary"
              onClick={() => handleEdit(i)}
            >
              <FiEdit className="inline-flex mr-2" />
              {t('common.action.edit')}
            </button>
          </div>
        </ContentDiv>

      </Container>
    );
  });

  return (
    <>
      {getQuestionAnswer}
    </>
  );
}

const Container = styled.div(() => [
  tw`bg-white flex mt-4 rounded-lg shadow w-full`,
  tw`p-4 md:p-8`,
]);

const ContentDiv = styled.div(() => [
  tw`flex justify-between w-full`,
  tw`flex-col md:flex-row`,
]);
