import React from 'react';
import moment from 'moment';
import { Reply } from './Reply';
import { MessagePayload } from '../../../patient-app-common/types/SecureMessageThreads';

interface PatientMsgProps {
  message: MessagePayload
}

export function PatientReply({ message }: PatientMsgProps) {
  return (
    <div className="flex justify-end mr-4 mb-4">
      <div className="flex flex-col">
        <Reply message={message} />
        <p className="font-light text-sm text-right">
          {moment(message.createdAt).format('MMM D hh:mm a')}
        </p>
      </div>
    </div>
  );
}
