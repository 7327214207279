import React from 'react';
import tw, { styled } from 'twin.macro';

interface AlertCardProps {
  title?: string;
  text?: string;
  handleAlertClick: () => void;
}

export default function AlertCard({ title, text, handleAlertClick }: AlertCardProps) {
  return (
    <Btn onClick={() => handleAlertClick()}>
      <p className="font-bold text-blue-100">
        {title}
      </p>
      <p>{text}</p>
    </Btn>
  );
}

const Btn = styled.button(() => [
  tw`bg-blue-10 mt-4 p-4 text-left w-full`,
  tw`border-l-4 border-green-100`,
]);
