/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import { camelizeKeys } from 'humps';

function parseJSON(object) {
  if (_.isString(object)) {
    try {
      const result = JSON.parse(object);
      return camelizeKeys(result);
    } catch (_e) {
      return [];
    }
  }
  return object;
}

export function meetsProfileDependency(profileDependencies, type, patientProfile) {
  let count = 0;
  let dependencyType = type;

  // TODO: remove this temporary code
  // due to caching of old data, profile dependencies can be in json string format
  profileDependencies = parseJSON(profileDependencies);

  if (profileDependencies.length === 0) {
    return true;
  }

  profileDependencies.forEach((dependency) => {
    const profileKey = patientProfile[dependency.keyId];

    if (profileKey && dependency.value === profileKey.value) {
      count++;
    }
  });

  if (!dependencyType) {
    dependencyType = 'or';
  }
  if (dependencyType === 'or' && count > 0) {
    return true;
  }
  if (dependencyType === 'and' && count === profileDependencies.length) {
    return true;
  }
  return false;
}

// returns a date time in milliseconds
export function getOffsetStart(refDate, offsetMinutes, offsetType) {
  const MINUTE_IN_MILLISECONDS = 60000;
  return refDate + (offsetMinutes * MINUTE_IN_MILLISECONDS * offsetType);
}
