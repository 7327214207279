import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import tw, { styled } from 'twin.macro';
import { setUnitPref } from '../../../patient-app-common/slices/appSlice';

export default function HeightInput({ sendAnswer, answerHistory }) {
  const dispatch = useDispatch();
  let { unitPref } = useSelector((state) => state.app);

  if (!unitPref) {
    unitPref = 'metric';
  }

  const [unit, setUnit] = useState(unitPref);

  const handleToggleClick = (unitStr) => {
    if (unitStr === unit) {
      return;
    }

    if (unitStr === 'metric') {
      setUnit('metric');
      dispatch(setUnitPref('metric'));
    }

    if (unitStr === 'imperial') {
      setUnit('imperial');
      dispatch(setUnitPref('imperial'));
    }

    sendAnswer(undefined);
  };

  return (
    <Container>
      {unit === 'metric' && (
        <MetricInput sendAnswer={sendAnswer} answerHistory={answerHistory} />
      )}

      {unit === 'imperial' && (
        <ImperialInput sendAnswer={sendAnswer} answerHistory={answerHistory} />
      )}

      <Row>
        <UnitPanel>
          <div className="pr-2">
            <input
              id="imperial"
              name="imperial"
              type="radio"
              checked={unit === 'imperial'}
              onChange={() => handleToggleClick('imperial')}
            />
          </div>
          <div>
            <label htmlFor="imperial" className="font-bold">
              Feet and inches
            </label>
          </div>

          <div className="pl-8 px-2">
            <input
              id="metric"
              name="metric"
              type="radio"
              checked={unit === 'metric'}
              onChange={() => handleToggleClick('metric')}
            />
          </div>
          <div>
            <label htmlFor="metric" className="font-bold">
              Centimetres (cm)
            </label>
          </div>
        </UnitPanel>
      </Row>
    </Container>
  );
}

function ImperialInput({ sendAnswer, answerHistory }) {
  const [errorFt, setErrorFt] = useState('');
  const [errorInch, setErrorInch] = useState('');

  const [height, setHeight] = useState({
    feet: '',
    inches: '',
  });

  const convertInitAnswer = useCallback(() => {
    if (answerHistory) {
      const totalInches = answerHistory * 0.3937;
      let feet = totalInches / 12;
      let inches = totalInches % 12;

      if (inches > 11) {
        feet += 1;
        inches = 0;
      }

      setHeight({
        feet: _.floor(feet),
        inches: _.round(inches),
      });
    }
  }, [answerHistory]);

  useEffect(() => {
    convertInitAnswer();
    
  }, []);

  const validateNumber = (event, input) => {
    const isValid = event.target.validity.valid;
    const value = parseFloat(event.target.value);

    switch (input) {
      case 'feet':
        if (!isValid) {
          setErrorFt('Enter a whole number for feet');
          return false;
        }
        break;

      case 'inches':
        if (!isValid) {
          setErrorInch('Enter a whole number for inches');
          return false;
        }

        if (value >= 12) {
          setErrorInch('Enter 12 inches as 1 foot');
          return false;
        }
        break;

      default:
    }

    return true;
  };

  const handleInputChange = (event, input) => {
    const value = parseFloat(event.target.value);
    const valid = validateNumber(event, input);

    if (!valid) {
      setHeight({
        ...height,
        [input]: undefined,
      });
      return;
    }

    if (input === 'feet') {
      setErrorFt('');
    } else {
      setErrorInch('');
    }

    setHeight({
      ...height,
      [input]: value.toString(),
    });
  };

  useEffect(() => {
    const convertToCM = () => {
      const { feet, inches } = height;
      const cm = parseFloat(feet) * 30.48 + parseFloat(inches) * 2.54;
      return _.round(cm, 2);
    };

    const validateAnswer = () => {
      const { feet, inches } = height;

      if (feet === undefined || inches === undefined) {
        sendAnswer(undefined);
      } else {
        setErrorFt('');
        setErrorInch('');

        const answer = convertToCM().toString();
        sendAnswer(answer);
      }
    };

    validateAnswer();
    
  }, [height]);

  return (
    <>
      <FtInput
        id="feet"
        type="number"
        min="0"
        step="1"
        onInput={(e) => handleInputChange(e, 'feet')}
        defaultValue={height.feet}
      />
      <Label htmlFor="feet">Ft</Label>

      <FtInput
        id="inch"
        type="number"
        min="0"
        step="1"
        onInput={(e) => handleInputChange(e, 'inches')}
        defaultValue={height.inches}
      />
      <Label htmlFor="inch">In</Label>

      <Row role="alert">
        <ErrorDiv>
          <div className="flex-col">
            {errorFt !== '' && <p className="error-text">{errorFt}</p>}
            {errorInch !== '' && <p className="error-text">{errorInch}</p>}
          </div>
        </ErrorDiv>
      </Row>
    </>
  );
}

function MetricInput({ sendAnswer, answerHistory }) {
  const [error, setError] = useState('');

  const handleInput = (event) => {
    const answer = event.target.value;
    const isValid = event.target.validity.valid;

    if (!isValid) {
      setError('Enter a number up to 2 decimal places');
    } else {
      setError('');
      sendAnswer(_.round(answer, 2).toString());
    }
  };

  return (
    <>
      <CmInput
        id="cm"
        type="number"
        min="0"
        step="0.01"
        onInput={(e) => handleInput(e)}
        defaultValue={answerHistory}
      />
      <Label htmlFor="cm">cm</Label>

      <Row>
        <ErrorDiv>
          <p className="error-text">{error}</p>
        </ErrorDiv>
      </Row>
    </>
  );
}

const Container = styled.div(() => [
  tw`w-full md:w-1/2`,
]);

const Row = styled.section(() => [
  tw`flex justify-center`,
]);

const FtInput = styled.input(() => [
  tw`border border-gray-500 mb-4 p-2 rounded w-3/4`,
  tw`text-lg sm:text-2xl`,
]);

const CmInput = styled.input(() => [
  tw`border border-gray-500 p-2 rounded w-3/4`,
  tw`text-lg sm:text-2xl`,
]);

const Label = styled.label(() => [
  tw`px-2 w-1/4`,
  tw`text-lg sm:text-2xl`,
]);

const ErrorDiv = styled.div(() => [
  tw`flex flex-initial w-full`,
]);

const UnitPanel = styled.div(() => [
  tw`inline-flex pt-4 w-full`,
]);
