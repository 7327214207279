import React from 'react';
import ReactJson from 'react-json-view';
import LoaderInline from '../layout/LoaderInline';
import { useGetPatientQuery } from '../../patient-app-common/api/patientApi';
import { useLazyGetProcedureQuery } from '../../patient-app-common/api/procedureApi';
import { useLazyGetEducationQuery } from '../../patient-app-common/api/educationApi';
import { useLazyGetApplicationEventsQuery } from '../../patient-app-common/api/applicationEventApi';
import { useLazyGetAllSurveysQuery } from '../../patient-app-common/api/surveyApi';

export default function JsonView() {
  const [data, setData] = React.useState({});
  const [loader, showLoader] = React.useState(false);

  const { data: patient, isLoading: isLoadingPatient } = useGetPatientQuery();

  const [allSurveys] = useLazyGetAllSurveysQuery();
  const [applicationEvents] = useLazyGetApplicationEventsQuery();
  const [education] = useLazyGetEducationQuery();
  const [procedureSet] = useLazyGetProcedureQuery();

  const fetchData = async (callback, id = null) => {
    showLoader(true);
    const results = await callback(id);

    if (results.isSuccess) {
      setData(results.data);
    }

    showLoader(false);
  };

  const handleValueSelect = (e) => {
    switch (e.target.value) {
      case 'procedure':
        fetchData(procedureSet);
        break;

      case 'education':
        fetchData(education);
        break;

      case 'appevent':
        fetchData(applicationEvents);
        break;

      case 'surveys':
        fetchData(allSurveys);
        break;

      default:
        setData({});
    }
  };

  return (
    <div className="p-8">
      <p>You can view the application data here for debugging</p>
      <div className="flex flex-col py-8">
        <div>
          <select
            name="json"
            id="json"
            defaultValue=""
            onChange={(e) => handleValueSelect(e)}
            className="form-select text-sm w-full sm:w-1/3"
          >
            <option value="" disabled>Select an option</option>
            <option value="appevent">Application Events</option>
            <option value="education">Education</option>
            <option value="surveys">All Surveys</option>
            <option value="procedure">Procedure API data (deprecated)</option>
          </select>

          <span className="align-bottom pl-4">
            { (isLoadingPatient || loader) && <LoaderInline /> }
          </span>
        </div>
      </div>

      <div>
        <ReactJson
          name="patient"
          collapsed
          src={patient}
        />
      </div>

      <div>
        <ReactJson
          collapsed
          src={data}
        />
      </div>
    </div>
  );
}
