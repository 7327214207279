/* eslint-disable no-useless-escape */
/* eslint-disable no-mixed-operators */

// returns the value of a param in a url query string
export function getURLParam(queryString, param) {
  param = param.replace(/[\[]/, "\\[")
    .replace(/[\]]/, "\\]");
  const regex = new RegExp(`[\\?&]${param}=([^&#]*)`);
  const results = regex.exec(queryString);
  return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

// https://gist.github.com/jed/982883
// For legacy browsers
// Returns a random v4 UUID of the form xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx, where each x is replaced with a random hexadecimal digit from 0 to f, and y is replaced with a random hexadecimal digit from 8 to b.
export function b( // @ts-ignore
  a,
) {
  return a // if the placeholder was passed, return
    ? ( // a random number from 0 to 15
      a // unless b is 8,
      ^ Math.random() // in which case
      * 16 // a random number from
      >> a / 4 // 8 to 11
    ).toString(16) // in hexadecimal
    : ( // or otherwise a concatenated string:
      // @ts-ignore
      [1e7] // 10000000 +
      + -1e3 // -1000 +
      + -4e3 // -4000 +
      + -8e3 // -80000000 +
      + -1e11 // -100000000000,
    ).replace( // replacing
      /[018]/g, // zeroes, ones, and eights with
      b, // random hex digits
    );
}
