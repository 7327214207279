import React, { useEffect, useMemo, useState } from 'react';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import tw, { styled } from 'twin.macro';
import ResultCard from './ResultCard';
import { useGetSurveyQuery } from '../../../patient-app-common/api/surveyApi';
import useResults from '../../../patient-app-common/hooks/useResults';
import { useGetLastTrackerQuery } from '../../../patient-app-common/api/trackerApi';
import { Loader } from '../../layout';

export default function Result() {
  const { t } = useTranslation('survey');
  const navigate = useNavigate();
  const { state } = useLocation();
  const { surveyId } = useParams();
  const patientEntry = state?.patientEntry;

  const { data: fetchedEntry, isLoading: isLoadingEntry } = useGetLastTrackerQuery(surveyId, {
    skip: patientEntry || !surveyId,
  });

  const { data: survey, isLoading: isLoadingSurvey } = useGetSurveyQuery(surveyId, {
    skip: !surveyId,
  });

  const isLoading = isLoadingEntry || isLoadingSurvey;
  const [loader, setLoader] = useState(true);

  const { createResults } = useResults();

  const [recommendations, setRecommendations] = useState(null);

  const divRef = React.useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setLoader(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (!survey) return;

    const entry = patientEntry || fetchedEntry;
    if (!entry) return;

    if (entry.createdAt < survey.lastEntryDate) return;

    const parseRecommendations = async () => {
      setLoader(true);
      const results = await createResults(survey, entry);
      setRecommendations(results);
      setLoader(false);
    };

    parseRecommendations();
  }, [survey, patientEntry, fetchedEntry]);

  const hasCharts = useMemo(() => {
    if (!survey) return false;
    return survey.questions.find((question) => question.chart);
  }, [survey]);

  const handleBtnClick = () => {
    if (state?.hasNextSurvey) {
      navigate(state?.nextSurveyPathname, {
        state: { eventId: state?.nextSurveyEventId },
      });
    } else {
      const pathname = hasCharts ? `/resultcharts/${survey.id}` : '/';
      navigate({ pathname });
    }
  };

  const getResults = () => {
    if (!recommendations?.length) {
      return <div />;
    }

    return recommendations.map((recommendation) => (
      <ResultCard
        key={recommendation.id}
        recommendation={recommendation}
      />
    ));
  };

  if (!loader && !recommendations) {
    return <Navigate to="/" replace />;
  }

  return (
    <div>
      <Loader visible={loader} />
      <HeaderDiv>
        <div className="flex-1">
        </div>
        <div className="inline-flex">
          <SkipBtn onClick={() => divRef.current.focus()}>
            {t('common.link.skip')}
          </SkipBtn>
          <button className="block btn-danger" onClick={() => handleBtnClick()}>
            {t('common.action.close')}
          </button>
        </div>
      </HeaderDiv>

      <ResultsDiv
        ref={divRef}
        role="region"
        tabIndex={0}
      >
        <div className="pt-8">
          {getResults()}
        </div>
      </ResultsDiv>

      <FooterDiv>
        <div className="flex justify-center">
          <Btn className="btn-primary" onClick={() => handleBtnClick()}>
            {t('common.action.ok')}
          </Btn>
        </div>
      </FooterDiv>
    </div>
  );
}

const HeaderDiv = styled.header(() => [
  tw`bg-white border-b border-gray-500 py-2`,
  tw`fixed flex flex-wrap items-center w-full`,
  tw`lg:px-16 px-6`,
  tw`top-0 z-10`,
]);

const SkipBtn = styled.button(() => [
  tw`text-white`,
  tw`focus:text-black`,
  tw`hidden sm:block`,
]);

const ResultsDiv = styled.div(() => [
  tw`bg-gray-100 mt-16 mb-24`,
]);

const FooterDiv = styled.footer(() => [
  tw`bg-white border-gray-300 border-t p-4 shadow-top w-full`,
  tw`bottom-0 fixed z-10`,
]);

const Btn = styled.button(() => [
  tw`shadow w-full md:w-1/2 lg:w-1/3`,
  tw`text-lg sm:text-2xl`,
]);

