import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import tw, { css, styled } from 'twin.macro';
import _ from 'lodash';
import useAppEvents from '../../patient-app-common/hooks/useAppEvents';
import TodoEvent from './TodoEvent';
import useSegment from '../../hooks/useSegment';
import { useReadEventMutation } from '../../patient-app-common/api/patientApi';
import { AppEvent } from '../../patient-app-common/types/ApplicationEvent';

const EVENT = 'To do item click';

export default function TodoList() {
  const { t } = useTranslation('todo');
  const { activeTodos } = useAppEvents();
  const { sendTrackEvent } = useSegment();

  const [readEvent] = useReadEventMutation();

  const percentage = useMemo(() => {
    if (!activeTodos.length) return 0;
    const tasksDone = activeTodos.filter((tasks) => tasks.done).length;

    return Math.round((tasksDone / activeTodos.length) * 100);
  }, [activeTodos]);

  let progressText = '';

  if (_.isNumber(percentage)) {
    progressText = t('todoheader.title', { percentage });
  }

  const handleTodoCheckbox = async (todo: AppEvent, index: number) => {
    const status = !activeTodos[index].done;

    const params = {
      eventId: todo.id,
      value: status.toString(),
    };

    try {
      await readEvent(params).unwrap();

      sendTrackEvent(EVENT, {
        id: todo.id,
        checked: todo.done,
        percentComplete: percentage,
      });
    } catch (e) {
      console.log(`TodoList error: ${e}`);
    }
  };

  return (
    <>
      <H1>
        {t('common.link.todo')}
      </H1>
      <Container>
        <TodoHeader>
          <div className="bg-gray-300 rounded mb-4">
            <ProgressBar
              aria-valuenow={percentage}
              aria-valuemax="100"
              progress={percentage}
              role="progressbar"
            >
              {percentage}
              %
            </ProgressBar>
          </div>
          {progressText}
        </TodoHeader>
        {activeTodos.length > 0 && activeTodos.sort((a, b) => Number(a.done) - Number(b.done)).map((todo, i) => (
          <div key={todo.id} role="list">
            <TodoEvent
              event={todo}
              handleInputChange={() => handleTodoCheckbox(todo, i)}
            />
          </div>
        ))}
      </Container>
    </>
  );
}

const Container = styled.div(() => [
  tw`bg-white border-gray-500 p-4 rounded-lg shadow-md`,
]);

const TodoHeader = styled.div(() => [
  tw`border-b border-gray-300 py-4`,
]);

const H1 = styled.h1(() => [
  tw`font-light pb-4`,
  tw`text-lg sm:text-2xl`,
]);

const ProgressBar = styled.div(({ progress }: { progress: number }) => [
  tw`bg-green-100 text-sm leading-none py-1 text-center`,
  tw`rounded-tl rounded-bl`,
  progress > 0 && tw`text-white`,
  css`
    width: ${progress}%;
  `,
]);
