import React, { useMemo } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import Table from './Table';

export default function TablePreview({ question }) {
  const { t } = useTranslation();

  const entries = useMemo(() => {
    const today = moment().startOf('day');
    const { patientEntries } = question;
    const data = [];

    for (let i = 0; i <= 4; i += 1) {
      const dayOfWeek = moment(today).subtract(i, 'days');
      const datum = {};
      // if there are multiple entries on the same day, get the last entry
      const entry = _.findLast(patientEntries, ({ date }) => moment(date).isSame(dayOfWeek, 'day'));

      if (entry) {
        datum.date = entry.date;
        datum.dateLabel = moment(entry.date).format('ddd D');
        datum.answer = entry.answer;
        datum.skipped = false;
      } else {
        datum.date = dayOfWeek;
        datum.dateLabel = moment(dayOfWeek).format('ddd D');
        datum.skipped = true;
      }

      if (i === 0) datum.dateLabel = t('common.text.today');
      data.unshift(datum);
    }

    return data;
  }, [question]);

  return (
    <Table
      question={question}
      entries={entries}
    />
  );
}
