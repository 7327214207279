import { camelizeKeys } from 'humps';
import _ from 'lodash';
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { produce } from "immer";
import { useActionCable } from '../hooks/useActionCable';
import { useChannel } from '../hooks/useChannel';
import { useGetPatientQuery } from '../patient-app-common/api/patientApi';
import { useGetSecureMessageThreadsQuery } from '../patient-app-common/api/secureMessageApi';
import {
  ActionCablePayload,
  ThreadPayload,
} from '../patient-app-common/types/SecureMessageThreads';
import { useAppSelector } from '../redux/hooks';

type ActionCableContextType = {
  secureMessageThreads: ThreadPayload[];
  error: unknown;
  isLoading: boolean;
  inboxCount: number;
  markThreadAsRead: (id: number) => void;
};

export const ActionCableContext = createContext<ActionCableContextType>({
  secureMessageThreads: [],
  error: null,
  isLoading: false,
  inboxCount: 0,
  markThreadAsRead: () => null,
})

export const ActionCableProvider = ({ children }: { children: JSX.Element }) => {
  const { patientId } = useAppSelector(({ auth }) => auth);
  const { data: patient } = useGetPatientQuery(undefined, {
    skip: !patientId
  });

  if (!patientId || !patient?.secureMessagingEnabled) {
    return (
      <>
        {children}
      </>
    );
  }

  return (
    <ThreadsProvider>
      {children}
    </ThreadsProvider>
  );
};

const ThreadsProvider = ({ children }: { children: JSX.Element }) => {
  const { token } = useAppSelector(({ auth }) => auth);
  const { route } = useAppSelector(({ app }) => app);

  const [secureMessageThreads, setSecureMessageThreads] = useState<ThreadPayload[]>([]);

  const { data: threads, error, isLoading } = useGetSecureMessageThreadsQuery(undefined, {
    refetchOnMountOrArgChange: true,
    // refresh cache every hour
    pollingInterval: 3600000,
  });

  const wssRoute = _.replace((route || ''), 'https://', 'wss://');

  const { actionCable } = useActionCable(`${wssRoute}/cable?token=${token}`);

  const { subscribe, unsubscribe } = useChannel(actionCable);

  const inboxCount = useMemo(() => {
    if (secureMessageThreads) {
      return _.filter(secureMessageThreads, ['unreadMessage', true]).length;
    }
    return 0;
  }, [secureMessageThreads]);

  const handleReceived = useCallback((data: unknown) => {
    const payload = camelizeKeys(data) as ActionCablePayload;

    const newMessage = {
      createdAt: payload.createdAt,
      file: payload.file,
      fileType: payload.fileType,
      id: payload.secureMessageId,
      sentBy: payload.sentBy,
      sentByName: payload.sentByName,
      text: payload.text,
    };

    const { 
      resolved,
      unreadMessage,
      patientReplyEnabled,
    } = payload;

    setSecureMessageThreads(
      produce((draft) => {
        const existingThread = draft
          .find((thread) => thread.id === payload.secureMessageThreadId);

        if (existingThread) {
          existingThread.messages.push(newMessage);
          existingThread.lastMessageSentAt = payload.createdAt;
          existingThread.patientReplyEnabled = patientReplyEnabled;
          existingThread.resolved = resolved;
          existingThread.unreadMessage = unreadMessage;
        } else {
          const newThread = {
            createdAt: payload.createdAt,
            id: payload.secureMessageThreadId,
            lastMessageSentAt: payload.createdAt,
            messages: [newMessage],
            patientReplyEnabled,
            resolved,
            unreadMessage,
          };

          draft.push(newThread);
        }
      }),
    );
  }, [threads]);

  const markThreadAsRead = (id: number) => {
    setSecureMessageThreads(
      produce((draft) => {
        const existingThread = draft
          .find((thread) => thread.id === id);

        if (existingThread) {
          existingThread.unreadMessage = false;
        } 
      }),
    );
  }

  useEffect(() => {
    subscribe({
      channel: 'PatientChannel',
    }, {
      received: (data: unknown) => {
        handleReceived(data);
      },
    });
    return () => {
      unsubscribe();
    };
  }, [handleReceived, threads]);

  useEffect(() => {
    if (!threads) return;
    setSecureMessageThreads(threads);
  }, [threads]);

  const contextData = {
    secureMessageThreads,
    error,
    isLoading,
    inboxCount,
    markThreadAsRead,
  };

  return (
    <ActionCableContext.Provider value={contextData}>
      {children}
    </ActionCableContext.Provider>
  );
}
