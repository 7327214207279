import React from 'react';
import { useTranslation } from 'react-i18next';
import tw, { css, styled } from 'twin.macro';
import { BiXCircle } from 'react-icons/bi';

interface SearchInputProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClear: () => void;
}

export default function SearchInput({
  value,
  onChange: handleChange,
  onClear: handleClear,
}: SearchInputProps) {
  const { t } = useTranslation('education');

  return (
    <div className="p-6 relative">
      <Input
        type="text"
        value={value}
        onChange={handleChange}
        placeholder={t('edulist.searchbar')}
      />
      {value && (
        <Btn aria-label={t('common.action.clear')} onClick={handleClear}>
          <span className="text-black hover:text-red"><BiXCircle /></span>
        </Btn>
      )}
    </div>
  );
}

const Input = styled.input(() => [
  tw`form-input block border-black shadow text-lg w-full`,
  css`&::-webkit-input-placeholder {
    color: #000D1D;
  };`,
  css`&::-ms-clear {
    display: none;
  }`,
]);

const Btn = styled.button(() => [
  tw`absolute right-0 top-0 mt-8 mr-10 text-3xl`,
]);
