import React from 'react';
import { useTranslation } from 'react-i18next';
import tw, { styled } from 'twin.macro';
import Panel from '../../layout/Panel';
import { useUpdatePatientMutation } from '../../../patient-app-common/api/patientApi';
import { LoaderInline } from '../../layout';

export default function SetPwd() {
  const { t } = useTranslation('settings');
  const [updatePatient, { isLoading }] = useUpdatePatientMutation();

  const [errorFields, setErrorFields] = React.useState([]);
  const [error, setError] = React.useState('');
  const [success, setSuccess] = React.useState(false);

  const [state, setState] = React.useState({
    old: '',
    new: '',
    confirm: '',
  });

  const handleInputChange = (e) => {
    setError('');
    setSuccess(false);

    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validatePwd = () => {
    const formErrors = [];

    for (const [key, value] of Object.entries(state)) {
      if (!value) {
        formErrors.push(key);
      }
    }

    const hasNumber = /[0-9]/.test(state.new);

    if (state.new && (state.new.length < 8 || !hasNumber)) {
      setError(t('setpwd.error.invalid'));
      setErrorFields(['new']);
      return false;
    }

    if (formErrors.length) {
      setErrorFields(formErrors);
      setError(t('setpwd.error.blank'));
      return false;
    }

    if (state.new !== state.confirm) {
      setErrorFields(['new', 'confirm']);
      setError(t('setpwd.error.nomatch'));
      return false;
    }

    return true;
  };

  const handleBtnClick = async () => {
    setErrorFields([]);
    setError('');
    setSuccess(false);

    const valid = validatePwd();

    if (!valid) {
      return;
    }

    let errorText = t('setpwd.error.server.1');

    const params = {
      oldPassword: state.old,
      password: state.new,
    };

    try {
      const result = await updatePatient(params).unwrap();
      if (result.success) {
        setSuccess(true);
      } else {
        errorText = t('setpwd.error.server.1');
        setErrorFields(['old']);
        setError(errorText);
      }
    } catch (e) {
      errorText = t('setpwd.error.server.2');
      setError(errorText);
    }
  };

  return (
    <Panel title={t('account.link.edit_password')}>
      <div className="flex justify-center w-full">
        <FormContainer>
          <Label htmlFor="old">
            {t('profileedit.input.current_password')}
            <Input
              error={errorFields.includes('old')}
              id="old"
              name="old"
              onChange={(e) => handleInputChange(e)}
              type="password"
            />
          </Label>

          <Label htmlFor="new" className="block pb-4">
            {t('profileedit.input.new_password')}
            <Input
              error={errorFields.includes('new')}
              id="new"
              name="new"
              onChange={(e) => handleInputChange(e)}
              type="password"
            />
          </Label>

          <Label htmlFor="confirm" className="block pb-4">
            {t('common.input.confirm_password')}
            <Input
              error={errorFields.includes('confirm')}
              id="confirm"
              name="confirm"
              onChange={(e) => handleInputChange(e)}
              type="password"
            />
          </Label>

          <div className="text-center">
            <button className="btn-primary" onClick={() => handleBtnClick()}>
              {t('common.action.submit')}
            </button>
            {error && <p className="error-text pt-4">{error}</p>}
            {success && <p className="italic pt-4">{t('common.text.update_success')}</p>}
          </div>
          <div className="text-center">{isLoading && <LoaderInline />}</div>
        </FormContainer>

      </div>
    </Panel>
  );
}

const FormContainer = styled.div(() => [
  tw`bg-blue-10 border border-gray-500 p-4 rounded w-3/4`,
]);

const Label = styled.label(() => [
  tw`block pb-4 font-bold`,
]);

const Input = styled.input(({ error }) => [
  tw`form-input border-black block w-full`,
  error && tw`border-red`,
]);
