import React, { useEffect, useMemo } from 'react';
import {
  Point,
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryScatter,
  VictoryVoronoiContainer,
} from 'victory';
import { scaleTime } from 'd3-scale';
import { timeFormatDefaultLocale } from 'd3-time-format';
import { scaleDiscontinuous } from '@d3fc/d3fc-discontinuous-scale';
import _ from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { D3_LOCALES } from '../../../../localization/d3-time-format';
import useLocale from '../../../../hooks/useLocale';

export default function LineChart({ question, filteredEntries, mode }) {
  const { t } = useTranslation();
  const { patientLocale: locale } = useLocale();

  useEffect(() => {
    if (locale && _.has(D3_LOCALES, locale)) {
      timeFormatDefaultLocale(D3_LOCALES[locale]);
    }
  }, [locale]);

  const discontinuousScale = scaleDiscontinuous(scaleTime());

  const entries = filteredEntries ?? question.patientEntries;

  const data = entries.map(({ answer, date, skipped }) => {
    const answerValue = _.toNumber(answer);
    // exclude entries that cannot be parsed to a number
    if (!_.isNil(answer) && _.isNumber(answerValue)) {
      return ({
        answer: answerValue,
        // Victory requires the date to be a JS date object
        date: new Date(date),
        skipped,
      });
    }
  });

  const areaData = [...data];
  _.remove(areaData, ['skipped', true]);

  const scatterDotSize = ({ datum }) => {
    if (areaData.length === 1 && !datum.skipped) {
      return 3;
    }
    return 0;
  };

  const dependentAxis = () => {
    let yValues;
    let yLabels;

    const {
      answers,
      stepValue,
      type,
    } = question;

    // for numerical input, set the min and max for y-scale based on patient entries +/- step value
    if (type === 'numerical_input') {
      const max = _.maxBy(data, 'answer').answer + stepValue;
      let min = _.minBy(data, 'answer').answer - stepValue;
      min = min < 0 ? 0 : min;
      yValues = [min, max];
      yLabels = [...yValues];
      return { yValues, yLabels };
    }
    // for scale and picker question types
    // convert answer keys to their numerical values through index positions
    yValues = answers.map((_value, index) => index);

    yLabels = answers.map(({ chartLabel }) => formatChartLabel(chartLabel));

    return { yValues, yLabels };
  };

  // long labels will get cut off, so shorten and break up the text as much as possible
  // https://formidable.com/open-source/victory/docs/faq/#my-axis-labels-are-cut-off-how-can-i-fix-them
  const formatChartLabel = (string) => string
    .replace(' degrees', '°')
    .trim()
    .split(' ')
    .join('\n');

  const { yValues, yLabels } = dependentAxis();

  const answerLabel = (datum) => {
    if (datum.skipped) {
      return t('common.text.no_entry');
    }

    let date = moment(datum.date).format('LL');

    if (mode === 'day') {
      date = moment(datum.date).format('ha');
    }

    if (question.type === 'numerical_input') {
      return `${datum.answer} ${question.unit}\n ${date}`;
    }

    return `${yLabels[datum.answer]} ${question.unit}\n ${date}`;
  };

  const independentAxis = useMemo(() => {
    const defaultProps = {
      tickValues: null,
      tickFormat: null,
    };

    switch (mode) {
      case 'day': {
        const startOfDay = moment(data[0].date).startOf('day');
        const tickValues = [startOfDay];

        for (let i = 0; i <= 2; i += 1) {
          const hour = moment(tickValues[i]).add(6, 'hours');
          tickValues.push(new Date(hour));
        }

        return {
          tickValues,
          tickFormat: (datum, i) => {
            if (i === 0) return `${moment(datum).format('MMM D')}`;
            return `${moment(datum).format('ha')}`;
          },
        };
      }

      case 'week': {
        const startOfWeek = moment(data[0].date).startOf('isoWeek');
        const tickValues = [];

        for (let i = 0; i <= 6; i += 1) {
          const dayOfWeek = moment(startOfWeek).add(i, 'days');
          tickValues.push(new Date(dayOfWeek));
        }

        return {
          tickValues,
          tickFormat: (datum) => `${moment(datum).format('ddd')}`,
        };
      }

      case 'all':
      default:
        return defaultProps;
    }
  }, [data, mode]);

  const { tickFormat, tickValues } = independentAxis;

  return (
    <div>
      <VictoryChart
        data={data}
        x="date"
        y="answer"
        scale={{ x: discontinuousScale }}
        minDomain={{ y: _.first(yValues) }}
        maxDomain={{ y: _.last(yValues) }}
        containerComponent={(
          <VictoryVoronoiContainer
            role="presentation"
            voronoiBlacklist={['area']}
            labels={({ datum }) => answerLabel(datum)}
          />
        )}
      >
        {!_.isEmpty(tickValues) ? (
          <VictoryAxis
            fixLabelOverlap
            tickValues={tickValues}
            tickFormat={tickFormat}
            style={{
              axis: { stroke: '#a0aec0' },
              tickLabels: { fill: '#a0aec0' },
            }}
          />
        ) : (
          <VictoryAxis
            fixLabelOverlap
            tickFormat={tickFormat}
            style={{
              axis: { stroke: '#a0aec0' },
              tickLabels: { fill: '#a0aec0' },
            }}
          />
        )}
        <VictoryAxis
          dependentAxis
          invertAxis={question.reverseY}
          tickValues={yValues}
          tickFormat={yLabels}
          style={{
            axis: { stroke: '#a0aec0' },
            axisLabel: { fontSize: '20px' },
            grid: { stroke: '#a0aec0' },
            tickLabels: { fill: '#a0aec0' },
          }}
        />
        <VictoryScatter
          data={data}
          dataComponent={(
            <Point
              tabIndex={0}
              ariaLabel={
                ({ datum }) => (
                  datum.skipped
                    ? `${datum.date}, ${t('linechart.label.blank')}}`
                    : `${datum.date}, ${datum.answer}`
                )
              }
            />
          )}
          x="date"
          y="answer"
          size={scatterDotSize}
          style={{
            data: {
              fill: '#044F89',
              fillOpacity: 0.1,
              stroke: '#044F89',
              strokeWidth: 2,
            },
          }}
        />
        {areaData.length > 1 && (
          <VictoryArea
            scale="time"
            style={{
              data: {
                stroke: '#044F89',
                strokeWidth: 3,
                fill: 'url(#gradient)',
              },
            }}
            x="date"
            y="answer"
            data={areaData}
            name="area"
            y0={() => (question.reverseY ? _.last(yValues) : _.first(yValues))}
          />
        )}
      </VictoryChart>
    </div>
  );
}
