import React from 'react';
import tw, { css, styled } from 'twin.macro';

interface MsgListProps {
  children: JSX.Element,
  onNewThreadClick: () => void;
  overlap: boolean;
}

export function ThreadList({
  children,
  onNewThreadClick: handleNewThreadClick,
  overlap,
}: MsgListProps) {
  return (
    <ParentContainer overlap={overlap} role="navigation">
      <Container overlap={overlap}>
        {/*
          <div className="px-4">
            <button
              className="btn-primary w-full"
              onClick={handleNewThreadClick}
            >
              Start a conversation
            </button>
          </div>
        */}
        <Ul>
          {children}
        </Ul>
      </Container>
    </ParentContainer>
  );
}

const ParentContainer = styled.div(({ overlap }: MsgListProps) => [
  tw`flex-col h-screen float-left fixed w-full`,
  overlap && tw`z-10`,
  css`
    margin-top: 3.875rem;
    max-height: -webkit-fill-available;
  `,
]);

const Container = styled.div(({ overlap }: MsgListProps) => [
  tw`bg-white flex-col h-screen justify-start overflow-y-scroll`,
  tw`border-gray-400 border-r`,
  overlap ? tw`w-full` : tw`w-0`,
  tw`md:w-sidedrawer md:min-w-sidedrawer`,
  css`
    transition: 0.3s;
    max-height: -webkit-fill-available;
  `,
]);

const Ul = styled.ul(() => [
  tw`pt-4 pb-24`,
]);
