import React from 'react';
import { useTranslation } from 'react-i18next';
import tw, { styled } from 'twin.macro';
import moment from 'moment';
import Panel from '../../layout/Panel';
import usePatientDates from '../../../patient-app-common/hooks/usePatientDates';
import { useUnlinkAccountMutation } from '../../../patient-app-common/api/patientApi';

export default function Profile({ patient }) {
  const { t } = useTranslation();
  const [unlinkAccount] = useUnlinkAccountMutation();

  const {
    clinicalContact,
    email,
    firstName,
    integration,
    lastName,
    maternityProgram,
    nonSurgicalProgram,
    phone,
    procedureName,
    surgicalDetails,
    timezone,
  } = patient;

  const { patientDates } = usePatientDates();

  const emailStr = email || t('common.text.not_provided');
  const phoneStr = phone || t('common.text.not_provided');

  const location = surgicalDetails?.surgerySiteLocation;

  const zone = moment.tz(timezone).zoneAbbr();

  const signupDateStr = `${moment(patientDates?.signupDate).format('LL')}`;

  const preOpDateStr = `${moment(patientDates?.preOpDate).format('LL')}`;

  let surgeryDateStr = `${moment(patientDates?.surgeryDate).format('LL')} (${zone})`;

  if (surgicalDetails?.surgeryTime && surgicalDetails?.enrollmentType === 'auto_enrolled') {
    surgeryDateStr = `${moment(patientDates?.surgeryDate).format('LL')} ${surgicalDetails.surgeryTime}`;
  }

  let clinicianName = '';
  if (clinicalContact) {
    clinicianName = `${clinicalContact.firstName} ${clinicalContact.lastName}`;
  }

  const dischargeDateStr = `${moment(patientDates?.dischargeDate).format('LL')} (${zone})`;

  let procedureDateLabel = t('common.text.procedure_date', { procedureName });
  let provider = t('common.health_provider.doctor');
  if (maternityProgram) {
    procedureDateLabel = 'Due date';
    provider = 'My provider';
  }

  const handleOTAUnlink = async () => {
    await unlinkAccount({ integrationType: 'ontario_trusted_account' });
  };

  return (
    <Panel
      initToggle
      title={t('common.link.profile')}
    >

      <Table>
        <tbody>
          <TR>
            <TH scope="row">{t('common.text.name')}</TH>
            <TD>{firstName ? `${firstName} ${lastName}` : t('profile.blankcontact')}</TD>
          </TR>
          <TR>
            <TH scope="row">{t('common.text.email')}</TH>
            <TD>{emailStr}</TD>
          </TR>
          <TR>
            <TH scope="row">{t('common.text.phone')}</TH>
            <TD>{phoneStr}</TD>
          </TR>
          {clinicianName && (
            <TR>
              <TH scope="row">{provider}</TH>
              <TD>{clinicianName}</TD>
            </TR>
          )}

          {integration?.ontarioTrustedAccount && (
            <TR>
              <TH scope="row">Ontario trusted account linked</TH>
              <TD>
                Yes
                <Btn onClick={handleOTAUnlink}>Request unlink</Btn>
              </TD>
            </TR>
          )}

          {nonSurgicalProgram && (
            <TR>
              <TH scope="row">{t('common.text.program_start_date')}</TH>
              <TD>{signupDateStr}</TD>
            </TR>
          )}

          {patientDates?.surgeryDate && (
            <TR>
              <TH scope="row">{procedureDateLabel}</TH>
              <TD>{surgeryDateStr}</TD>
            </TR>
          )}

          {patientDates?.dischargeDate && (
            <TR>
              <TH scope="row">{t('common.text.discharge_date')}</TH>
              <TD>{dischargeDateStr}</TD>
            </TR>
          )}

          {patientDates?.preOpDate && (
            <TR>
              <TH scope="row">{t('common.text.preop_visit_date')}</TH>
              <TD>{preOpDateStr}</TD>
            </TR>
          )}

          {location && (
            <TR>
              <TH scope="row">Location</TH>
              <TD>{location}</TD>
            </TR>
          )}
        </tbody>
      </Table>
    </Panel>
  );
}

const Table = styled.table(() => [
  tw`border-none text-left w-full`,
]);

const TH = styled.th(() => [
  tw`border-none text-left w-1/2`,
]);

const TR = styled.tr(() => [
  tw`odd:bg-blue-10`,
]);

const TD = styled.td(() => [
  tw`border-none w-1/2`,
]);

const Btn = styled.button(() => [
  tw`bg-white border border-black rounded text-xs`,
  tw`ml-4 px-2`,
]);
