import React from 'react';
import { useNavigate } from 'react-router-dom';
import { createOpioidParams, getOpioidRisk, getAvgRisk } from '../../../services/OpioidRisk';
import { reviewHelperContext } from '../../../patient-app-common/helpers/survey_helper';
import SurveyContainer from '../layout/SurveyLayout';
import Loader from '../../layout/Loader';
import { usePostTrackerMutation } from '../../../patient-app-common/api/trackerApi';
import { useUpdateProfileMutation } from '../../../patient-app-common/api/patientApi';
import { useAppSelector } from '../../../redux/hooks';

const OpioidReview = ({
  answers,
  backHandler,
  eventId,
  procedureName,
  profile,
  questions,
  surveyData,
}) => {
  const navigate = useNavigate();

  const [submitTracker] = usePostTrackerMutation();
  const [updateProfile] = useUpdateProfileMutation();

  const { token } = useAppSelector(({ auth }) => auth);

  const { education } = surveyData;

  const [loader, showLoader] = React.useState(false);
  const [reviewHelper, setReviewHelper] = React.useState(null);

  React.useEffect(() => {
    const helper = reviewHelperContext({ surveyData, answers, profile });
    setReviewHelper(helper);
    
  }, [answers, profile]);

  const createOpioidResult = () => {
    const opioidParams = createOpioidParams(procedureName, questions, answers);
    const result = getOpioidRisk(opioidParams);
    const average = getAvgRisk(opioidParams);

    return { result, average };
  };

  const handleSubmitClick = async () => {
    showLoader(true);

    const profileParams = reviewHelper.createProfileParams();
    const params = reviewHelper.createSurveyParams('app_event', eventId);
    params.token = token;

    const { result, average } = createOpioidResult();

    try {
      const res = await submitTracker(params).unwrap();

      if (res.submitted) {
        await updateProfile(profileParams).unwrap();
        showLoader(false);
      }
    } catch (e) {
      showLoader(false);
    }

    navigate('/opioidrisk', {
      state: {
        result,
        average,
        education,
      },
    });
  };

  return (
    <div className="flex flex-col h-screen">
      <Loader
        visible={loader}
        text="Sending your response to your healthcare team."
      />

      <SurveyContainer
        backHandler={() => backHandler()}
        exitHandler={() => navigate('/')}
        nextHandler={() => handleSubmitClick()}
        progress={100}
        showBack
        subtitle="Please press submit to send your answers"
        surveyName={surveyData.patientDisplayName}
        review
        title="Thanks for answering all our questions!"
      />
    </div>
  );
};

export default OpioidReview;
