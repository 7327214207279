import React from 'react';
import { useTranslation } from 'react-i18next';

export default function AnswerAlert({ handleCloseClick }) {
  const { t } = useTranslation('survey');

  return (
    <div>
      <div className="py-4">
        <p className="font-bold pb-2">{t('surveylayout.answer_dialog.title')}</p>
        <p>{t('surveylayout.answer_dialog.description')}</p>
      </div>
      <div className="flex justify-center">
        <button
          onClick={() => handleCloseClick()}
          className="btn-primary w-3/4 mx-1"
        >
          {t('common.action.ok')}
        </button>
      </div>
    </div>
  );
}
