import _ from 'lodash';
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import tw, { styled } from "twin.macro";

export default function NumInput({ sendAnswer, answerHistory, options }) {
  const { t } = useTranslation('survey');
  const [error, setError] = React.useState("");

  const validateNumber = useCallback((value) => {
    setError('');

    const { min, max } = options;
    const minNumber = _.toNumber(min);
    const maxNumber = _.toNumber(max);

    if (_.isNaN(_.toNumber(value))) {
      setError(t('numinput.error.invalid'));
      return false;
    }

    if (minNumber && value < minNumber) {
      setError(t('numinput.error.min', { value: minNumber }));
      return false;
    }
    if (maxNumber && value > maxNumber) {
      setError(t('numinput.error.max', { value: maxNumber }));
      return false;
    }

    return true;
  }, [options]);

  const handleInput = (event) => {
    const answer = event.target.value;
    const valid = validateNumber(answer);

    if (!valid) {
      sendAnswer(null);
    } else {
      setError("");
      sendAnswer(answer);
    }
  };

  return (
    <div>
      <Container>
        <Input
          id="num"
          type="number"
          onInput={(e) => handleInput(e)}
          defaultValue={answerHistory}
        />
        <Label htmlFor="num">{options.unit}</Label>
      </Container>
      <Container>
        <ErrorText>{error}</ErrorText>
      </Container>
      {options.image !== "" && (
        <Container>
          <Img src={options.image} alt={options.alt_text} />
        </Container>
      )}
    </div>
  );
}

const Container = styled.div(() => [
  tw`w-full md:w-1/2`,
]);

const Input = styled.input(() => [
  tw`border border-gray-500 p-2 rounded shadow`,
  tw`text-lg sm:text-2xl`,
  tw`w-3/4`,
]);

const Label = styled.label(() => [
  tw`p-2 w-1/4`,
  tw`text-lg sm:text-2xl`,
]);

const ErrorText = styled.p(() => [
  tw`flex flex-initial pt-2`,
  tw`text-sm italic text-red`,
  tw`w-full md:w-1/2`,
]);

const Img = styled.img(() => [
  tw`mx-auto object-contain py-4`,
]);
