import React, { useMemo } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import useSurveyDrafts from '../../../hooks/useSurveyDrafts';
import Card from '../layout/Card';

export default function SurveyEvent({
  avatar,
  event,
  index,
  size,
}) {
  const { t } = useTranslation('home');
  const { surveys, surveyDrafts } = useSurveyDrafts();
  const surveyId = _.toNumber(event.options.id);

  const hasDraft = useMemo(() => {
    if (!surveyDrafts?.length) return false;
    return _.find(surveyDrafts, {
      applicationEventId: event.id,
      procedureId: surveyId,
    });
  }, [surveyId, surveyDrafts]);

  let btnText = event.options.linkText ?? t('common.button.survey_start');
  btnText = hasDraft ? t('common.button.survey_edit') : btnText;

  const submittedDate = useMemo(() => {
    if (!event.shouldPersistAfterReading) return '';

    const lastEntryDate = _.find(surveys, ['id', surveyId])?.lastEntryDate;

    if (lastEntryDate) {
      return moment(lastEntryDate).fromNow();
    }

    return '';
  }, [surveys]);

  return (
    <article
      aria-labelledby={`title-${index}`}
      aria-describedby={`body-${index}`}
      aria-posinset={index + 1}
      aria-setsize={size}
      className="py-4"
    >
      <Card
        date={moment(event.startDateTime).format('LL')}
        icon={avatar}
        headerComp={(
          <h3 className="font-bold" id={`title-${index}`}>
            {event.title}
          </h3>
        )}
        bodyComp={(
          <>
            <p id={`body-${index}`} className="whitespace-pre-line">{event.body}</p>
            <Link
              className="block btn-primary w-3/4 mt-4 text-center mx-auto"
              to={`/tracker/${event.options.id}`}
              state={{ eventId: event.id }}
            >
              {btnText}
            </Link>
            {submittedDate !== '' && (
              <div className="bg-blue-10 mt-4">
                <p className="inline-flex whitespace-pre-line">
                  <span className="place-self-center text-xl px-2">
                    <AiOutlineExclamationCircle />
                  </span>
                  {t('common.text.last_completed_date', { date: submittedDate })}
                </p>
              </div>
            )}
            {hasDraft && (
              <div className="bg-blue-10 mt-4">
                <p className="inline-flex whitespace-pre-line">
                  <span className="place-self-center text-xl px-2">
                    <AiOutlineExclamationCircle />
                  </span>
                  {t('common.text.has_draft')}
                </p>
              </div>
            )}
          </>
        )}
      />
    </article>
  );
}
