import React, {
  ChangeEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import _ from 'lodash';
import AccountForm from './AccountForm';
import FormContainer from './layout/FormContainer';
import LoaderInline from '../layout/LoaderInline';
import useSegment from '../../hooks/useSegment';
import { useAccountFormMutation } from '../../patient-app-common/api/authApi';
import { useAppSelector } from '../../redux/hooks';
import { FormField } from '../../patient-app-common/types/Auth';
import { getURLParam } from '../../utils/utils';

const EVENT = 'Signup Start button clicked';

export default function Setup() {
  const location = useLocation();
  const { sendTrackEvent } = useSegment();
  const { accessKey, setupToken } = useParams<{ accessKey: string, setupToken: string }>();
  const { route } = useAppSelector(({ app }) => app);
  const { i18n, t } = useTranslation('login');

  const errorDiv = useRef<null | HTMLDivElement>(null);

  const [error, setError] = useState(false);
  const [form, setForm] = useState<FormField[]>([]);
  const [languageField, setLanguageField] = useState<null | FormField>(null);
  const [showForm, setShowForm] = useState(false);

  const [accountForm, { isLoading }] = useAccountFormMutation();

  const getForm = async () => {
    try {
      const results = await accountForm({ token: accessKey }).unwrap();
      setForm(results);

      const languages = _.find(results, ['fieldCode', 'language_id']);
      if (languages) {
        setLanguageField(preselectLanguageField(languages));
      }
    } catch (e) {
      setError(true);
      errorDiv.current && errorDiv.current.focus();
    }
  };

  const preselectLanguageField = (languages: FormField) => {
    const initLocale = getURLParam(location.search, 'lang');
    if (!initLocale) return languages;

    const preselectedLang = languages.options.find((lang) => lang.includes(initLocale));
    if (!preselectedLang) return languages;

    const langId = preselectedLang[1];

    return { ...languages, value: langId };
  };

  useEffect(() => {
    if (route !== null) {
      getForm();
    }
  }, [route]);

  useEffect(() => {
    const initLocale = getURLParam(location.search, 'lang') || 'en';

    moment.locale(initLocale);
    i18n.changeLanguage(initLocale);
    document.documentElement.lang = initLocale;
  }, []);

  const handleLocaleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (!languageField) return;
    const languageFieldCopy = { ...languageField };
    languageFieldCopy.value = e.target.value;
    setLanguageField(languageFieldCopy);

    const locale = e.target[e.target.selectedIndex]
      .getAttribute('data-locale');

    if (!locale) return;

    moment.locale(locale);
    i18n.changeLanguage(locale);
    document.documentElement.lang = locale;
  };

  const formWithSelectedLanguage = () => {
    if (!languageField) return;
    const formCopy: FormField[] = [...form];

    _.remove(formCopy, (field) => field.languageId
      && field.languageId !== Number(languageField.value));
    _.remove(formCopy, (field) => field.fieldCode === 'language_id');

    const languageFieldCopy = { ...languageField };
    languageFieldCopy.visible = false;

    formCopy.push(languageFieldCopy);
    setForm(formCopy);
  };

  const handleSetupBtnClick = () => {
    if (languageField) {
      formWithSelectedLanguage();
    }

    setShowForm(true);
  };

  if (showForm) {
    return (
      <div className="bg-gray-100 h-screen">
        <div className="text-left p-6">
          <AccountForm
            fields={form}
            token={setupToken}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="bg-gray-100 h-screen">
      <FormContainer>
        <h1 className="text-3xl">
          {t('setup.title')}
        </h1>

        <div className="p-6">
          <p className="text-left">
            {t('setup.description')}
          </p>
        </div>

        {languageField && (
          <div className="p-4">
            <h2 className="pb-2 text-lg">{t('common.select.language')}</h2>
            <select
              name="lng"
              id="lng"
              defaultValue={languageField.value}
              onChange={(e) => handleLocaleChange(e)}
              className="form-select w-3/4"
            >
              {languageField.options.map((lng) => (
                <option
                  data-locale={lng[2]}
                  key={lng[1]}
                  value={lng[1]}
                >
                  {lng[0]}
                </option>
              ))}
            </select>
          </div>
        )}

        <div className="py-4">
          <button
            disabled={isLoading || error}
            onClick={() => {
              handleSetupBtnClick();
              sendTrackEvent(EVENT);
            }}
            className="btn-primary w-1/2 disabled:bg-gray-500"
          >
            {t('common.action.start')}
          </button>

          {isLoading && (
            <div className="text-center">
              <LoaderInline />
            </div>
          )}

          {error && (
            <div ref={errorDiv} role="alert" tabIndex={0}>
              <p className="error-text p-2 text-xs md:text-sm">
                {t('common.error.server')}
              </p>
            </div>
          )}
        </div>
      </FormContainer>
    </div>
  );
}
