import { isIE, browserVersion } from 'react-device-detect';
import { configureStore, PreloadedState } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { v4 as uuidv4 } from 'uuid';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { encryptTransform } from 'redux-persist-transform-encrypt';
// If using Redux-Persist, you should specifically ignore all the action types it dispatches:
// https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { coreReducer, CoreReducer } from './reducers';
import { baseApi } from '../patient-app-common/api/baseApi';
import { b as legacyCompatibleGenerateUuidv4 } from '../utils/utils';

function secretKey() {
  if (sessionStorage.getItem('md-key') == null) {
    let newKey;

    if (isIE && Number(browserVersion) <= 10) {
      newKey = legacyCompatibleGenerateUuidv4();
    } else {
      newKey = uuidv4();
    }

    sessionStorage.setItem('md-key', newKey);
    return newKey;
  }
  return sessionStorage.getItem('md-key');
}

// encryption for redux persisted data
const persistConfig = {
  key: 'root-v1',
  storage,
  transforms: [
    encryptTransform({
      secretKey: secretKey(),
      onError(error) {
        // Handle the error.
      },
    }),
  ],
  stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer<CoreReducer>(persistConfig, coreReducer);

export function setupStore(preloadedState: PreloadedState<CoreReducer> = {}) {
  return configureStore({
    reducer: persistedReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      // Adding the api middleware enables caching, invalidation, polling,
      // and other useful features of `rtk-query`.
      .concat(baseApi.middleware),
  });
}

export const store = setupStore();
export const persistor = persistStore(store);

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
