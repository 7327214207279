import React, { useEffect, useMemo } from 'react';
import * as Sentry from '@sentry/react';
import './styles/tailwind.css';
import './styles/customscroll.scss';
import { useDispatch, useSelector } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import 'focus-visible';
import { styled } from 'twin.macro';
import { ActionCableProvider } from './context/ActionCableContext';
import { setAppVariant, setRoute, setTestEnv } from './patient-app-common/slices/appSlice';
import IdleTimer from './IdleTimer';
import { DOMAINS } from './constants/domains';
import AppRouter from './AppRouter';
import withClearCache from './ClearCache';
import { Crash, NoMatch } from './components/layout';
import useSegment from './hooks/useSegment';
import useLocale from './hooks/useLocale';


function App() {
  const { hostname } = window.location;

  const { patientId } = useSelector(({ auth }) => auth);

  const dispatch = useDispatch();
  useLocale({ skip: !patientId });
  const { trackUser } = useSegment();

  useEffect(() => {
    if (patientId) {
      Sentry.setUser({ patientId });
      trackUser(patientId);
    }
  }, [patientId]);

  useEffect(() => {
    if (hostname.includes('holland') || hostname.includes('sunnybrook')) {
      dispatch(setAppVariant('sunnybrook'));
    }

    if (hostname.includes('azurestaticapps')) {
      dispatch(setTestEnv(true));
    }
  }, [hostname]);

  const domain = useMemo(() => {
    let validURL = false;
    let route;

    if (DOMAINS.CA.includes(hostname) || DOMAINS.LOCAL.includes(hostname)) {
      route = process.env.REACT_APP_API_CA;
      validURL = true;
    }

    if (DOMAINS.LOCAL.includes(hostname)) {
      route = process.env.REACT_APP_API_LOCAL;
      validURL = true;
    }

    if (DOMAINS.SANDBOX.includes(hostname)) {
      route = process.env.REACT_APP_API_SANDBOX;
      validURL = true;
    }

    if (DOMAINS.US.includes(hostname)) {
      route = process.env.REACT_APP_API_US;
      validURL = true;
    }

    if (hostname.includes('azurestaticapps')) {
      validURL = true;
    }

    return { route, validURL };
  }, [hostname]);

  useEffect(() => {
    if (domain.route !== undefined) {
      dispatch(setRoute(domain.route));
    }
  }, [domain]);

  if (!domain.validURL) {
    return <NoMatch />;
  }

  return (
    <Sentry.ErrorBoundary fallback={<Crash />}>
      <ActionCableProvider>
        <FocusVisible className="js-focus-visible focus-visible">
          <IdleTimer />
          <HashRouter>
            <AppRouter />
          </HashRouter>
        </FocusVisible>
      </ActionCableProvider>
    </Sentry.ErrorBoundary>
  );
}

export default withClearCache(App);

const FocusVisible = styled.div`
  &.js-focus-visible :focus:not(.focus-visible) {
     outline: none;
  }
  &.js-focus-visible .focus-visible {
     outline: none;
     border: 3px solid #528deb;
  }
`;
