import React, { useEffect, useMemo, useState } from 'react';
import * as Sentry from '@sentry/react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import Survey from './Survey';
import SurveyLoader from './layout/SurveyLoader';
import SurveyRecord from './layout/SurveyRecord'
import useSurveyDrafts from '../../hooks/useSurveyDrafts';
import { NoMatch } from '../layout';
import { useAppSelector } from '../../redux/hooks';
import usePatientDates from '../../patient-app-common/hooks/usePatientDates';
import { useGetPatientQuery } from '../../patient-app-common/api/patientApi';
import useAppEvents from '../../patient-app-common/hooks/useAppEvents';
import useUnloadAlert from '../../hooks/useUnloadAlert';
import { useGetSurveyQuery } from '../../patient-app-common/api/surveyApi';

export default function SurveyProvider() {
  useUnloadAlert();
  const { surveyId } = useParams();
  const { state } = useLocation();
  const eventId = _.toNumber(state?.eventId) || null;

  const { providerMode } = useAppSelector(({ auth }) => (auth));

  const { isLoading: isLoadingPatient, data: patient } = useGetPatientQuery();
  const { unread } = useAppEvents({ skip: !eventId });
  const { patientDates } = usePatientDates();

  const id = surveyId === 'daily' ? patient?.mainTrackerId : surveyId;

  const { isLoading: isLoadingSurvey, data, error } = useGetSurveyQuery(id, { skip: !patient || !surveyId });
  const { expireDraft, parseDraftData } = useSurveyDrafts();

  const isLoading = isLoadingSurvey || isLoadingPatient;

  const [loader, setLoader] = useState(true);
  const [draft, setDraft] = useState(null);

  useEffect(() => {
    setLoader(isLoading);
  }, [isLoading]);

  useEffect(() => {
    // the timer is just for a smoother ux transition
    if (isLoading) return;
    const timeout = setTimeout(() => {
      setLoader(false);
    }, 3000);

    return () => clearTimeout(timeout);
  }, [isLoading]);

  const surveyData = useMemo(() => {
    if (data?.id) return data;
    return {};
  }, [data]);

  const opioidSurvey = useMemo(() => {
    if (
      surveyData?.postprocessors
      && surveyData.postprocessors.includes('AtriumOpioidRiskModelPostprocessor')
    ) {
      return true;
    }

    return false;
  }, [surveyData]);

  const [surveyDone, setSurveyDone] = useState(false);

  const hasEntry = useMemo(() => {
    if (!surveyData) return false;

    const oneTimeSurvey = surveyData.editable || surveyData.setupSurvey || surveyData.mainSurvey;
    const lastEntryDate = surveyData?.lastEntryDate;

    if (!lastEntryDate) return false;

    const secondsSinceLastEntry = moment().diff(lastEntryDate, 'seconds');

    if (secondsSinceLastEntry <= 60) {
      // prevents the survey complete page from loading during the submission process
      return false;
    }

    if (oneTimeSurvey) return true;

    if (surveyData.mainTracker) {
      return moment(lastEntryDate).isSame(moment(), 'day');
    }

    if (eventId && unread?.length) {
      const unreadEventIds = _.map(unread, 'id');
      return !unreadEventIds.includes(eventId);
    }

    return false;
  }, [eventId, unread, surveyData]);

  useEffect(() => {
    if (!surveyData) return;
    setSurveyDone(hasEntry);
  }, [hasEntry, surveyData]);

  useEffect(() => {
    if (_.isEmpty(surveyData)) return;

    Sentry.setContext('Params', {
      procedure_id: surveyData.id,
    });
  }, [eventId, surveyData]);

  useEffect(() => {
    if (_.isEmpty(surveyData?.draft) || !patient || !patientDates) return;
    if (draft?.draftCreatedAt === surveyData.draft.draftCreatedAt) return;

    const { profile } = patient;
    let timeout;

    const setDraftState = async () => {
      const expired = expireDraft(surveyData, unread);

      if (!expired) {
        setLoader(true);
        const draftData = await parseDraftData(surveyData, patientDates, profile);
        setDraft(draftData);
      }

      // the timer is just for a smoother ux transition
      timeout = setTimeout(() => setLoader(null), 1000);
    };

    setDraftState();

    return () => clearTimeout(timeout);
  }, [surveyData, unread, patient, patientDates]);

  if (!surveyId) {
    return (
      <Navigate to="/" replace />
    );
  }

  if (loader || _.isEqual(surveyData, {})) {
    return (
      <SurveyLoader />
    );
  }

  if (error?.status === 404 || !patient) {
    return (<NoMatch />);
  }

  if (surveyDone) {
    return (
      <SurveyRecord
        editable={surveyData.editable}
        handleCancelClick={() => setSurveyDone(false)}
        lastEntryDate={surveyData.lastEntryDate}
      />
    );
  }

  return (
    <>
      {providerMode && (
        <div className="lg:px-16 px-6 py-2 bg-teal-70">
          <p className="text-lg sm:text-xl">
            Reminder: you are filling out this survey on behalf of {patient.firstName} {patient.lastName}
          </p>
        </div>
      )}
      <Survey
        draft={draft}
        eventId={eventId}
        opioidSurvey={opioidSurvey}
        patient={patient}
        patientDates={patientDates}
        surveyData={surveyData}
      />
    </>
  );
}
