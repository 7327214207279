import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import tw, { css, styled } from 'twin.macro';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { Patient } from '../../../../patient-app-common/types/Patient';
import { PatientDates } from '../../../../patient-app-common/hooks/usePatientDates';
import useSurveyDrafts from '../../../../hooks/useSurveyDrafts';
import DailyResult from './DailyResult';

interface HealthCheckCardProps {
  patient: Patient;
  patientDates: PatientDates | null;
}

export default function ({
  patient,
  patientDates,
} : HealthCheckCardProps) {
  const entryFromToday = useMemo(() => {
    if (!patient) return false;

    const { mainTrackerId, surveys } = patient;

    const lastEntryDate = _.find(surveys, ['id', mainTrackerId])?.lastEntryDate;

    if (lastEntryDate) {
      return moment().isSame(lastEntryDate, 'd');
    }
    return false;
  }, [patient]);

  const hasHealthCheck = useMemo(() => {
    if (!patient || !patientDates) return false;

    const currentDate = new Date();
    const { daysWithCheckIn, daysPostOp } = patient;
    const { dischargeDate } = patientDates;

    const daysToSurvey = daysWithCheckIn ?? daysPostOp;
    const programEndDate = moment(dischargeDate)
      .add(daysToSurvey, 'days');

    const withinDaysToSurvey = daysToSurvey > 0
    && moment(currentDate)
      .startOf('day')
      .isBefore(programEndDate);

    return withinDaysToSurvey;
  }, [patient, patientDates]);

  if (!patient) return null;

  if (entryFromToday) {
    return (
      <DailyResult mainTrackerId={patient.mainTrackerId} />
    );
  }

  if (hasHealthCheck) {
    return <HealthCheckCard mainTrackerId={patient.mainTrackerId} />;
  }

  return null;
}

function HealthCheckCard({ mainTrackerId }: { mainTrackerId: number }) {
  const { t } = useTranslation('home');
  const { surveyDrafts } = useSurveyDrafts();

  const hasDraft = useMemo(() => {
    if (!surveyDrafts?.length) return false;

    const draft = _.find(surveyDrafts, {
      procedureId: mainTrackerId,
    });
    const draftDate = draft?.draftCreatedAt;

    if (!draftDate) return false;

    const draftFromToday = moment()
      .startOf('day')
      .isSame(moment(draftDate).startOf('day'));

    return draftFromToday;
  }, [mainTrackerId, surveyDrafts]);

  const btnText = hasDraft ? t('common.button.survey_edit') : t('common.button.survey_start');

  return (
    <div
      aria-labelledby="healthcheck-title"
      aria-describedby="healthcheck-body"
      className="py-4"
      role="dialog"
    >
      <div className="bg-blue-10 border-gray-500 p-8 rounded-lg shadow-md">
        <div className="flex justify-center">
          <h2
            className="font-bold text-lg"
            id="healthcheck-title"
          >
            {t('common.text.main_tracker')}
          </h2>
        </div>
        <div className="flex justify-center py-4">
          <p id="healthcheck-body">{t('healthcheckcard.description')}</p>
        </div>
        <StyledLink
          to={`/tracker/${mainTrackerId}`}
        >
          {btnText}
        </StyledLink>
        {hasDraft && (
          <div className="mt-4 text-center">
            <p className="inline-flex">
              <span className="place-self-center text-xl px-2">
                <AiOutlineExclamationCircle />
              </span>
              <span>{t('common.text.has_draft')}</span>
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

const StyledLink = styled(Link)(() => [
  tw`block w-3/4 mt-4 text-center mx-auto`,
  tw`font-bold text-white py-2 px-4 rounded`,
  css`background: linear-gradient(
    20deg,
    hsl(206, 94%, 28%),
    hsl(191, 100%, 43%)
  );`,
]);
