import React, { useMemo } from 'react';
import moment from 'moment';
import tw, { styled } from 'twin.macro';
import { FiRepeat } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import useModal from '../../hooks/useModal';
import { AppEvent } from '../../patient-app-common/types/ApplicationEvent';
import EduModal from '../education/EduModal';

interface TodoEventProps {
  event: AppEvent;
  handleInputChange: () => void;
}

export default function TodoEvent({ event, handleInputChange }: TodoEventProps) {
  const { visible, toggleModal } = useModal();
  const { t } = useTranslation();

  // appends the word "Today" to the repeat event subtitle
  const formatSubtitle = (initText: string) => {
    const today = t('common.text.today');
    let formattedText = today;
    if (initText.length > 0) {
      const subtitle = initText.charAt(0).toLowerCase() + initText.slice(1);
      formattedText = `${today}, ${subtitle}`;
    }
    return formattedText;
  };

  const eventSubtitle = useMemo(() => {
    if (!event.repeating || !event.subtitle) return event.subtitle;
    return formatSubtitle(event.subtitle);
  }, [event]);

  const handleEduClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    toggleModal();
    e.stopPropagation();
  };

  const date = moment(event.completedDate).format('LLLL');

  return (
    <>
      <EduModal
        event={event}
        source="TodoEvent"
        toggleModal={toggleModal}
        visible={visible}
      />
      <Container
        onClick={() => handleInputChange()}
        role="listitem"
      >
        <div className="px-4">
          <Input
            data-test="read-checkbox"
            aria-label={event.title}
            checked={event.done}
            name={event.id}
            type="checkbox"
            role="checkbox"
            onChange={() => handleInputChange()}
          />
        </div>
        <div className="w-9/12">
          <Title htmlFor={event.id} done={event.done} id={event.id}>
            {event.title}
          </Title>

          {!event.done && <p>{eventSubtitle}</p>}

          {event.done && event.completedDate && (
            <p>
              {t('common.text.completed_date', { date })}
            </p>
          )}

          <div className="py-4">
            {event.edIds
            && event.edIds.length > 0
            && event.options.linkText && (
              <EduBtn
                onClick={
                  (e: React.MouseEvent<HTMLButtonElement>) => handleEduClick(e)
                }
              >
                {event.options.linkText}
              </EduBtn>
            )}
          </div>
        </div>

        <div className="w-1/12">
          <span className="text-xl text-green-100">
            {event.repeating && <FiRepeat />}
          </span>
        </div>
      </Container>
    </>
  );
}

const Container = styled.div(() => [
  tw`bg-white border-b border-gray-300 cursor-pointer flex p-4`,
  tw`hover:bg-teal-30`,
]);

const Input = styled.input(() => [
  tw`form-checkbox h-6 pointer-events-none text-blue-100 w-6`,
  tw`border-2 border-black`,
  tw`h-4 w-4`,
]);

const Title = styled.label(({ done }: { done: boolean }) => [
  tw`font-bold`,
  tw`text-sm sm:text-lg`,
  done && tw`line-through text-gray-700`,
]);

const EduBtn = styled.button(() => [
  tw`bg-blue-10 text-blue-100 py-2 px-4 rounded-full`,
  tw`text-sm sm:text-base`,
]);
