import { combineReducers } from 'redux';
import { AnyAction } from '@reduxjs/toolkit';
import { RootStateOrAny } from 'react-redux';
import { baseApi } from '../../patient-app-common/api/baseApi';
import authSlice from '../../patient-app-common/slices/authSlice';
import appSlice from '../../patient-app-common/slices/appSlice';

const combinedReducers = combineReducers({
  app: appSlice,
  auth: authSlice,
  [baseApi.reducerPath]: baseApi.reducer,
});

export const coreReducer = (state: RootStateOrAny, action: AnyAction) => {
  if (action.type === 'auth/logout') {
    const { app } = state;
    state = { app } as RootStateOrAny;
  }

  return combinedReducers(state, action);
};

export type CoreReducer = ReturnType<typeof coreReducer>;
