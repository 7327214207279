const SURGERY_MODIFIER = {
  PANCREAS: 0.018,
  LIVER: 0.073,
};

const BMI_BENCHMARK = {
  PANCREAS: 18.5,
  LIVER: 24.99,
};

const CONSTANTS_MAP = {
  PANCREAS: {
    1: 1.102,
    2: 1.123,
    3: -0.072,
    4: -0.054,
    5: 0.779,
    6: 0.8,
    7: -0.397,
    8: -0.377,
    9: 1.545,
    10: 1.565,
    11: 0.368,
    12: 0.388,
    13: 1.222,
    14: 1.242,
    15: 0.045,
    16: 0.065,
  },

  LIVER: {
    1: 6.680,
    2: 5.586,
    3: 4.130,
    4: 3.036,
    5: 6.075,
    6: 4.982,
    7: 3.525,
    8: 2.432,
    9: 19.601,
    10: 18.508,
    11: 17.051,
    12: 15.957,
    13: 18.997,
    14: 17.903,
    15: 16.447,
    16: 15.353,
  },
};

const AVG_SCORES = {
  PANCREAS: {
    SUBSTANCE_USE: 0.3,
    NO_SUBSTANCE_USE: 0.26,
  },

  LIVER: 0.65,
};

const questionLabels = {
  "What is your age?": "ageStr",
  "What is your weight?": "weightStr",
  "What is your height?": "heightStr",
  "Do you smoke?": "smokingStr",
  "Do you have a history of substance abuse?": "substanceStr",
  "Have you used opioids before surgery?": "opioidsStr",
};

export const getAvgRisk = (params) => {
  const { procedureStr, substanceStr } = params;
  const substanceUse = stringToBool(substanceStr);
  const procedure = procedureStr.toUpperCase();
  const SCORE = AVG_SCORES[procedure.toUpperCase()];

  switch (procedure) {
    case "PANCREAS":
      if (substanceUse) {
        return SCORE.SUBSTANCE_USE;
      }
      return SCORE.NO_SUBSTANCE_USE;

    case "LIVER":
      return SCORE;

    default:
  }
};

export const createOpioidParams = (procedureName, questions, answers) => {
  const procedureStr = procedureName.split(" ")[0];
  const params = { procedureStr };

  questions.forEach((question) => {
    const key = questionLabels[question.title];
    const answerIndex = answers[question.id];

    switch (question.type) {
      case "numerical_input":
      case "text":
        params[key] = answerIndex;
        break;

      case "radio":
        params[key] = question.answers[answerIndex].text;
        break;

      default:
    }
  });
  return params;
};

function stringToBool(string) {
  switch (string.toLowerCase()
    .trim()) {
    case "yes": return true;
    case "no": return false;
    default:
  }
}

function stringToBMI(weightStr, heightStr) {
  const weight = parseFloat(weightStr);
  const height = parseFloat(heightStr) / 100;
  const bmi = weight / Math.pow(height, 2);
  return bmi;
}

function getModelEquation(constant, surgeryModifier, age) {
  const exp = Math.exp(constant - surgeryModifier * age);
  return exp / (1 + exp);
}

/* eslint-disable import/prefer-default-export */
export function getOpioidRisk(params) {
  const {
    procedureStr,
    ageStr,
    opioidsStr,
    smokingStr,
    substanceStr,
    weightStr,
    heightStr,
  } = params;

  const procedure = procedureStr.toUpperCase();
  const age = Number(ageStr);
  const opioids = stringToBool(opioidsStr);
  const smoking = stringToBool(smokingStr);
  const substanceUse = stringToBool(substanceStr);
  const constantsMap = CONSTANTS_MAP[procedure];
  const surgeryModifier = SURGERY_MODIFIER[procedure];

  let bmi;

  if (stringToBMI(weightStr, heightStr) >= BMI_BENCHMARK[procedure]) {
    bmi = true;
  } else {
    bmi = false;
  }

  let equationNum;

  if (!opioids && !smoking && !bmi && !substanceUse) {
    equationNum = 1;
  }
  if (!opioids && !smoking && !bmi && substanceUse) {
    equationNum = 2;
  }
  if (!opioids && !smoking && bmi && !substanceUse) {
    equationNum = 3;
  }
  if (!opioids && !smoking && bmi && substanceUse) {
    equationNum = 4;
  }
  if (!opioids && smoking && !bmi && !substanceUse) {
    equationNum = 5;
  }
  if (!opioids && smoking && !bmi && substanceUse) {
    equationNum = 6;
  }
  if (!opioids && smoking && bmi && !substanceUse) {
    equationNum = 7;
  }
  if (!opioids && smoking && bmi && substanceUse) {
    equationNum = 8;
  }
  if (opioids && !smoking && !bmi && !substanceUse) {
    equationNum = 9;
  }
  if (opioids && !smoking && !bmi && substanceUse) {
    equationNum = 10;
  }
  if (opioids && !smoking && bmi && !substanceUse) {
    equationNum = 11;
  }
  if (opioids && !smoking && bmi && substanceUse) {
    equationNum = 12;
  }
  if (opioids && smoking && !bmi && !substanceUse) {
    equationNum = 13;
  }
  if (opioids && smoking && !bmi && substanceUse) {
    equationNum = 14;
  }
  if (opioids && smoking && bmi && !substanceUse) {
    equationNum = 15;
  }
  if (opioids && smoking && bmi && substanceUse) {
    equationNum = 16;
  }

  const result = getModelEquation(constantsMap[equationNum], surgeryModifier, age);
  return result;
}
