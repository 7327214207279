import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import tw, { styled } from 'twin.macro';
import { BiArrowBack, BiRightArrowAlt } from 'react-icons/bi';
import EduFeedback from './EduFeedback';
import { Education } from '../../../patient-app-common/types/Education';

interface EduFooterProps {
  education: Education[],
  componentID: string;
}

export default function EduFooter({ education, componentID }: EduFooterProps) {
  const { t } = useTranslation();

  const getNextTopic = () => {
    const id = _.toNumber(componentID);
    const index = _.findIndex(education, ['id', id]);
    const nextTopic = education[index + 1];

    return nextTopic;
  };

  const getPrevTopic = () => {
    const id = _.toNumber(componentID);
    const index = _.findIndex(education, ['id', id]);
    const prevTopic = education[index - 1];

    return prevTopic;
  };

  const nextTopic = getNextTopic();
  const prevTopic = getPrevTopic();

  return (
    <div>
      <CardFooter firstTopic={!prevTopic}>
        {prevTopic && (
          <NavLinkDiv left>
            <p className="p-1">
              {t('common.action.back')}
            </p>
            <NavLink to={`/education/${prevTopic.id}`}>
              <span>
                <BiArrowBack className="text-xl" />
              </span>
              <span>{prevTopic.title}</span>
            </NavLink>
          </NavLinkDiv>
        )}
        {nextTopic && (
          <NavLinkDiv right>
            <p className="p-1">
              {t('common.action.next')}
            </p>
            <NavLink to={`/education/${nextTopic.id}`}>
              <span>{nextTopic.title}</span>
              <span>
                <BiRightArrowAlt className="text-xl" />
              </span>
            </NavLink>
          </NavLinkDiv>
        )}
      </CardFooter>
      <EduFeedback education={education} componentID={componentID} />
    </div>
  );
}

const CardFooter = styled.div(({ firstTopic }: { firstTopic: boolean }) => [
  tw`border-t border-gray-500`,
  tw`flex flex-col md:flex-row md:justify-between`,
  tw`p-0 md:p-3`,
  firstTopic && tw`md:justify-end`,
]);

const NavLinkDiv = styled.div(({ left, right }: { left: boolean, right: boolean }) => [
  tw`flex-col text-center`,
  left && tw`md:text-left border-b border-gray-500 md:border-white`,
  right && tw`flex-col text-center md:text-right`,
]);

const NavLink = styled(Link)(() => [
  tw`inline-flex text-blue-100`,
  tw`text-xs lg:text-sm`,
  tw`hover:underline`,
]);
