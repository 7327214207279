// @ts-nocheck
import React, {
  useContext,
  useEffect,
  useState,
  useRef
} from 'react';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import tw, { css, styled } from 'twin.macro';
import { ActionCableContext } from '../../context/ActionCableContext';

import { ThreadList } from './components/ThreadList';
import { ThreadListItem } from './components/ThreadListItem';
import { ThreadPlaceholder } from './components/ThreadPlaceholder';

import NavBar from '../layout/NavBar';
import Thread from './Thread';
import ThreadInput from './ThreadInput';

export default function Inbox() {
  const { t } = useTranslation('inbox');

  const mainRef = useRef<HTMLElement>(null);
  const threadRef = useRef<HTMLDivElement>(null);

  const [selectedThread, setSelectedThread] = useState<null | number>(null);

  const { secureMessageThreads: threads, error, isLoading } = useContext(ActionCableContext);

  const [closedThreadDisplayCount, setClosedThreadDisplayCount] = useState(5);

  const orderedThreads = _.orderBy(threads, ['unreadMessage', 'lastMessageSentAt'], ['desc', 'desc']);
  const [openThreads, closedThreads] = _.partition(orderedThreads, ['resolved', false]);
  const closedThreadsToDisplay = _.orderBy(closedThreads.slice(0, closedThreadDisplayCount));
  const thread = _.find(threads, ['id', selectedThread]);

  const showMore = () => {
    if (_.isEmpty(closedThreads)) return;

    if (closedThreads.length > closedThreadDisplayCount) {
      setClosedThreadDisplayCount(closedThreadDisplayCount + 5);
    } else {
      setClosedThreadDisplayCount(closedThreads.length);
    }
  };


  useEffect(() => {
    if (!thread) return;

    if (threadRef.current) {
      threadRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [thread]);

  return (
    <div>
      <Helmet>
        <style>{'body { overflow: hidden; }'}</style>
      </Helmet>
      <NavBar skipHandler={() => {
        if (mainRef.current) {
          mainRef.current.focus();
        }
      }}
      />
      <Container
        aria-label={t('common.link.inbox')}
        role="region"
        tabIndex={0}
        ref={mainRef}
      >
        <ThreadList
          onNewThreadClick={() => setSelectedThread(0)}
          overlap={selectedThread === null}
        >
          <>
            {!_.isEmpty(openThreads) && <ListHeader>{t('inbox.subheader.open_threads')}</ListHeader>}
            {openThreads.map((th) => (
              <div
                className="cursor-pointer"
                onClick={() => setSelectedThread(th.id)}
                onKeyDown={() => setSelectedThread(th.id)}
                ref={selectedThread === th.id ? threadRef : null}
                role="link"
                tabIndex={0}
                key={th.id}
              >
                <ThreadListItem
                  thread={th}
                  selected={selectedThread === th.id}
                />
              </div>
            ))}
            {!_.isEmpty(closedThreads) && (
              <>
                <ListHeader>{t('inbox.subheader.closed_threads')}</ListHeader>
                {closedThreadsToDisplay.map((th) => (
                  <div
                    className="cursor-pointer"
                    onClick={() => setSelectedThread(th.id)}
                    onKeyDown={() => setSelectedThread(th.id)}
                    ref={selectedThread === th.id ? threadRef : null}
                    role="link"
                    tabIndex={0}
                    key={th.id}
                  >
                    <ThreadListItem
                      selected={selectedThread === th.id}
                      thread={th}
                    />
                  </div>
                ))}
                <div className="flex justify-center my-4">
                  {closedThreads.length > closedThreadDisplayCount && (
                    <button
                      className="btn-secondary"
                      onClick={showMore}
                    >
                      {t('common.action.show_more')}
                    </button>
                  )}
                </div>
              </>
            )}
          </>
        </ThreadList>
        {selectedThread === null && (
          <ThreadPlaceholder
            isLoading={isLoading}
            error={error}
            count={_.filter(threads, ['unreadMessage', true]).length}
          />
        )}
        {selectedThread !== null && (
          <>
            <Thread
              id={selectedThread}
              disableReply={thread?.resolved || thread?.patientReplyEnabled === false}
            />
            <ThreadInput
              onClose={() => setSelectedThread(null)}
              setSelectedThread={setSelectedThread}
              thread={thread}
            />
          </>
        )}
      </Container>
    </div>
  );
}

const Container = styled.div(() => [
  tw`bg-gray-100 h-screen`,
  css`
    max-height: -webkit-fill-available;
  `,
  tw`overflow-hidden`,
]);

const ListHeader = styled.div(() => [
  tw`font-semibold pb-2 pt-8 px-4`,
]);
