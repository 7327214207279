import React from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import storage from 'redux-persist/lib/storage';
import { logout } from './patient-app-common/slices/authSlice';
import { Modal } from './components/layout';
import useModal from './hooks/useModal';

export default function IdleTimer() {
  const dispatch = useDispatch();
  const { patientId } = useSelector(({ auth }) => auth);
  const { visible, toggleModal } = useModal();

  const handleOnIdle = (event) => {
    if (window.Cypress) return;

    if (patientId) {
      storage.removeItem('root-v1');
      dispatch(logout());
      toggleModal();
    }
  };

  useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  return (
    <div>
      <Modal
        visible={visible}
        handleCloseClick={toggleModal}
        component={<TimeoutAlert handleCloseClick={toggleModal} />}
      />
    </div>
  );
}

function TimeoutAlert({ handleCloseClick }) {
  return (
    <div>
      <div className="py-4">
        <p className="font-bold pb-2">You have been inactive for too long!</p>
        <p>For security reasons, we logged you out of SeamlessMD.</p>
      </div>
      <div className="flex justify-evenly">
        <button
          onClick={() => handleCloseClick()}
          className="btn-primary w-3/4 mx-1"
        >
          Close
        </button>
      </div>
    </div>
  );
}
