import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import tw, { styled } from 'twin.macro';
import { BiChevronRight } from 'react-icons/bi';
import { Education } from '../../../patient-app-common/types/Education';
import { breadcrumbs } from '../../../patient-app-common/helpers/education_helpers';

interface BreadcrumbProps {
  education: Education[],
  componentID: string,
}

export default function Breadcrumb({ education, componentID }: BreadcrumbProps) {
  const topics = breadcrumbs(education, componentID);
  const { t } = useTranslation();

  return (
    <Container>
      <div className="inline-flex">
        <NavLink to="/education/">
          {t('common.link.library')}
        </NavLink>
        <span>
          <BiChevronRight className="text-2xl" />
        </span>

        {topics.reverse().map((topic, index) => {
          const lastIndex = topics.length - 1;

          if (index !== lastIndex) {
            return (
              <>
                <NavLink to={`/education/${topic.id}`}>
                  <span className="overflow-ellipsis">{topic.title}</span>
                </NavLink>
                <span>
                  <BiChevronRight className="text-2xl" />
                </span>
              </>
            );
          }
          return <span>{topic.title}</span>;
        })}
      </div>
    </Container>
  );
}

const Container = styled.div(() => [
  tw`border-b border-gray-500 p-3`,
  tw`text-sm lg:text-base`,
]);

const NavLink = styled(Link)(() => [tw`text-blue-100`, tw`hover:underline`]);
