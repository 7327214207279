import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from '../../layout/Card';

export default function EndCard() {
  const { t } = useTranslation('home');

  return (
    <div className="py-4" data-test="phase-card">
      <Card
        headerComp={(
          <div>
            <h2 className="font-bold">
              <span>{t('endcard.title')}</span>
            </h2>
          </div>
        )}
        bodyComp={
          <p>{t('endcard.description')}</p>
        }
      />
    </div>
  );
}
