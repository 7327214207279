import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import './localization/i18n';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux/configureStore';
import * as serviceWorker from './serviceWorker';
import App from './App';

// Sentry config
// DSNs are safe to keep public https://docs.sentry.io/product/sentry-basics/concepts/dsn-explainer/
Sentry.init({
  dsn: 'https://7a252c5af7824ad08c6a10da3fb51bfe@o16484.ingest.sentry.io/5600389',
  autoSessionTracking: true,
  integrations: [
    new Integrations.BrowserTracing(),
  ],
  environment: process.env.REACT_APP_SENTRY_ENV,
  tracesSampleRate: 0.1,
});

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
