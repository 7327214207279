import { useMemo } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { surveyHelperContext, reviewHelperContext } from '../patient-app-common/helpers/survey_helper';
import { useDeleteTrackerDraftMutation, useSaveTrackerDraftMutation } from '../patient-app-common/api/trackerApi';
import { useGetPatientQuery } from '../patient-app-common/api/patientApi';

export default function useSurveyDrafts() {
  const { data: patient } = useGetPatientQuery();

  const [updateDraft] = useSaveTrackerDraftMutation();
  const [deleteDraft] = useDeleteTrackerDraftMutation();

  const surveys = useMemo(() => {
    if (!patient) return [];
    return patient.surveys;
  }, [patient]);

  const surveyDrafts = useMemo(() => {
    if (!patient) return [];
    return patient.surveyDrafts;
  }, [patient]);

  const expireDraft = (surveyData, events = []) => {
    const { draft } = surveyData;
    const draftDate = draft.draftCreatedAt;

    const isHealthCheck = surveyData.mainTracker;
    const eventId = draft.applicationEventId;

    let expired = false;

    if (isHealthCheck) {
      const isToday = moment()
        .startOf('day')
        .isSame(moment(draftDate).startOf('day'));

      expired = !isToday;
    }

    if (eventId && events?.length) {
      const appEvent = _.find(events, ['id', eventId.toString()]);

      if (appEvent?.startDateTime) {
        const isCurrent = moment(draftDate).startOf('day')
          .isSameOrAfter(moment(appEvent.startDateTime).startOf('day'));

        expired = !isCurrent;
      }
    }

    if (expired) {
      try {
        deleteDraft({ procedureId: surveyData.id });
      } catch (e) {
        console.log(e);
      }
    }

    return expired;
  };

  const dataUrlToFile = async (imageURL) => {
    const response = await fetch(imageURL);
    const blob = await response.blob();
    return new File([blob], "file.jpg", { type: 'image/jpeg' });
  };

  const setAnswersWithFiles = async (imageKeys, draft) => {
    const cameraAnswers = {};

    await Promise.all(imageKeys.map(async (key) => {
      const answerKey = key.split('_')[1];
      const url = draft[answerKey];
      cameraAnswers[answerKey] = await dataUrlToFile(url);
    }));
    return cameraAnswers;
  }

  const parseDraftAnswers = async (draft) => {
    const source = JSON.parse(draft.source);
    let draftAnswers = { ...source.answers };

    if (draft.imageKeys) {
      let imageKeys = JSON.parse(draft.imageKeys);

      const cameraAnswers = await setAnswersWithFiles(imageKeys, draft);
      draftAnswers = { ...source.answers, ...cameraAnswers };
    }

    return draftAnswers;
  };

  const parseDraftData = async (surveyData, patientDates, profile) => {
    const helper = surveyHelperContext({
      patientDates,
      surveyData,
      editMode: true,
    });

    const { draft } = surveyData;
    const currentAnswers = await parseDraftAnswers(draft);

    const {
      validQuestions,
      validProfiles,
      validAnswers,
    } = helper.validateQuestions({
      currentIndex: 0,
      currentAnswers,
      currentHistory: [0],
      currentProfiles: [profile],
    });

    const draftData = {
      draftCreatedAt: draft.draftCreatedAt,
      draftAnswers: validAnswers,
      draftHistory: validQuestions,
      draftProfiles: validProfiles,
    };

    return draftData;
  };

  const saveDraft = async ({
    answers,
    eventId,
    surveyData,
  }) => {
    let params = {
      procedureId: surveyData.id,
      applicationEventId: eventId,
      source: JSON.stringify({ answers }),
    };

    const photoParams = reviewHelperContext({ surveyData, answers })
      .createPhotoParams();

    if (photoParams && Object.keys(photoParams).length > 0) {
      params = { ...params, ...photoParams };
    }

    try {
      const result = await updateDraft(params).unwrap();
      return result;
    } catch (e) {
      throw new Error(e);
    }
  };

  return {
    surveys,
    surveyDrafts,
    expireDraft,
    saveDraft,
    parseDraftData,
  };
}
