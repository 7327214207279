import { useCallback, useMemo } from 'react';
import _ from 'lodash';
import { useGetAllSurveysQuery } from '../api/surveyApi';


export default function useSurveys(options = { skip: false }) {
  const {
    data,
    error,
    isLoading,
  } = useGetAllSurveysQuery(undefined, { skip: options.skip });

  const allSurveys = useMemo(() => {
    if (!data || error) return null;
    return data;
  }, [data, error]);

  const getSurveyById = useCallback((id: string) => {
    if (!allSurveys) return null;

    return _.find(allSurveys, ['id', id]);
  }, [allSurveys]);

  return {
    isLoading,
    allSurveys,
    getSurveyById,
  };
}
