import React, { useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import tw, { styled } from 'twin.macro';
import { setUnitPref } from '../../../patient-app-common/slices/appSlice';

export default function WeightInput({ sendAnswer, answerHistory }) {
  const dispatch = useDispatch();
  const textInput = useRef(null);
  let { unitPref } = useSelector(({ app }) => app);

  if (!unitPref) {
    unitPref = 'metric';
  }

  const [unit, setUnit] = useState(unitPref);
  const [error, setError] = useState('');

  const defaultValue = useMemo(() => {
    if (!answerHistory) return null;

    if (unitPref === 'imperial') {
      return _.round(answerHistory * 2.205);
    }

    return answerHistory;
  }, [answerHistory, unitPref]);

  const validateNumber = (e) => {
    const parsedAnswer = parseFloat(e.target.value);

    if (!Number.isFinite(parsedAnswer) || parsedAnswer < 0) {
      setError('Enter a valid number');
      return false;
    }

    return true;
  };

  const convertLBtoKG = (value) => {
    const kg = value * 0.45359237;
    return _.round(kg, 2);
  };

  const handleInput = (event) => {
    setError('');
    const { value } = event.target;
    const valid = validateNumber(event);

    if (!valid) {
      sendAnswer(null);
      return;
    }

    let answer;

    if (unit === 'imperial') {
      answer = convertLBtoKG(value).toString();
    } else {
      answer = _.round(value, 2).toString();
    }
    sendAnswer(answer);
  };

  const handleToggleClick = (unitStr) => {
    if (unitStr === unit) {
      return;
    }

    setUnit(unitStr);
    dispatch(setUnitPref(unitStr));

    textInput.current.value = null;
    sendAnswer(null);
  };

  const unitDisplay = (unit === 'metric') ? 'kg' : 'lbs';

  return (
    <Container data-test="weight-input-component">
      <div>
        <InputDiv>
          <Input
            id="weight"
            ref={textInput}
            type="number"
            min="0"
            onInput={(e) => handleInput(e)}
            defaultValue={defaultValue}
          />
          <Label htmlFor="weight">{unitDisplay}</Label>
        </InputDiv>
      </div>

      <div role="alert">
        <ErrorDiv>
          <p className="error-text">{error}</p>
        </ErrorDiv>
      </div>

      <div>
        <UnitPanel>
          <div className="pr-2">
            <input
              id="imperial"
              name="imperial"
              type="radio"
              checked={unit === 'imperial'}
              onChange={() => handleToggleClick('imperial')}
            />
          </div>
          <div>
            <label htmlFor="imperial" className="font-bold">
              Pounds (lbs)
            </label>
          </div>

          <div className="pl-8 px-2">
            <input
              id="metric"
              name="metric"
              type="radio"
              checked={unit === 'metric'}
              onChange={() => handleToggleClick('metric')}
            />
          </div>
          <div>
            <label htmlFor="metric" className="font-bold">
              Kilograms (kg)
            </label>
          </div>
        </UnitPanel>
      </div>
    </Container>
  );
}

const Container = styled.div(() => [
  tw`flex flex-col space-y-4`,
]);

const InputDiv = styled.div(() => [
  tw`w-full md:w-1/2`,
]);

const Input = styled.input(() => [
  tw`border border-gray-500 flex-row justify-start p-2 rounded w-3/4`,
  tw`text-lg sm:text-2xl`,
]);

const Label = styled.label(() => [
  tw`px-2 w-1/4`,
  tw`text-lg sm:text-2xl`,
]);

const ErrorDiv = styled.div(() => [
  tw`flex flex-initial`,
  tw`w-full`,
]);

const UnitPanel = styled.div(() => [
  tw`inline-flex w-full`,
]);
