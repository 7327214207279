import _ from 'lodash';
import React, { useState } from 'react';
import { ImSpinner8 } from 'react-icons/im';
import { useTranslation } from 'react-i18next';
import tw, { styled } from 'twin.macro';
import { useRequestPasswordMutation } from '../../patient-app-common/api/authApi';
import ReCaptcha from './ReCaptcha';

export default function RequestPassword() {
  const { t } = useTranslation('login');
  const [requestPassword, { isLoading }] = useRequestPasswordMutation();

  const [contact, setContact] = useState('');
  const [isHuman, setIsHuman] = useState(true);
  const [error, setError] = useState('');
  const [serverError, setServerError] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const validateInput = () => {
    const cleanedInput = contact.replace(/[()\-\s]/g, '');
    const isPhone = cleanedInput && !_.isNaN(_.toNumber(cleanedInput));

    let valid = null;

    if (isPhone) {
      valid = cleanedInput.match(/^\d{10}$/);
    } else {
      // eslint-disable-next-line no-useless-escape
      const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      valid = contact.match(emailRegex);
    }

    if (!valid) {
      setError(t('requestpassword.error.invalid_contact'));
    }

    return valid;
  };

  const handleBtnClick = async () => {
    if (!contact) {
      setError(t('common.error.blank_input'));
      return;
    }

    const valid = validateInput();

    if (!valid) return;

    if (!isHuman) {
      setError(t('common.error.recaptcha'));
      return;
    }

    setError('');

    try {
      await requestPassword({ email: contact }).unwrap();
      setSubmitted(true);
    } catch (e) {
      setServerError(t('common.error.server'));
    }
  };

  const handleHelpBtnClick = () => {
    const url = 'mailto:support@seamless.md?subject=SeamlessMD%20Support%20request%20-%20Password%20Reset&body=Please%20include%20the%20following%20information%3A%0A%0APatient%20Name%3A%0AHospital%20Name%3A%0AEmail%3A%0APhone%20number%3A';
    window.open(url, '_blank');
  };

  return (
    <div className="bg-gray-100 h-screen">
      <div className="flex justify-center">
        <ColumnContainer>
          <FormContainer>
            <h1 className="font-semibold">
              {t('common.link.help_account')}
            </h1>
            <p className="py-2">
              {t('requestpassword.description')}
            </p>
            <p>
              {t('requestpassword.help_text')}
            </p>
            <button
              className="text-blue-100 mt-4"
              onClick={handleHelpBtnClick}
            >
              {t('common.link.help')}
            </button>
            <div className="pt-6">
              <label htmlFor="email" className="block">
                <p className="font-semibold">
                  {t('requestpassword.input')}
                </p>
                <Input
                  error={error}
                  id="email"
                  name="email"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setError('');
                    setSubmitted(false);
                    setContact(e.target.value);
                  }}
                />
              </label>
            </div>
            <ReCaptcha
              setIsHuman={(result: boolean) => setIsHuman(result)}
            />
            <div className="pt-4">
              <button
                className="btn-primary"
                onClick={() => handleBtnClick()}
                disabled={isLoading}
              >
                {isLoading && (
                  <span className="inline-flex mr-2">
                    <ImSpinner8 className="animate-spin" />
                  </span>
                )}
                {t('requestpassword.button.submit')}
              </button>
            </div>
            {error && <p className="error-text pt-4">{error}</p>}
            {submitted && <p className="italic pt-4">{t('requestpassword.response_success')}</p>}
            {serverError && (<p className="pt-4">{serverError}</p>)}
          </FormContainer>
        </ColumnContainer>
      </div>
    </div>
  );
}

const ColumnContainer = styled.div(() => [
  tw`text-left p-8 pt-16`,
  tw`w-full md:w-1/2`,
]);

const FormContainer = styled.div(() => [
  tw`bg-white border border-gray-500 p-12 rounded shadow`,
]);

const Input = styled.input(({ error }: { error: boolean }) => [
  tw`block form-input mt-1 w-full`,
  tw`hover:border-blue-100`,
  error && tw`border-red`,
]);
