import * as React from 'react';
import useModal from '../../../../hooks/useModal';
import DateModal from '../../prompts/DateModal';
import { Modal } from '../../../layout';
import EndCard from './EndCard';
import EndSurveyCard from './EndSurveyCard';
import HealthCheckCard from './HealthCheckCard';
import PreopCardHL7 from './PreopCardHL7';
import PreopCard from './PreopCard';
import PreopVisitCard from './PreopVisitCard';
import PeriopCard from './PeriopCard';
import PostopCard from './PostopCard';
import { Patient } from '../../../../patient-app-common/types/Patient';
import { PatientDates } from '../../../../patient-app-common/hooks/usePatientDates';

interface SurgicalProgramProps {
  patient: Patient;
  patientDates: PatientDates;
  programPhase: undefined | string;
}

export default function SurgicalProgram({
  patient,
  patientDates,
  programPhase,
}: SurgicalProgramProps) {
  const { visible, toggleModal } = useModal();

  return (
    <>
      <Modal
        visible={visible}
        handleCloseClick={toggleModal}
        component={(
          <DateModal
            programPhase={programPhase}
            patientDates={patientDates}
            callbackFn={toggleModal}
          />
        )}
      />
      <SurgicalPhaseCard
        patient={patient}
        programPhase={programPhase}
        patientDates={patientDates}
        toggleModal={toggleModal}
      />
    </>
  );
}

function SurgicalPhaseCard({
  patient,
  patientDates,
  programPhase,
  toggleModal,
}: SurgicalProgramProps & { toggleModal: () => void }) {
  const hl7 = patient.surgicalDetails?.enrollmentType === 'auto_enrolled';
  const { preOpDate, surgeryDate, dischargeDate } = patientDates;

  switch (programPhase) {
    case 'inPreop':
      if (hl7) return <PreopCardHL7 patient={patient} />;
      return (
        <>
          <PreopVisitCard
            hospitalDisplayName={patient.hospitalDisplayName}
            preOpDate={preOpDate}
            timezone={patient.timezone}
          />
          <PreopCard patient={patient} surgeryDate={surgeryDate} toggleModal={toggleModal} />
        </>
      );

    case 'inPeriop':
      return (
        <PeriopCard
          patient={patient}
          surgeryDate={surgeryDate}
          toggleModal={toggleModal}
        />
      );

    case 'inPostop':
      return (
        <>
          <PostopCard patient={patient} dischargeDate={dischargeDate} />
          <HealthCheckCard
            patient={patient}
            patientDates={patientDates}
          />
        </>
      );

    case 'end':
      return (
        <>
          <EndCard />
          <EndSurveyCard
            endSurveyId={patient.endSurveyId}
            endSurveyCompleted={patient.endSurveyCompleted}
          />
        </>
      );

    default:
      return null;
  }
}
