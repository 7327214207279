import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import tw, { css, styled } from 'twin.macro';

import useSegment from '../../../hooks/useSegment';
import useSurveyDrafts from '../../../hooks/useSurveyDrafts';
import {
  useUpdateMedicineHistoryMutation,
  useUpdateProfileMutation,
  useReadEventMutation,
} from '../../../patient-app-common/api/patientApi';
import {
  useDeleteTrackerDraftMutation,
  useExpireOneTimeTokenMutation,
  usePostTrackerMutation,
} from '../../../patient-app-common/api/trackerApi';
import { reviewHelperContext } from '../../../patient-app-common/helpers/survey_helper';
import { useAppSelector } from '../../../redux/hooks';

import SurveyLayout from '../layout/SurveyLayout';
import SurveyLoader from '../layout/SurveyLoader';
import QuestionAnswer from './QuestionAnswer';

const SUBMIT_SUCCESS_EVENT = 'Survey Completed';
const SUBMIT_FAIL_EVENT = 'Survey failed to submit';

export default function Review({
  answers,
  draft,
  eventId,
  exitHandler,
  handleEdit,
  medicineHistory,
  profile,
  questions,
  surveyData,
}) {
  const { providerMode, token } = useAppSelector(({ auth }) => auth);

  const { t } = useTranslation('survey');
  const { trackSurvey } = useSegment();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { saveDraft } = useSurveyDrafts();

  const [submitTracker] = usePostTrackerMutation();
  const [updateProfile] = useUpdateProfileMutation();
  const [updateMedicineHistory] = useUpdateMedicineHistoryMutation();
  const [readEvent] = useReadEventMutation();
  const [expireSurveyToken] = useExpireOneTimeTokenMutation();
  const [deleteDraft] = useDeleteTrackerDraftMutation();

  const surveyName = surveyData.mainTracker ? t('common.text.main_tracker') : surveyData.patientDisplayName;

  const [loader, showLoader] = useState(null);
  const [reviewHelper, setReviewHelper] = useState(null);

  useEffect(() => {
    const helper = reviewHelperContext({ surveyData, answers, profile });
    setReviewHelper(helper);
    
  }, [answers, profile]);

  const eventType = useMemo(() => {
    if (eventId) {
      return 'app_event';
    }
    if (surveyData.mainTracker) {
      return 'main_tracker';
    }
    if (surveyData.setupSurvey) {
      return 'setup_survey';
    }
    if (surveyData.mainSurvey) {
      return 'main_survey';
    }
    return '';
  }, [surveyData, eventId]);

  const handleDraft = async () => {
    if (draft && !surveyData.editable) {
      try {
        await deleteDraft({ procedureId: surveyData.id }).unwrap();
      } catch (e) {
        console.log(e);
      }
    }

    if (surveyData.editable) {
      saveDraft({
        answers,
        eventId,
        surveyData,
      });
    }
  };

  const handleSubmitClick = async () => {
    showLoader({ text: t('review.loading_text') });

    let params = reviewHelper.createSurveyParams(eventType, eventId);
    const photoParams = reviewHelper.createPhotoParams();
    if (photoParams && Object.keys(photoParams).length > 0) {
      params = { ...params, ...photoParams };
    }

    const profileParams = reviewHelper.createProfileParams();
    const medicineParams = reviewHelper.createMedProfile(medicineHistory);

    try {
      const result = await submitTracker(params).unwrap();

      if (result.submitted) {
        await handleDraft();
        if (eventId) await readEvent({ eventId }).unwrap();
        if (!_.isEmpty(profileParams)) await updateProfile(profileParams).unwrap();
        if (!_.isEmpty(medicineParams)) await updateMedicineHistory(medicineParams).unwrap();
        if (providerMode) await expireSurveyToken(token);

        trackSurvey(SUBMIT_SUCCESS_EVENT, { id: eventId }, surveyData);

        showLoader({ text: t('review.loading_text') });

        navigate(`/recommendation/${surveyData.id}`, {
          state: {
            ...state,
            surveyData,
          },
        });
      } else {
        trackSurvey(SUBMIT_FAIL_EVENT, { id: eventId }, surveyData);
        showLoader({
          text: t('common.error.server'),
          type: 'error',
        });
      }
    } catch (e) {
      trackSurvey(SUBMIT_FAIL_EVENT, { id: eventId }, surveyData);
      showLoader({
        text: t('common.error.server'),
        type: 'error',
      });
    }
  };

  if (loader) {
    return (
      <SurveyLoader
        text={loader.text}
        type={loader.type}
        handleCancelClick={() => showLoader(null)}
      />
    );
  }

  return (
    <div>
      <SurveyLayout
        exitHandler={exitHandler}
        nextHandler={handleSubmitClick}
        progress={100}
        disableClose
        review
        surveyName={surveyName}
      >
        <Notice>
          <p className="font-bold">{t('review.title')}</p>
          <p className="text-sm">{t('review.description')}</p>
        </Notice>
        <QuestionAnswer
          answers={answers}
          handleEdit={handleEdit}
          questions={questions}
        />
      </SurveyLayout>
    </div>
  );
}

const Notice = styled.div(() => [
  tw`border-gray-500 my-4 p-4 rounded-lg`,
  css`background-color: #FCF8E4`,
]);
