import React, { useEffect, useMemo } from 'react';
import Modal from '../layout/Modal';
import useEducation from '../../patient-app-common/hooks/useEducation';
import { combineEducationTopics } from '../../patient-app-common/helpers/education_helpers';
import { AppEvent } from '../../patient-app-common/types/ApplicationEvent';
import useSegment from '../../hooks/useSegment';

const EDUCATION_EVENT = 'Education Topic Viewed';

interface EducationModalProps {
  event: AppEvent;
  source: string;
  toggleModal: () => void;
  visible: boolean;
}
export default function EduModal({
  event,
  source,
  toggleModal,
  visible,
}: EducationModalProps) {
  const { allEducation } = useEducation();
  const { sendTrackEvent } = useSegment();

  useEffect(() => {
    if (!event || !visible) return;
    sendEventForTopics();
  }, [event, visible]);

  const sendEventForTopics = () => {
    const combinedTopic = combineEducationTopics(allEducation, event.edIds);

    if (!combinedTopic?.ids.length) return;

    combinedTopic.ids.forEach((id, index) => {
      sendTrackEvent(EDUCATION_EVENT, {
        id,
        title: combinedTopic.titles[index],
        source,
      });
    });
  };

  const HtmlContent = useMemo(() => {
    if (!allEducation.length || !event) {
      return <div />;
    }

    const { edIds } = event;

    const combinedTopic = combineEducationTopics(allEducation, edIds);

    if (combinedTopic.html) {
      return (
        <div
          className="ed-v3"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: combinedTopic.html }}
        />
      );
    }
    return <div />;
  }, [allEducation, event]);

  return (
    <Modal
      visible={visible}
      handleCloseClick={toggleModal}
      component={HtmlContent}
      showFooter
      width="1000px"
    />
  );
}
