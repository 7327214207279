import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import tw, { css, styled } from 'twin.macro';
import {
  treeSortEducation,
  filteredEducationTree,
} from '../../patient-app-common/helpers/education_helpers';
import useEducation from '../../patient-app-common/hooks/useEducation';
import NavBar from '../layout/NavBar';
import SearchInput from './layout/SearchInput';
import { Education } from '../../patient-app-common/types/Education';

export default function EduList() {
  const { t } = useTranslation('education');

  const mainRef = React.useRef<HTMLDivElement>(null);

  const { educationForProfile } = useEducation();

  const [searchTerm, setSearchTerm] = React.useState('');

  const filterBySearch = (edu: Education[]) => {
    const term = searchTerm.toLowerCase();
    return edu.filter((topic) => topic.title.toLowerCase().includes(term));
  };

  const availableTopics = useMemo(() => {
    if (!educationForProfile.length) return [];

    if (searchTerm) {
      // get all IDs of titles that match the search term
      const searchedIds = _.map(filterBySearch(educationForProfile), 'id');
      // include all children that belong to the matched titles
      const topicsTree = filteredEducationTree(educationForProfile, searchedIds);
      return topicsTree;
    }

    const topicsTree = treeSortEducation(educationForProfile, null);
    return topicsTree;
  }, [educationForProfile, searchTerm]);

  return (
    <>
      <NavBar skipHandler={() => mainRef.current && mainRef.current.focus()} />
      <Container
        aria-label={t('common.link.library')}
        role="region"
        tabIndex={0}
        ref={mainRef}
      >
        <ContentContainer>
          <SearchInput
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onClear={() => setSearchTerm('')}
          />

          <h1 className="text-2xl py-4">
            {t('common.link.library')}
          </h1>

          <TopicsContainer>
            <div>
              {availableTopics.map((component) => (
                <Link
                  key={component.id}
                  to={`/education/${component.id}`}
                >
                  <LinkContainer>
                    <p>{component.title}</p>
                  </LinkContainer>
                </Link>
              ))}
            </div>
          </TopicsContainer>

          {availableTopics.length === 0 && (
            <div className="text-center">
              <div className="p-10 text-xl overflow-hidden">
                <p className="whitespace-pre-line">
                  {t('edulist.search_empty', { searchTerm })}
                </p>
              </div>
            </div>
          )}
        </ContentContainer>
      </Container>
    </>
  );
}

const Container = styled.div(() => [
  tw`bg-gray-100 flex justify-center h-full min-h-screen`,
  css`
    min-height: -webkit-fill-available;
  `,
]);

const ContentContainer = styled.div(() => [tw`my-16`, tw`w-full md:w-1/2`]);

const LinkContainer = styled.div(() => [
  tw`cursor-pointer p-3 text-black`,
  tw`hover:bg-teal-30`,
  tw`text-base sm:text-lg`,
]);

const TopicsContainer = styled.div(() => [
  tw`bg-white border-gray-500 flex-col rounded shadow`,
]);
