import React from 'react';
import tw, { css, styled } from 'twin.macro';

interface CardProps {
  date?: string;
  icon?: string;
  color?: string;
  headerComp: React.ReactNode;
  bodyComp: React.ReactNode;
}

export default function Card({
  date = '',
  icon,
  color,
  headerComp,
  bodyComp,
}: CardProps) {
  return (
    <Container>
      <div><p className="font-light text-sm">{date}</p></div>
      <div className="divide-y p-4">
        <HeaderDiv className="flex p-1 text-sm">
          <div className="flex flex-grow">
            <span style={{ alignSelf: 'center' }}>{headerComp}</span>
          </div>
          {icon && (
            <ImgContainer color={color}>
              <img className="h-16 w-16 rounded-full" src={icon} alt="" />
            </ImgContainer>
          )}
        </HeaderDiv>
        <BodyDiv>
          {bodyComp}
        </BodyDiv>
      </div>
    </Container>
  );
}

const Container = styled.div(() => [
  tw`bg-white border-gray-500 p-4 rounded-lg shadow-md`,
]);

const ImgContainer = styled.div(({ color } : { color: string }) => [
  tw`h-16 w-16 rounded-full`,
  color ? css`background-color:${color};` : tw`bg-white`,
  css`min-width:64px`,
]);

const HeaderDiv = styled.div(() => [
  tw`flex p-1`,
  tw`text-base sm:text-lg`,
]);

const BodyDiv = styled.div(() => [
  tw`pt-4`,
  tw`text-sm sm:text-base`,
]);
