import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import tw, { styled } from 'twin.macro';
import { validateEntry } from '../../../patient-app-common/helpers/progress_helper';
import { getImageNumber } from '../../../patient-app-common/helpers/survey_helper';

export default function TrackerEntry({ entry, survey }) {
  if (!entry || !survey) {
    return null;
  }

  const { questions } = survey;
  const { source } = entry;

  return (
    <div className="mx-auto w-full md:w-1/2">
      <div className="flex flex-col my-8">
        {questions.map((question) => {
          const hasAnswer = _.has(source, question.id);
          if (!hasAnswer) return null;

          const answer = source[question.id];
          const isValid = validateEntry(question, answer);
          if (!isValid) return null;

          return (
            <Card key={question.id}>
              <h3 className="pb-2">{question.title}</h3>
              <AnswerFragment
                question={question}
                answer={answer}
              />
            </Card>
          );
        })}
      </div>
    </div>
  );
}

function AnswerFragment({ question, answer }) {
  const { t } = useTranslation();

  switch (question.type) {
    case 'camera':
      if (!answer) {
        return <p>{t('common.text.no_entry')}</p>;
      }
      return <img alt="" src={answer} />;

    case 'checkbox': {
      const selectedList = _.filter(question.answers, (_checkbox, i) => answer[i]);
      const labels = _.map(selectedList, 'text');
      const list = labels.map((label) => (<li key={label}>{label}</li>));

      return <div>{list}</div>;
    }

    case 'medicine': {
      const list = answer.map((value, i) => {
        if (!_.isEmpty(value)) {
          return (
            <li key={question.answers[i].text}>
              {`${question.answers[i].text} ${value.dose} - ${value.pills} pills`}
            </li>
          );
        }
        return '';
      });
      return <div>{list}</div>;
    }

    case 'radio':
    case 'gen_scale':
      return <p>{question.answers[answer]?.text}</p>;

    case 'picker':
      return <p>{getImageNumber(question.answers[answer]?.text)}</p>;

    default:
      return <p>{answer || t('common.text.no_entry')}</p>;
  }
}

const Card = styled.div(() => [
  tw`bg-white border-gray-500 my-4 p-8 rounded-lg shadow-md w-full`,
  tw`flex-col`,
]);
