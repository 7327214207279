import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiChevronRight } from 'react-icons/bi';
import tw, { styled } from 'twin.macro';
import { Panel } from '../../layout';
import SetTwoFA from './SetTwoFA';

export default function Account({ patient }) {
  const { t } = useTranslation('settings');

  const [modal, setModal] = useState(false);

  if (!patient.hasMfa || !patient.email) return null;

  return (
    <>
      <SetTwoFA
        visible={modal}
        handleExit={() => setModal(false)}
        patient={patient}
      />
      <Panel title={t('settings.list.app_settings')}>
        <Container>
          <Link htmlFor="2fa-toggle" role="link" onClick={() => setModal(true)}>
            {patient.mfaMethod ? t('common.two_fa.button_disable'): t('common.two_fa.button_enable')}
            <BiChevronRight />
          </Link>
        </Container>
      </Panel>
    </>
  );
}

const Container = styled.div(() => [
  tw`flex flex-col justify-center w-full`,
]);

const Link = styled.div(() => [
  tw`cursor-pointer flex flex-row justify-between py-4`,
  tw`hover:bg-teal-30`,
  tw`px-0 sm:px-12 `,
]);
