import React from 'react';
import tw, { css, styled } from 'twin.macro';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { parse } from 'node-html-parser';
import Modal from '../../layout/Modal';
import useModal from '../../../hooks/useModal';
import { replaceHeaders } from '../../../patient-app-common/helpers/education_helpers';
import useSegment from '../../../hooks/useSegment';

const EDUCATION_EVENT = 'Education Topic Viewed';

export default function ResultCard({ recommendation }) {
  const { t } = useTranslation();
  const { visible, toggleModal } = useModal();
  const { sendTrackEvent } = useSegment();

  const [eduHTML, setEduHTML] = React.useState('');

  const createEduModal = (ruleHTML) => {
    if (!ruleHTML) {
      return (<div />);
    }

    let formattedHTML = ruleHTML;
    const root = parse(ruleHTML);
    const H1 = root.querySelector('h1');

    if (H1) {
      H1.remove();

      const header = `<h1><strong>${H1.innerHTML}</strong></h1>`;

      formattedHTML = header + (replaceHeaders(root.toString()));
      formattedHTML = `<div class="ed-v3">${formattedHTML}</div>`
    }

    const fragment = (
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: formattedHTML }}
      />
    );

    return setEduHTML(fragment);
  };

  const icon = `${process.env.PUBLIC_URL}/assets/icons/${recommendation.apptext.recIconImage}`;
  const color = recommendation.apptext.recRecommendationBackgroundColor;

  const handleEduBtnClick = (ruleEdu) => {
    const { ids, titles, html } = ruleEdu;
    createEduModal(html);
    toggleModal();

    if (ids.length) {
      ids.forEach((id, index) => sendTrackEvent(EDUCATION_EVENT, {
        id,
        title: titles[index],
        source: 'ResultCard',
      }));
    }
  };

  const getMessageList = () => (
    recommendation.messageList.map((item) => {
      if (_.isEmpty(item)) {
        return null;
      }

      return (
        <Li key={item.ruleMsg}>
          {item.ruleMsg}
          <br />

          {!_.isEmpty(item.ruleEdu.html) && (
            <button
              onClick={() => handleEduBtnClick(item.ruleEdu)}
            >
              <EduLink>
                {t('common.link.learn_more')}
              </EduLink>
            </button>
          )}
        </Li>
      );
    })
  );

  return (
    <div>
      <Modal
        visible={visible}
        handleCloseClick={toggleModal}
        component={eduHTML}
        showFooter
        width="1000px"
      />

      <div className="flex justify-center">
        <CardContainer>
          <HeaderDiv color={color}>
            <IconDiv>
              <Icon src={icon} alt="" />
            </IconDiv>

            <TitleDiv>
              <p>{recommendation.apptext.recLabelText}</p>
            </TitleDiv>
          </HeaderDiv>

          <div className="p-6">
            <SubText>{recommendation.apptext.recListText}</SubText>
            <Ul>
              {getMessageList()}
            </Ul>
            <SubText>{recommendation.apptext.recDetailsText}</SubText>
          </div>
        </CardContainer>
      </div>
    </div>
  );
}

const CardContainer = styled.div(() => [
  tw`bg-white border border-gray-500 rounded shadow`,
  tw`w-full lg:w-1/2`,
]);

const HeaderDiv = styled.div(({ color }) => [
  tw`inline-flex w-full p-2 border-b border-gray-500`,
  css`background-color: ${color}; color: white;`,
]);

const IconDiv = styled.div(() => [
  tw`flex justify-center`,
  css`width: 15%`,
]);

const Icon = styled.img(() => [
  tw`h-16 md:mt-4 w-16`,
  css`min-width: 64px`,
]);

const TitleDiv = styled.div(() => [
  tw`p-6 text-white`,
  tw`text-xl sm:text-2xl `,
  css`width: 85%`,
]);

const SubText = styled.p(() => [
  tw`p-4 `,
  tw`text-sm sm:text-base`,
]);

const Ul = styled.ul(() => [
  tw`list-disc pl-6 space-y-4`,
]);

const Li = styled.li(() => [
  tw`text-sm sm:text-base`,
]);

const EduLink = styled.p(() => [
  tw`font-bold py-2 text-blue-100 underline`,
]);
