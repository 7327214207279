import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import tw, { styled } from 'twin.macro';

interface ZoneAlertProps {
  hospitalDisplayName: string;
  sameTimeZone: boolean | null;
  timezone: string
}

export default function ZoneAlert({
  hospitalDisplayName,
  sameTimeZone,
  timezone,
}: ZoneAlertProps) {
  const { t } = useTranslation('home');
  const hospitalZone = moment.tz(timezone).zoneAbbr();

  if (sameTimeZone) {
    return null;
  }

  return (
    <Container>
      <p>{t('zonealert.description', { hospitalDisplayName, hospitalZone })}</p>
    </Container>
  );
}

const Container = styled.div(() => [
  tw`bg-teal-10 border-l-8 border-teal-70 my-4 p-8 rounded-lg shadow-md`,
]);
