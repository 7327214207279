import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import tw, { styled } from "twin.macro";
import DateInput from "../../layout/DateInput";

export default function DateText({ sendAnswer, answerHistory, options }) {
  const { t } = useTranslation();

  const [initDate, setInitDate] = React.useState(null);
  const [error, setError] = React.useState("");

  const validateDate = (date) => {
    let result = false;
    result = moment(date).isValid();
    return result;
  };

  const handleInputChange = (date) => {
    setError("");
    const valid = validateDate(date);

    if (!valid) {
      setError(t("datetext.error"));
      return;
    }

    const dateStr = moment(date)
      .format("YYYY-MM-DD")
      .toString();
    sendAnswer(dateStr);
  };

  React.useEffect(() => {
    if (answerHistory) {
      const dateObj = moment(answerHistory);
      setInitDate(dateObj);
    }
    
  }, []);

  return (
    <div>
      <div className="w-full md:w-1/2">
        <StyledDateInput
          startDate={initDate}
          sendDate={(date) => handleInputChange(date)}
        />
        <p className="error-text">{error}</p>
      </div>

      { options.image !== ""
        && <Img src={options.image} alt={options.alt_text} />}
    </div>
  );
}

const Img = styled.img(() => [
  tw`mx-auto object-contain py-4`,
]);

const StyledDateInput = styled(DateInput)(() => [
  tw`form-input block w-full`,
  tw`border-gray-500 hover:border-blue-100`,
]);
