import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Card from '../../layout/Card';
import { Patient } from '../../../../patient-app-common/types/Patient';
import { useAppSelector } from '../../../../redux/hooks';

interface PreopCardProps {
  patient: Patient;
  surgeryDate: moment.Moment | null;
  toggleModal: () => void;
}

export default function PreopCard({ patient, surgeryDate, toggleModal }: PreopCardProps) {
  const { t } = useTranslation('home');
  const { appVariant } = useAppSelector((state) => state.app);

  const {
    hasPeriop,
    hasPostop,
    patientsCanChangeSurgeryDate,
    procedureName,
    timezone,
  } = patient;

  const preopOnly = !hasPeriop && !hasPostop;
  const canChangeSurgeryDate = patientsCanChangeSurgeryDate && !preopOnly;

  const hasSurgeryDate = !_.isEmpty(surgeryDate);
  const daysToSurgery = moment(surgeryDate)
    .diff(moment.tz(timezone)
      .startOf('day'), 'days');

  const title = hasSurgeryDate ?
    t('preopcard.title_date', { procedureName, date: moment(surgeryDate).format('LL') })
    : t('preopcard.title_nodate', { procedureName });

  return (
    <div
      aria-labelledby="preop-title"
      aria-describedby="preop-body"
      data-test="phase-card"
      className="py-4"
      role="dialog"
    >
      <Card
        headerComp={(
          <>
            <h2 className="font-bold" role="status" id="preop-title">
              {title}
            </h2>
          </>
        )}
        bodyComp={(
          <>
            <p id="preop-body">
              {hasSurgeryDate && (
                <span>
                  {t('preopcard.countdown', { count: daysToSurgery })}
                </span>
              )}
              {' '}{t('preopcard.description')}
            </p>

            {canChangeSurgeryDate && (
              <button
                className="pt-8 text-blue-100 text-left"
                onClick={toggleModal}
              >
                {t('preopcard.set_surgerydate', { context: appVariant })}
              </button>
            )}
          </>
        )}
      />
    </div>
  );
}
