import { useCallback, useMemo } from 'react';
import _ from 'lodash';
import { useGetTrackerInstancesQuery } from '../api/trackerApi';

export default function useTrackers() {
  const {
    data,
    isLoading,
    isFetching,
    error,
  } = useGetTrackerInstancesQuery(undefined, {
    // image urls expire after 30 min, so refresh cache after 30 min
    refetchOnMountOrArgChange: 1800,
  });

  const surveyEntries = useMemo(() => {
    if (!data || error) return null;

    // sort by oldest to newest
    return _.orderBy(data, ['createdAt'], ['asc']);
  }, [data, error]);

  const getEntriesBySurveyId = useCallback((id: number) => {
    if (!surveyEntries) return null;
    return surveyEntries.filter((entry) => (entry.procedureId === id));
  }, [surveyEntries]);

  const getSurveyEntryById = useCallback((id: number) => {
    if (!surveyEntries) return null;
    return surveyEntries.find((entry) => (entry.id === id));
  }, [surveyEntries]);

  return {
    isLoading,
    isFetching,
    surveyEntries,
    getEntriesBySurveyId,
    getSurveyEntryById,
  };
}
