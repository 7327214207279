import React from 'react';
import { useTranslation } from 'react-i18next';
import tw, { styled } from 'twin.macro';

export default function QuestionFooter({
  backHandler,
  index,
  nextHandler,
  showBack,
  review,
}) {
  const { t } = useTranslation();

  return (
    <footer className="pb-16">
      {index === 0 && (
        <div className="flex justify-center">
          <Btn
            className="btn-primary"
            onClick={nextHandler}
          >
            {t('common.action.start')}
          </Btn>
        </div>
      )}

      {index !== 0 && (
        <div className="flex justify-center w-full">
          {showBack && (
            <Btn
              className="btn-secondary"
              onClick={backHandler}
            >
              {t('common.action.back')}
            </Btn>
          )}
          <Btn
            className="btn-primary"
            onClick={nextHandler}
          >
            {!review ? t('common.action.next') : t('common.action.submit')}
          </Btn>
        </div>
      )}
    </footer>
  );
}

const Btn = styled.button(() => [
  tw`text-lg md:text-2xl mx-4 py-2 px-4 rounded shadow`,
  tw`w-full md:w-1/2 lg:w-1/4`,
]);
