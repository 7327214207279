import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { BiChevronDown } from 'react-icons/bi';
import useSegment from '../../../hooks/useSegment';

const EVENT = 'Progress chart all entries viewed';
const SHOW_EVENT = 'Progress chart show more entries';

export default function ImgList({ question }) {
  const { t } = useTranslation();
  const { sendTrackEvent } = useSegment();
  const { pathname } = useLocation();

  const entries = _.filter(question.patientEntries, ({ answer }) => !_.isEmpty(answer))
    .sort((a, b) => moment(b.date) - moment(a.date));

  const [count, setCount] = useState(5);
  const entriesToShow = _.orderBy(entries.slice(0, count));

  const eventData = {
    id: question.id,
    pathname,
    procedureId: question.procedureId,
    procedureName: question.surveyName,
    questionType: question.type,
    title: question.title,
  };

  useEffect(() => {
    sendTrackEvent(EVENT, { ...eventData, timeFrame: 'all' });
  }, []);

  const showMore = () => {
    if (!entries.length) return;

    if (count + 1 <= entries.length) {
      setCount(count + 5);
    } else {
      setCount(entries.length);
    }
    sendTrackEvent(SHOW_EVENT, eventData);
  };

  return (
    <>
      {entries.length > 0 && (
        <h3 className="font-semibold text-lg mb-4">
          {question.chartTitle}
        </h3>
      )}
      {entriesToShow.map(({ answer, date }) => {
        const formattedDate = moment(date).format('LL');
        return (
          <figure className="py-4 flex-none">
            <figcaption className="font-semibold mb-2">{formattedDate}</figcaption>
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img style={{ maxHeight: '300px' }} src={answer} />
          </figure>
        );
      })}
      {entries.length > entriesToShow.length && (
        <button
          className="text-sm text-blue-100"
          onClick={showMore}
        >
          {t('common.action.show_more')}
          <BiChevronDown className="inline-flex" />
        </button>
      )}
    </>
  );
}
