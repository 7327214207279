import React, { useEffect } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import Login from './components/login/Login';
import Setup from './components/login/Setup';
import SignupAccess from './components/login/SignupAccess';
import RequestPassword from './components/login/RequestPassword';
import ResetPassword from './components/login/ResetPassword';
import AutoAuth from './components/login/AutoAuth';
import TaskAuth from './components/login/TaskAuth';
import Home from './components/home/Home';
import SetDelivery from './components/home/prompts/SetDelivery';
import SetDischarge from './components/home/prompts/SetDischarge';
import Todos from './components/todo/Todos';
import AppointmentList from './components/appointments/AppointmentList';
import EduList from './components/education/EduList';
import EduTopic from './components/education/EduTopic';
import ProgressTabs from './components/progress/ProgressTabs';
import TrackerTabs from './components/progress/trackers/TrackerTabs';
import Inbox from './components/inbox/Inbox';
import Settings from './components/settings/Settings';
import Result from './components/survey/result/Result';
import ResultCharts from './components/survey/result/ResultCharts';
import OpioidResult from './components/survey/opioid/OpioidResult';
import Demo from './components/demo/Demo';
import JsonView from './components/demo/JsonView';
import { NoMatch } from './components/layout';
import ProviderExit from './components/survey/layout/ProviderExit';
import SurveyProvider from './components/survey/SurveyProvider';
import useSegment from './hooks/useSegment';
import useSetupPrompt from './hooks/useSetupPrompt';
import Verify from './components/login/Verify';


const validRoutes = [
  '/login',
  '/home',
  '/discharge',
  '/maternity',
  '/todos',
  '/appointments',
  '/education',
  '/education/:componentID',
  '/progress',
  '/progress/survey/:trackerID/',
  '/inbox',
  '/profile',
  '/recommendation',
  '/tracker',
  '/resultcharts',
  '/opioidrisk',
  '/demo',
];

const autoAuthRoutes = [
  '/auth_auto/:token/:eventId?',
  '/provider_tracker/:token/:surveyId/:eventId?',
];

export default function AppRouter() {
  const { providerId, admin } = useSelector((state) => state.auth);
  const { pathname } = useLocation();
  const { trackPage } = useSegment();

  useSetupPrompt();

  useEffect(() => {
    trackPage(pathname);
  }, [pathname]);


  const providerSurveyMode = providerId && !admin

  if (providerSurveyMode) {
    return <ProviderSurveyRouter />;
  }

  return <PatientRouter pathname={pathname} />;
}

function PatientRouter({ pathname }) {
  const { 
    token,
    mfaChecked,
    mfaMethod,
  } = useSelector(({ auth }) => auth);

  const authenticated = token && (!mfaMethod || mfaChecked);

  return (
    <Routes>
      {autoAuthRoutes.map((path) => (
        <Route key={path} path={path} element={<AutoAuth />} />
      ))}

      {!token && (
        <>
          <Route path="/auto_tasks/" element={<TaskAuth />} />
          <Route path="/" element={<Login />} />
          <Route path="/request_password" element={<RequestPassword />} />
          <Route path="/reset_password_form/:token" element={<ResetPassword />} />
          <Route path="/setup/:accessKey/:setupToken" element={<Setup />} />
          <Route path="/signup" element={<SignupAccess />} />
          {validRoutes.map((path) => (
            <Route key={path} path={path} element={<Navigate to="/" replace />} />
          ))}
          <Route path="*" element={<NoMatch />} />
        </>
      )}

      {token && !authenticated && (
        <Route path="*" element={<Verify />} />
      )}

      {authenticated && (
        <>
          {['/login', '/signup'].map((path) => (
            <Route key={path} path={path} element={<Navigate to="/" replace />} />
          ))}
          <Route path="/setup/:accessKey/:setupToken" element={<Setup />} />
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/maternity" element={<SetDelivery />} />
          <Route path="/discharge" element={<SetDischarge />} />
          <Route path="/todos" element={<Todos />} />
          <Route path="/appointments" element={<AppointmentList />} />
          <Route path="/education" element={<EduList />} />
          <Route path="/education/:componentID" element={<EduTopic />} />
          <Route path="/progress/survey/:trackerID/" element={<TrackerTabs />} />
          <Route path="/progress" element={<ProgressTabs />} />
          <Route path="/inbox" element={<Inbox />} />
          <Route path="/profile" element={<Settings />} />
          <Route path="/tracker/:surveyId?" element={<SurveyProvider key={pathname} />} />
          <Route path="/recommendation/:surveyId" element={<Result />} />
          <Route path="/resultcharts/:surveyId" element={<ResultCharts />} />
          <Route path="/opioidrisk" element={<OpioidResult />} />
          <Route path="/demo" element={<Demo />} />
          <Route path="/json" element={<JsonView />} />
          <Route path="*" element={<NoMatch />} />
        </>
      )}
    </Routes>
  );
}

function ProviderSurveyRouter({ pathname }) {
  return (
    <Routes>
      {autoAuthRoutes.map((path) => (
        <Route key={path} path={path} element={<AutoAuth />} />
      ))}
      <Route path="/tracker/:surveyId?/:token?" key={pathname} element={<SurveyProvider />} />
      <Route path="*" element={<ProviderExit />} />
    </Routes>
  );
}
