import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Card from '../../layout/Card';
import { useGetLastTrackerQuery } from '../../../../patient-app-common/api/trackerApi';

interface DailyResultProps {
  mainTrackerId: number
}

export default function DailyResult({ mainTrackerId }: DailyResultProps) {
  const { t } = useTranslation('home');

  const { data: mainTrackerEntry } = useGetLastTrackerQuery(mainTrackerId);

  if (!mainTrackerEntry?.result) return null;
  const { result } = mainTrackerEntry;

  const date = moment(mainTrackerEntry?.createdAt).format('LL');
  const icon = `assets/icons/${result.apptext.recIconImage}`;

  return (
    <div
      aria-labelledby="dailyresult-title"
      className="py-4"
      role="dialog"
    >
      <Card
        date={t('common.text.completed_date', { date })}
        icon={icon}
        color={result.apptext.recRecommendationBackgroundColor}
        headerComp={<h2 className="font-bold" id="dailyresult-title">{result.apptext.recLabelText}</h2>}
        bodyComp={(
          <Link
            to={`/recommendation/${mainTrackerEntry.procedureId}`}
            state={{ patientEntry: mainTrackerEntry }}
            className="inline-block btn-secondary text-center w-full my-4"
          >
            {t('dailyresult.button.open')}
          </Link>
        )}
      />
    </div>
  );
}
