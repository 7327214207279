import React, { useMemo } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import tw, { css, styled } from 'twin.macro';
import { NavBar } from '../layout';
import { useGetPatientQuery } from '../../patient-app-common/api/patientApi';

export default function AppointmentList() {
  const { t } = useTranslation();

  const { data} = useGetPatientQuery();

  const appointments = useMemo(() => {
    if (!data) return [];
    return data.appointments;
  }, [data]);

  const mainRef = React.useRef<HTMLDivElement | null>(null);

  const handleURLClick = (url: string) => {
    window.open(url, '_blank');
  };

  const getFilteredAppts = (filterStr: 'active' | 'past') => {
    if (filterStr === 'active') {
      return appointments.filter((appt) => {
        const active = moment()
          .startOf('day')
          .isSameOrBefore(appt.startAt, 'd');
        return active;
      });
    }

    if (filterStr === 'past') {
      return appointments.filter((appt) => {
        const past = moment()
          .startOf('day')
          .isAfter(appt.startAt, 'd');
        return past;
      });
    }

    return [];
  };

  const [filter, setFilter] = React.useState('active');
  const [filteredAppts, setFilteredAppts] = React.useState(getFilteredAppts('active'));

  const handleTabClick = (filterStr: 'active' | 'past') => {
    setFilter(filterStr);
    const tabAppts = getFilteredAppts(filterStr);
    setFilteredAppts(tabAppts);
  };

  React.useEffect(() => {
    const lastApptDate = _.last(appointments)?.startAt;
    const hasActiveAppts = moment()
      .startOf('day')
      .isSameOrBefore(lastApptDate, 'd');

    if (!hasActiveAppts) {
      setFilter('past');
      const tabAppts = getFilteredAppts('past');
      setFilteredAppts(tabAppts);
    }
  }, []);

  return (
    <div className="bg-gray-100 h-full min-h-screen">
      <NavBar skipHandler={() => mainRef.current && mainRef.current.focus()} />
      <div
        aria-label={t('common.link.appointments')}
        className="pt-16 md:pt-24"
        role="region"
        tabIndex={0}
        ref={mainRef}
      >
        <H1>{t('common.link.appointments')}</H1>
        <TabBar
          role="tablist"
        >
          <TabBtn
            aria-selected={filter === 'active'}
            onClick={() => handleTabClick('active')}
            role="tab"
            selected={filter === 'active'}
          >
            Upcoming
          </TabBtn>

          <TabBtn
            aria-selected={filter === 'past'}
            onClick={() => handleTabClick('past')}
            role="tab"
            selected={filter === 'past'}
          >
            Past
          </TabBtn>
        </TabBar>
        <Alert>
          <p>
            If any of the appointment times or details are different from what your health care provider communicated, please contact
            <a href="mailto:support@seamless.md">
              <span className="font-bold text-blue-100"> support@seamless.md </span>
            </a>
            and we can help investigate.
          </p>
        </Alert>
        <Container>
          {filteredAppts.length > 0 ? (
            <Table>
              <tbody>
                {filteredAppts.map((appointment) => {
                  const date = moment(appointment.startAt).format('llll');

                  return (
                    <Row>
                      <th scope="row" className="border-none p-4 py-8">{appointment.title}</th>
                      <TD>{appointment.details}</TD>
                      <TD>{date}</TD>
                      <TD>
                        {appointment.videoVisit ? (
                          <button
                            className="btn-primary"
                            onClick={() => handleURLClick(appointment.url)}
                          >
                            Start video visit
                          </button>
                        ) : null
                        }
                      </TD>
                    </Row>
                  );
                })}

              </tbody>
            </Table>
          ) : (
            <H3>No appointments to show</H3>
          )}
        </Container>
      </div>
    </div>
  );
}

const Alert = styled.div(() => [
  tw`bg-teal-10 border-l-8 border-teal-70 m-4 p-8 rounded-lg shadow-md`,
]);

const H1 = styled.h1(() => [
  tw`px-12`,
  tw`text-xl sm:text-2xl`,
]);

const TabBar = styled.div(() => [
  tw`border-b border-gray-500 p-4`,
]);

const Container = styled.div(() => [
  tw`flex justify-center w-full`,
  tw`pt-8`,
]);

const Table = styled.table(() => [
  tw`border-none text-black text-left w-full`,
  tw`mx-0 md:mx-8`,
  tw`text-sm md:text-base`,
  css`border-collapse: separate; border-spacing: 0rem 1rem;`,
]);

const Row = styled.tr(() => [
  tw`bg-white`,
  // tw`border border-gray-500 p-4`,
  tw`shadow-lg`,
]);

const TD = styled.td(() => [
  tw`border-none whitespace-pre-line`,
]);

const TabBtn = styled.button(({ selected }: { selected: boolean }) => [
  tw`pt-4 px-8 font-bold`,
  selected ? tw`text-blue-100` : tw`text-gray-700`,
  tw`hover:text-blue-100`,
]);

const H3 = styled.h3(() => [
  tw`font-light text-gray-600`,
  tw`text-lg sm:text-xl`,
]);
