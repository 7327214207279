import * as React from 'react';
import useHospitalFlags from '../../../hooks/useHospitalFlags';

export default function FormContainer({ children }) {
  const { hospital } = useHospitalFlags();

  return (
    <div className="bg-gray-100 h-full min-h-screen">
      <div className="flex justify-center py-8">
        <div className="bg-white border border-gray-500 w-full lg:w-1/2 text-center rounded shadow">
          <div
            className="items-center align-center flex justify-center p-4"
            role="banner"
          >
            {hospital?.logo && <img className="w-1/4" alt="logo" src={hospital.logo} />}
          </div>
          {children}
        </div>
      </div>
    </div>
  );
}
