/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Card from './Card';
import useHospitalFlags from '../../../hooks/useHospitalFlags';
import { getURLParam } from '../../../utils/utils';

export default function OTALink({ integration, hospitalDisplayName }) {
  const location = useLocation();
  const { hasOTALink } = useHospitalFlags();
  const { route } = useSelector(({ app }) => app);

  let link = `${route}/integration/identos/niagarahealthnavigator/start`;
  const client = getURLParam(location.search, 'client');

  if (client) {
    link += `?client=${client}`;
  }

  const [visible, setVisible] = React.useState(true);

  if (!visible) {
    return null;
  }

  if (location.search.includes('first_ota_auth=true')) {
    return (
      <div className="py-8">
        <Card
          headerComp={(
            <h2 className="font-bold text-lg">Your Ontario trusted account has been linked!</h2>
          )}
          bodyComp={(
            <div>
              <div>
                <p>You can now login with your Ontario trusted account or your SeamlessMD account.</p>
                <div className="flex justify-center">
                  <button
                    className="btn-primary w-3/4 block text-center mt-4"
                    onClick={() => setVisible(false)}
                  >
                    OK, I have read this
                  </button>
                </div>
              </div>
            </div>
          )}
        />
      </div>
    );
  }

  if (hasOTALink && !integration?.ontarioTrustedAccount) {
    return (
      <div className="py-8">
        <Card
          headerComp={(
            <h2 className="font-bold text-lg">Opt to connect to your Ontario Trusted Account</h2>
          )}
          bodyComp={(
            <div>
              <div>
                <p>
                  You can choose to login to SeamlessMD with an Ontario Trusted Account, which allows a smoother sign in experience from your mobile device.
                  This account uses your <strong>financial, online banking login</strong> to verify your identity and works well for people who are also using other digital health applications at {hospitalDisplayName}.
                  This is completely <strong> optional,</strong> and you can still enroll/log in with your email address and password.
                </p>
                <div className="flex justify-center">
                  <a href={link} className="flex w-full justify-center">
                    <div
                      className="btn-primary w-3/4 block text-center mt-4"
                    >
                      Link my account
                    </div>
                  </a>
                </div>
              </div>
            </div>
          )}
        />
      </div>
    );
  }

  return null;
}
