import React from 'react';
import { useTranslation } from 'react-i18next';
import Panel from '../../layout/Panel';

export default function Caregiver({ contacts }) {
  const { t } = useTranslation();

  if (!contacts.length) return null;

  return (
    <Panel title={t('common.text.caregivers')}>
      {contacts.length > 0 && (
        <table className="border-none text-left w-full">
          <thead>
            <tr className="bg-blue-10">
              <th className="border-none text-left w-1/3" scope="col">
                {t('common.text.name')}
              </th>
              <th className="border-none text-left w-1/3" scope="col">
                {t('common.text.email')}
              </th>
              <th className="border-none text-left w-1/3" scope="col">
                {t('common.text.phone')}
              </th>
            </tr>
          </thead>
          <tbody>
            {contacts.map((contact, i) => {
              const color = (i % 2 === 0) ? 'bg-white' : 'bg-blue-10';
              const emailStr = contact.email ? contact.email : t('common.text.not_provided');
              const phoneStr = contact.phone ? contact.phone : t('common.text.not_provided');

              return (
                <tr className={color} key={contact.id}>
                  <th className="border-none text-left" scope="row">
                    {`${contact.firstName} ${contact.lastName}`}
                  </th>
                  <td className="border-none">{emailStr}</td>
                  <td className="border-none">{phoneStr}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </Panel>
  );
}
