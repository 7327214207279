import React, { useContext, useMemo, useState } from 'react';
import { Link, useMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import tw, { css, styled } from 'twin.macro';
import {
  BiHomeAlt,
  BiCalendarEvent,
  BiChat,
  BiListCheck,
  BiTrendingUp,
  BiBookOpen,
  BiUser,
  BiHelpCircle,
  BiMenu,
} from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import { ActionCableContext } from '../../context/ActionCableContext';
import { logout } from '../../patient-app-common/slices/authSlice';
import NavDemo from './NavDemo';
import useAppEvents from '../../patient-app-common/hooks/useAppEvents';
import useLocale from '../../hooks/useLocale';
import { useGetPatientQuery } from '../../patient-app-common/api/patientApi';

const NavBar = ({ skipHandler }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { patientLocale } = useLocale();

  const {
    activeTodos,
    futureTodos,
    todoCount,
  } = useAppEvents();

  const { data: patient } = useGetPatientQuery();
  const { inboxCount } = useContext(ActionCableContext);

  const [toggle, setToggle] = useState(false);

  const showTodosTab = useMemo(() => {
    if (activeTodos.length || futureTodos.length) {
      return true;
    }

    return false;
  }, [activeTodos, futureTodos]);

  const handleLogoutClick = (e) => {
    e.preventDefault();
    storage.removeItem('persist:root-v1');
    dispatch(logout());
  };

  const openHelp = () => {
    const url = `https://ca.seamless.md/education/241780?lang=${patientLocale}`;
    window.open(url, '_blank');
  };

  if (!patient) return null;

  let { procedureLogo } = patient;
  procedureLogo = procedureLogo ?? '/assets/images/seamlessMD-logo.png';

  return (
    <>
      <ParentContainer>
        <NavContainer>
          <div className="flex-1" role="banner">
            <Logo src={procedureLogo} alt={patient.hospitalDisplayName} />
          </div>

          <SkipBtn onClick={() => skipHandler()}>
            {t('common.link.skip')}
          </SkipBtn>

          <button
            onClick={() => setToggle(!toggle)}
            className="lg:hidden block"
          >
            <span aria-label={t('common.layout.menu')} className="text-3xl">
              <BiMenu />
            </span>
          </button>

          <Menu toggle={toggle}>
            <NavDemo patient={patient} />
            <nav>
              <ul className="lg:flex items-center justify-between space-x-2">
                <li className="ml-2 relative">
                  <CustomLink activeOnlyWhenExact to="/">
                    <BiHomeAlt className="text-xl" />
                    {t('common.link.home')}
                  </CustomLink>
                </li>
                {showTodosTab && (
                  <li className="relative flex items-center">
                    <CustomLink to="/todos">
                      <BiListCheck className="text-xl" />
                      {t('common.link.todo')}
                    </CustomLink>
                    {todoCount > 0 && <Badge>{todoCount}</Badge>}
                  </li>
                )}
                {patient.appointments?.length > 0 && (
                  <li>
                    <CustomLink to="/appointments">
                      <BiCalendarEvent className="text-xl" />
                      {t('common.link.appointments')}
                    </CustomLink>
                  </li>
                )}
                <li>
                  <CustomLink
                    activeStyle={{ color: '#044F89' }}
                    to="/education"
                  >
                    <BiBookOpen className="text-xl" />
                    {t('common.link.library')}
                  </CustomLink>
                </li>
                <li>
                  <CustomLink to="/progress">
                    <BiTrendingUp className="text-xl" />
                    {t('common.link.progress')}
                  </CustomLink>
                </li>
                {patient.secureMessagingEnabled && (
                  <li className="relative flex items-center">
                    <CustomLink to="/inbox">
                      <BiChat className="text-xl" />
                      {t('common.link.inbox')}
                    </CustomLink>
                    {inboxCount > 0 && <Badge>{inboxCount}</Badge>}
                  </li>
                )}
                <li>
                  <CustomLink to="/profile">
                    <BiUser className="text-xl" />
                    {t('common.link.profile')}
                  </CustomLink>
                </li>
                <li className="lg:hidden">
                  <StyledLink onClick={openHelp} to="#">
                    <BiHelpCircle className="text-xl" />
                    {t('common.link.help')}
                  </StyledLink>
                </li>
              </ul>
            </nav>
          </Menu>
        </NavContainer>

        <div>
          <Btn className="btn-secondary" collapse onClick={openHelp}>
            {t('common.text.help')}
          </Btn>

          <Btn
            className="btn-primary"
            onClick={handleLogoutClick}
          >
            {t('common.action.logout')}
          </Btn>
        </div>
      </ParentContainer>
    </>
  );
};

// https://reactrouter.com/web/example/custom-link
function CustomLink({ children, to, activeOnlyWhenExact }) {
  const match = useMatch({
    path: to,
    exact: activeOnlyWhenExact,
  });

  return (
    <StyledLink selected={match} to={to}>
      {children}
    </StyledLink>
  );
}

const ParentContainer = styled.div(() => [
  tw`bg-white border-b border-blue-20 inline-flex flex shadow w-full`,
  tw`lg:pl-16 pl-6`,
  css`
    height: 3.875rem;
  `,
  tw`fixed z-50`,
]);

const NavContainer = styled.div(() => [
  tw`bg-white flex-1 flex flex-wrap items-center`,
]);

const SkipBtn = styled.button(() => [
  tw`px-4 text-white`,
  tw`focus:text-black`,
  tw`hidden md:block`,
]);

const Menu = styled.div(({ toggle }) => [
  tw`lg:flex lg:w-auto w-full z-50`,
  toggle
    && tw`
    block
    bg-white lg:bg-transparent
    border border-blue-20 lg:border-none
    shadow lg:shadow-none
  `,
  !toggle && tw`hidden`,
]);

const Logo = styled.img(() => [
  tw`object-contain`,
  css`
    width: 8rem;
  `,
]);

const StyledLink = styled(Link)(({ selected }) => [
  tw`block border-b-2 border-transparent max-w-xs`,
  tw`px-0 py-3 lg:p-4`,
  tw`hover:border-blue-100`,
  tw`inline-flex`,
  selected && tw`border-blue-100`,
]);

const Btn = styled.button(({ collapse }) => [
  tw`px-4 rounded mt-2 mx-1`,
  collapse && tw`hidden lg:inline`,
  css`
    height: 3rem;
  `,
]);

const Badge = styled.div(() => [
  tw`lg:absolute z-10 top-1/10 ml-2 inline-flex right-1/10 lg:right-0`,
  tw`bg-red`,
  tw`rounded-full`,
  tw`w-4 h-4`,
  tw`justify-center items-center text-xs`,
  tw`text-white`,
]);

export default NavBar;
