import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const GenScale = ({
  data, sendAnswer, answerHistory, options,
}) => {
  const { t } = useTranslation();

  const initAnswer = answerHistory !== undefined ? answerHistory : -1;
  const [answer, setAnswer] = React.useState(initAnswer);

  const minLabel = options.min ? options.min : data[0].text;
  const maxLabel = options.max ? options.max : data[data.length - 1].text;

  const handleBtnClick = (value) => {
    setAnswer(value);
    sendAnswer(value);
  };

  return (
    <div>
      {_.isEmpty(options?.image) && (
        <div className="flex justify-center mb-20">
          <div className="bg-teal-100 flex h-48 items-center justify-center rounded-full w-48">
            <p
              aria-label={
                answer >= 0
                  ? `${t('common.text.selection')} ${data[answer].text}`
                  : ''
              }
              className="text-8xl text-white"
            >
              {answer >= 0 ? data[answer].text : ''}
            </p>
          </div>
        </div>
      )}

      <div className="flex justify-center">
        <div className="inline-flex lg:w-3/4 w-full justify-between">
          <div className="py-1">
            <p>{minLabel}</p>
          </div>
          <div className="py-1">
            <p>{maxLabel}</p>
          </div>
        </div>
      </div>

      <div className="flex justify-center">
        <div className="border border-gray-500 inline-flex rounded shadow lg:w-3/4 w-full">
          { data.map((item, index) => (
            <button
              key={item.text}
              onClick={() => handleBtnClick(index)}
              className={`border-2 border-white hover:bg-teal-30 rounded px-1 sm:py-5 py-1 ${answer === index ? 'bg-teal-30' : 'bg-white'
              }`}
              style={{ width: `${100 / data.length}%` }}
            >
              {item.text}
            </button>
          ))}
        </div>
      </div>

      {options.image !== '' && (
        <div className="flex justify-center pb-4">
          <img
            className="object-fill md:object-cover lg:w-3/4 w-full"
            src={options.image}
            alt={options.alt_text}
          />
        </div>
      )}
    </div>
  );
};

export default GenScale;
