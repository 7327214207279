import React from 'react';
import moment from 'moment';
import { Reply } from './Reply';
import { MessagePayload } from '../../../patient-app-common/types/SecureMessageThreads';

interface ProviderMsgProps {
  message: MessagePayload,
}

export function ProviderReply({ message }: ProviderMsgProps) {
  return (
    <div className="flex justify-start">
      <div className="flex flex-col">
        <p className="text-sm font-semibold mt-4">{message.sentByName}
          <span className="font-light text-sm ml-4">
            {moment(message.createdAt).format('MMM D hh:mm a')}
          </span>
        </p>
        <Reply message={message} />
      </div>
    </div>
  );
}
