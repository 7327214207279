import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import tw, { styled } from 'twin.macro';
import { BiChevronDown } from 'react-icons/bi';
import { answerToChartLabel } from '../../../patient-app-common/helpers/progress_helper';
import useSegment from '../../../hooks/useSegment';

const EVENT = 'Progress chart all entries viewed';
const SHOW_EVENT = 'Progress chart show more entries';

export default function EntryList({ question, filteredEntries, mode }) {
  const { t } = useTranslation();
  const { sendTrackEvent } = useSegment();
  const { pathname } = useLocation();

  const entries = filteredEntries ? [...filteredEntries] : [...question.patientEntries];
  _.remove(entries, { skipped: true });
  _.remove(entries, ({ answer }) => !_.isNumber(answer) && _.isEmpty(answer));
  entries.sort((a, b) => moment(b.date) - moment(a.date));

  const [count, setCount] = useState(10);
  const entriesToShow = _.orderBy(entries.slice(0, count));

  const eventData = {
    id: question.id,
    pathname,
    procedureId: question.procedureId,
    procedureName: question.surveyName,
    questionType: question.type,
    title: question.title,
  };

  useEffect(() => {
    if (question.type !== 'text') return;
    sendTrackEvent(EVENT, { ...eventData, timeFrame: 'all' });
  }, []);

  const showMore = () => {
    if (!entries.length) return;

    if (count + 1 <= entries.length) {
      setCount(count + 10);
    } else {
      setCount(entries.length);
    }
    sendTrackEvent(SHOW_EVENT, eventData);
  };

  const formatDate = (date) => {
    switch (mode) {
      case 'day':
        return moment(date).format('LLL');

      case 'week':
        return moment(date).format('LL');

      case 'all':
      default:
        return moment(date).format('LL');
    }
  };

  return (
    <>
      {entriesToShow.map(({ answer, date }) => {
        const parsedAnswer = answerToChartLabel(question, answer);
        const formattedDate = formatDate(date);
        return (
          <Div>
            <p className="font-semibold mb-2">
              {formattedDate}
            </p>
            <p className="break-words font-light whitespace-pre-wrap">
              {parsedAnswer} {question.unit}
            </p>
          </Div>
        );
      })}
      {entries.length > entriesToShow.length && (
        <button
          className="text-sm text-blue-100"
          onClick={showMore}
        >
          {t('common.action.show_more')}
          <BiChevronDown className="inline-flex" />
        </button>
      )}
    </>
  );
}

const Div = styled.div(() => [
  tw`bg-white border-gray-500 p-4 rounded-lg shadow-md my-4 w-full`,
]);
