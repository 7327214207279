import { useMemo } from 'react';
import moment from 'moment';
import { useGetPatientQuery } from '../api/patientApi';

export interface PatientDates {
  signupDate: moment.Moment | null;
  preOpDate: moment.Moment | null;
  surgeryDate: moment.Moment | null;
  dischargeDate: moment.Moment | null;
  completedDate: moment.Moment | null;
}

// sets date to hospital timezone
function dateInHospitalTz(dateInMs: number | null, timezone: string) {
  if (!dateInMs) return null;

  return moment(dateInMs).tz(timezone);
}

export default function usePatientDates(options = { skip: false }) {
  const {
    data,
    error,
  } = useGetPatientQuery(undefined, { skip: options.skip });

  const patientDates = useMemo(() => {
    if (!data || error) return null;

    const {
      createdAt,
      preOpDate,
      surgeryDate,
      dischargeDate,
      completedDate,
      timezone,
    } = data;
 
    return {
      signupDate: dateInHospitalTz(createdAt, timezone),
      preOpDate: dateInHospitalTz(preOpDate, timezone),
      surgeryDate: dateInHospitalTz(surgeryDate, timezone),
      dischargeDate: dateInHospitalTz(dischargeDate, timezone),
      completedDate: dateInHospitalTz(completedDate, timezone),
    };
  }, [data, error]);

  const surgeryDate = useMemo(() => {
    if (!patientDates) return null;
    return patientDates.surgeryDate;
  }, [patientDates]);

  const dischargeDate = useMemo(() => {
    if (!patientDates) return null;
    return patientDates.dischargeDate;
  }, [patientDates]);

  const sameTimeZone = useMemo(() => {
    if (!data || error) return null;

    const { timezone } = data;
    const hospitalZone = moment.tz(timezone).zoneAbbr();
    const userZone = moment.tz(moment.tz.guess()).zoneAbbr();

    return hospitalZone === userZone;
  }, [data, error]);

  return {
    patientDates,
    surgeryDate,
    dischargeDate,
    sameTimeZone,
  };
}
