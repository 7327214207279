import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import tw, { styled } from 'twin.macro';
import useHospitalFlags from '../../../hooks/useHospitalFlags';
import { getURLParam } from '../../../utils/utils';


const MOH_LOGO = 'assets/icons/moh.png';

export default function OTALoginCard() {
  const location = useLocation();
  const { route } = useSelector(({ app }) => app);
  const { hospital } = useHospitalFlags();

  let link = `${route}/integration/identos/niagarahealthnavigator/start`;
  const client = getURLParam(location.search, 'client');

  if (client) {
    link += `?client=${client}`;
  }

  if (!hospital) return null;

  return (
    <div className="w-full">
      <div className="flex flex-col sm:flex-row justify-center place-items-center">
        <img
          style={{ height: '80px', maxWidth: '200px' }}
          alt={hospital.displayName}
          src={hospital.logo}
        />
        <img
          src={MOH_LOGO}
          alt="Government of Ontario"
          style={{ height: '80px' }}
        />
      </div>
      <a href={link}>
        <Btn>

          <span className="ml-2 place-self-center">
            Login with your Ontario trusted account
          </span>
        </Btn>
      </a>
      <Text>Your consent is required to continue</Text>
      <div className="relative py-4">
        <div className="absolute inset-0 flex items-center">
          <div className="w-full border-b border-gray-600" />
        </div>
        <div className="relative flex justify-center">
          <span className="bg-white px-4 text-sm text-gray-600">or</span>
        </div>
      </div>
    </div>
  );
}

const Btn = styled.div(() => [
  tw`border border-black p-2 rounded text-sm`,
  tw`justify-center inline-flex w-full`,
]);

const Text = styled.p(() => [
  tw`pt-2 text-center text-gray-600 text-sm`,
]);
