import React from 'react';
import _ from 'lodash';
import tw, { styled } from 'twin.macro';
import { useTranslation } from 'react-i18next';
import { BsDot, BsSquare, BsSquareFill } from 'react-icons/bs';

// this helper converts the answer from the survey instance to an array of selected question choices
// for checkbox: [true, false, true, false] is converted to [Option 1, Option 3]
// for radio: 0 is converted to [Option 1]
function selectedAnswersArray(chartQuestion, patientEntry) {
  switch (chartQuestion.type) {
    case 'checkbox':
      if (_.isArray(patientEntry)) {
        const selectedList = _.filter(chartQuestion.answers, (_checkbox, i) => patientEntry[i]);
        return _.map(selectedList, 'chartLabel');
      }
      break;

    case 'radio': {
      const index = _.toNumber(patientEntry);
      if (_.isNumber(index)) {
        return [chartQuestion.answers[index].chartLabel];
      }
    }
      break;

    default:
      return [];
  }
}

export default function Table({ question, entries }) {
  const { t } = useTranslation('progress');

  const checkboxValue = (questionItem, entryIndex) => {
    const entryForDay = entries[entryIndex];

    if (entryForDay.skipped) {
      return (
        <BsDot
          aria-label={t('common.text.no_entry')}
          className="text-gray-500"
        />
      );
    }

    const selectedItems = selectedAnswersArray(question, entryForDay.answer);

    if (selectedItems.includes(questionItem)) {
      return (
        <BsSquareFill
          aria-label={t('table.checkbox_true')}
          className="text-2xl text-blue-100"
        />
      );
    }
    return (
      <BsSquare
        aria-label={t('table.checkbox_false')}
        className="text-2xl text-blue-100"
      />
    );
  };

  return (
    <div className="overflow-x-scroll" data-test="table-component">
      <StyledTable>
        <thead>
          <tr className="bg-blue-10">
            <TH className="border-none" scope="col" />
            { entries.map((entry) => (
              <TH
                scope="col"
                key={entry.date}
              >
                {entry.dateLabel}
              </TH>
            ))}
          </tr>
        </thead>
        <tbody>
          {question.answers.map((answer, i) => {
            if (question.type === 'checkbox' && i === question.answers.length - 1) {
              return null;
            }
            const questionItem = answer.chartLabel;
            return (
              <tr className="even:bg-blue-10" key={questionItem}>
                <TH scope="row">
                  <span className="font-semibold">
                    {questionItem}
                  </span>
                </TH>
                {_.times(entries.length, (entryIndex) => (
                  <TD key={`${questionItem}-${entryIndex}`}>
                    {checkboxValue(questionItem, entryIndex)}
                  </TD>
                ))}
              </tr>
            );
          })}
        </tbody>
      </StyledTable>
    </div>
  );
}

const StyledTable = styled.table(() => [
  tw`border-none text-left w-full table-auto`,
  tw`text-sm`,
]);

const TH = styled.th(() => [
  tw`border-none font-light p-2`,
]);

const TD = styled.td(() => [
  tw`border-none p-2`,
]);
