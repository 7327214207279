import React from 'react';
import tw, { styled } from 'twin.macro';

export default function RadioBtn({
  data, sendAnswer, answerHistory, options,
}) {
  const [answer, setAnswer] = React.useState(answerHistory);

  const handleInputChange = (key) => {
    setAnswer(parseInt(key, 10));
    sendAnswer(parseInt(key, 10));
  };

  return (
    <div>
      <AnswersContainer>
        {data.map((item) => (
          <AnswerDiv
            key={item.key}
            selected={parseInt(item.key, 10) === answer}
            onClick={() => handleInputChange(item.key)}
          >
            <div className="px-5">
              <Input
                id={item.key}
                name={item.key}
                type="radio"
                checked={parseInt(item.key, 10) === answer}
                onChange={() => handleInputChange(item.key)}
              />
            </div>
            <div>
              <label htmlFor={item.key} className="font-bold">
                {item.text}
              </label>
            </div>
          </AnswerDiv>
        ))}
      </AnswersContainer>
      {options.image !== '' && (
        <Img src={options.image} alt={options.alt_text} />
      )}
    </div>
  );
}

const AnswersContainer = styled.div(() => [
  tw`border-gray-500 divide-y shadow`,
]);

const AnswerDiv = styled.div(({ selected }) => [
  tw`cursor-pointer flex p-3`,
  selected ? tw`bg-teal-30` : tw`bg-white`,
  tw`hover:bg-teal-30`,
]);

const Input = styled.input(() => [
  tw`form-radio h-4 text-blue-100 w-4`,
  tw`border-2 border-black`,
]);

const Img = styled.img(() => [
  tw`mx-auto object-contain py-4`,
]);
