import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import tw, { styled } from 'twin.macro';
import _ from 'lodash';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import LineChart from './LineChart';
import EntryList from '../EntryList';
import useSegment from '../../../../hooks/useSegment';

const EVENT = 'Progress chart date changed';

export default function DailyData({ question }) {
  const { t } = useTranslation();
  const { sendTrackEvent } = useSegment();
  const { pathname } = useLocation();

  const [startOfDay, setStartOfDay] = useState(moment().startOf('day'));

  const trackNavigation = (direction, date) => {
    const eventData = {
      id: question.id,
      direction,
      pathname,
      procedureId: question.procedureId,
      procedureName: question.surveyName,
      questionType: question.type,
      startDate: moment(date).format('YYYY-MM-DD').toString(),
      timeFrame: 'day',
      title: question.title,
    };

    sendTrackEvent(EVENT, eventData);
  };

  const getPrevDay = () => {
    const prevDay = moment(startOfDay).subtract(1, 'days');
    setStartOfDay(prevDay);
    trackNavigation('back', prevDay);
  };

  const disableBack = useMemo(() => {
    const { patientEntries } = question;
    const firstEntryDate = _.first(patientEntries).date;
    return moment(firstEntryDate).isSameOrAfter(startOfDay, 'day');
  }, [startOfDay, question]);

  const getNextDay = () => {
    const nextDay = moment(startOfDay).add(1, 'days');
    setStartOfDay(nextDay);
    trackNavigation('next', nextDay);
  };

  const disableNext = useMemo(() => moment().isSame(startOfDay, 'day'), [startOfDay]);

  const entriesForDay = useMemo(() => {
    const { patientEntries } = question;
    const data = _.filter(patientEntries, ({ date }) => moment(date).isSame(startOfDay, 'day'));

    if (_.isEmpty(data)) {
      data.push({
        date: startOfDay,
        answer: 0,
        skipped: true,
      });
    }

    return data;
  }, [startOfDay, question]);

  return (
    <>
      <h3 className="text-sm my-4">{moment(startOfDay).format('LL')}</h3>
      <div className="inline-flex gap-4">
        <Highlight disabled={disableBack} onClick={() => getPrevDay()}>
          <BiChevronLeft className="inline-flex" />
          {t('common.action.back')}
        </Highlight>
        <Highlight disabled={disableNext} onClick={() => getNextDay()}>
          {t('common.action.next')}
          <BiChevronRight className="inline-flex" />
        </Highlight>
      </div>
      <LineChart
        question={question}
        filteredEntries={entriesForDay}
        mode="day"
      />
      <EntryList
        question={question}
        filteredEntries={entriesForDay}
        mode="day"
      />
    </>
  );
}

const Highlight = styled.button(({ disabled }) => [
  tw`text-sm text-blue-100`,
  disabled && tw`text-gray-400`,
]);
