import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LoaderInline from '../layout/LoaderInline';
import FormContainer from './layout/FormContainer';
import { getURLParam } from '../../utils/utils';
import NoMatch from '../layout/NoMatch';
import { useReadEventMutation } from '../../patient-app-common/api/patientApi';
import { useAppSelector } from '../../redux/hooks';

export default function TaskAuto() {
  const { t } = useTranslation('login');
  const [readEvent, { isLoading, isSuccess, isError }] = useReadEventMutation();

  const { route } = useAppSelector(({ app }) => app);

  const location = useLocation();
  const eventId = getURLParam(location.search, 'application_event_id');
  const title = getURLParam(location.search, 'title');
  const token = getURLParam(location.search, 'token');

  const submitReadEvent = async () => {
    const params = {
      token,
      eventId,
    };

    await readEvent(params);
  };

  useEffect(() => {
    if (route === null || !eventId || !token) return;
    submitReadEvent();
  }, [eventId, route, token]);

  if (!eventId) {
    return <NoMatch />;
  }

  return (
    <FormContainer>
      <div className="p-4">
        <h1 className="text-2xl">{title}</h1>

        <div className="p-4">
          {isLoading && <LoaderInline /> }

          {!isLoading && isSuccess && (
            <p>{t('taskauth.response_success')}</p>
          )}

          {!isLoading && isError && (
            <div>
              <p>{t('common.error.server')}</p>
            </div>
          )}
        </div>

        <Link
          className="font-bold text-blue-100 text-lg hover:underline"
          to="/login"
        >
          {t('common.action.login')}
        </Link>

      </div>
    </FormContainer>
  );
}
