import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isIE } from 'react-device-detect';
import tw, { css, styled } from 'twin.macro';
import {
  BiChevronRightCircle,
  BiChevronLeftCircle,
} from 'react-icons/bi';
import { EducationTree, treeSortEducation } from '../../../patient-app-common/helpers/education_helpers';
import { Education } from '../../../patient-app-common/types/Education';

interface EduDrawerProps {
  componentID: string;
  collapse: boolean;
  education: Education[];
  skipHandler: () => void;
  toggleCollapse: (collapse: boolean) => void;
}

export default function EduDrawer({
  componentID,
  collapse,
  education,
  skipHandler,
  toggleCollapse,
}: EduDrawerProps) {
  const { t } = useTranslation();
  const linkRef = useRef(null);

  const topicsTree = treeSortEducation(education, null);

  const getList = (components: EducationTree[], padding = 0) => {
    const list = components.map((component) => {
      const selected = (componentID === component.id.toString());
      const isParent = component.html === '' || !component.parentId;

      let subtopics: EducationTree[] = [];

      if (component.children) {
        subtopics = [...component.children];
      }

      const className = `pl-${padding} block`;

      return (
        <li key={component.id}>
          <StyledLink
            key={component.id}
            to={`/education/${component.id}`}
            isParent={isParent}
            selected={selected}
            collapse={collapse}
            ref={selected ? linkRef : null}
          >
            <span className={className}>{component.title}</span>
          </StyledLink>
          { subtopics.length
            ? (
              <ul className="">
                { getList(subtopics, (4 + padding)) }
              </ul>
            )
            : null}
        </li>
      );
    });
    return list;
  };

  return (
    <ParentContainer role="navigation">
      <Container collapse={collapse}>
        <SkipBtn
          aria-hidden={collapse}
          collapse={collapse}
          onClick={() => skipHandler()}
        >
          {t('common.link.skip')}
        </SkipBtn>

        {!isIE && (
          <BtnContainer collapse={collapse}>
            <span className="text-4xl text-gray-600">
              { collapse
                ? (
                  <BiChevronRightCircle
                    aria-label={t('edudrawer.expandbtn.arialabel')}
                    onClick={() => toggleCollapse(false)}
                  />
                )
                : (
                  <BiChevronLeftCircle
                    aria-label={t('edudrawer.hidebtn.arialabel')}
                    onClick={() => toggleCollapse(true)}
                  />
                )}
            </span>
          </BtnContainer>
        )}

        <Ul aria-hidden={collapse}>
          { getList(topicsTree) }
        </Ul>

      </Container>
    </ParentContainer>
  );
}

const ParentContainer = styled.div(() => [
  tw`flex-col h-screen float-left fixed`,
  css`
    max-height: -webkit-fill-available;
  `,
]);

const SkipBtn = styled.button(({ collapse }: { collapse: boolean }) => [
  tw`font-bold p-4 text-white text-xl w-full`,
  !collapse && tw`focus:text-black focus:bg-teal-30`,
]);

const Container = styled.div(({ collapse }: { collapse: boolean }) => [
  tw`bg-white flex-col h-screen justify-start overflow-scroll`,
  tw`border-gray-500 border-b-2 border-r-2`,
  tw`w-0 md:w-sidedrawer md:min-w-sidedrawer`,
  collapse && tw`w-10 md:w-10 min-w-0 md:min-w-0`,
  css`
    transition: 0.3s;
    max-height: -webkit-fill-available;
  `,
]);

const Ul = styled.ul(() => [
  css`padding-bottom: 8rem`,
]);

const StyledLink = styled(Link)(({ isParent, selected, collapse }: {
  isParent: boolean;
  selected: boolean;
  collapse: boolean;
}) => [
  tw`block p-1`,
  isParent && tw`font-bold`,
  !isParent && tw`font-normal `,
  selected && !collapse && tw`bg-teal-30`,
  collapse ? tw`text-white` : tw`text-black hover:bg-teal-10`,
]);

const BtnContainer = styled.button(({ collapse }: { collapse: boolean }) => [
  tw`absolute border-2 border-gray-600 bg-gray-100 rounded-full p-1 z-20`,
  tw`hover:bg-teal-30`,
  tw`invisible md:visible`,
  css`top: 0.5rem;`,
  collapse ? css`right: -1.5rem` : css`right: -1rem;`,
]);
