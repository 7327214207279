import React, { ChangeEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';
import AccountForm from './AccountForm';
import FormContainer from './layout/FormContainer';
import LoaderInline from '../layout/LoaderInline';
import useSegment from '../../hooks/useSegment';
import { useAccountFormMutation } from '../../patient-app-common/api/authApi';
import { FormField } from '../../patient-app-common/types/Auth';

const IMAGE = `${process.env.PUBLIC_URL}/assets/images/accesskey.png`;
const EVENT = 'Signup Start button clicked';

export default function Signup() {
  const { i18n, t } = useTranslation('login');
  const { sendTrackEvent } = useSegment();
  const [accountForm, { isLoading }] = useAccountFormMutation();

  const errorDiv = useRef<HTMLDivElement>(null);

  const [error, setError] = useState(false);
  const [accessKey, setAccessKey] = useState('');
  const [languageField, setLanguageField] = useState<null | FormField>(null);
  const [languageSelected, setLanguageSelected] = useState(true);
  const [form, setForm] = useState<FormField[]>([]);

  const getSignupForm = async (token: string) => {
    try {
      const results = await accountForm({ token }).unwrap();
      setForm(results);

      const languages = _.find(results, ['fieldCode', 'language_id']);
      if (languages) {
        setLanguageField(languages);
        setLanguageSelected(false);
      }
    } catch (e) {
      setError(true);
      errorDiv.current && errorDiv.current.focus();
    }
  };

  const handleBtnPress = () => {
    getSignupForm(accessKey);
  };

  const handleLocaleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (!languageField) return;

    const languageFieldCopy = { ...languageField };
    languageFieldCopy.value = e.target.value;
    setLanguageField(languageFieldCopy);

    const locale = e.target[e.target.selectedIndex]
      .getAttribute('data-locale');

    if (!locale) return;

    moment.locale(locale);
    i18n.changeLanguage(locale);
    document.documentElement.lang = locale;
  };

  const formWithSelectedLanguage = () => {
    const formCopy: FormField[] = [...form];

    if (!languageField?.value) return;

    _.remove(formCopy, (field) => field.languageId && field.languageId !== Number(languageField.value));
    _.remove(formCopy, (field) => field.fieldCode === 'language_id');

    const languageFieldCopy = { ...languageField };
    languageFieldCopy.visible = false;

    formCopy.push(languageFieldCopy);
    setForm(formCopy);
    setLanguageSelected(true);
  };

  if (languageSelected && form.length > 0) {
    return (
      <AccountForm
        fields={form}
        token={accessKey}
      />
    );
  }

  const LangFragment = () => (
    <>
      <h1 className="text-lg">
        {t('common.select.language')}
      </h1>
      <div className="flex justify-center p-4">
        <select
          name="lng"
          id="lng"
          defaultValue={languageField?.value}
          onChange={(e) => handleLocaleChange(e)}
          className="form-select w-3/4"
        >
          {!_.isEmpty(languageField) && languageField.options.map((lng) => (
            <option
              data-locale={lng[2]}
              key={lng[1]}
              value={lng[1]}
            >
              {lng[0]}
            </option>
          ))}
        </select>
      </div>

      <div className="p-4">
        <button
          onClick={() => formWithSelectedLanguage()}
          className="btn-primary w-1/2 md:w-1/4"
        >
          {t('common.action.next')}
        </button>
      </div>
    </>
  );

  const KeyFragment = () => (
    <>
      <h1 className="text-lg p-4 text-left">
        {t('signupaccess.description')}
      </h1>
      {error && (
        <div ref={errorDiv} role="alert" tabIndex={0}>
          <p
            className="error-text p-2 text-xs md:text-sm"
          >
            {t('signupaccess.error')}
          </p>
        </div>
      )}

      <div className="flex justify-center p-4">
        <input
          type="text"
          name="access"
          value={accessKey}
          onChange={(e) => setAccessKey(e.target.value)}
          className="block form-input mt-1 w-3/4 md:w-1/2"
        />
      </div>

      <div className="p-4">
        <button
          onClick={() => {
            handleBtnPress();
            sendTrackEvent(EVENT, { selfSignup: true });
          }}
          className="btn-primary w-1/2 md:w-1/4"
        >
          {t('common.action.start')}
        </button>
      </div>

      {isLoading && (
        <div className="text-center">
          <LoaderInline />
        </div>
      )}
    </>
  );

  return (
    <FormContainer>
      <div className="flex justify-center p-4">
        <img className="h-32 w-32" src={IMAGE} alt="" />
      </div>
      {!form.length && KeyFragment()}

      {!languageSelected && LangFragment()}
    </FormContainer>
  );
}
