import React from 'react';
import { useTranslation } from 'react-i18next';
import tw, { styled } from 'twin.macro';
import { FaFileUpload, FaImage } from 'react-icons/fa';
import Compressor from 'compressorjs';

export default function Camera({ questionID, sendAnswer, answerHistory }) {
  const { t } = useTranslation('survey');
  const [imageURL, createImageURL] = React.useState('');

  const previewSavedImage = (file) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      createImageURL(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const [skipped, checkSkipBtn] = React.useState(false);

  React.useEffect(() => {
    if (answerHistory !== undefined) {
      if (answerHistory.type) {
        previewSavedImage(answerHistory);
        checkSkipBtn(false);
      } else {
        checkSkipBtn(true);
      }
    }
  }, [questionID, answerHistory]);

  const handleInputChange = () => {
    checkSkipBtn(true);
    createImageURL('');
    sendAnswer(false);
  };

  const handleImageChange = async (e) => {
    checkSkipBtn(false);
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    new Compressor(file, {
      quality: 0.6,
      success(result) {
        sendAnswer(result);
        previewSavedImage(result);
      },
      error(err) {
        console.log(err.message);
      },
    });
  };

  const getImagePreview = () => {
    if (imageURL) {
      return (<img src={imageURL} alt={t('common.text.attachment')} />);
    }
    return (
      <PlaceholderDiv
        aria-hidden="true"
      >
        <span className="text-6xl text-gray-600"><FaImage /></span>
      </PlaceholderDiv>
    );
  };

  return (
    <div>
      <div className="flex justify-center">
        <ParentContainer>
          <UploadDiv>
            <UploadLabel>
              <span className="text-6xl">
                <FaFileUpload />
              </span>
              <span className="my-2 text-xl font-bold">
                {t('camerainput.button.gallery')}
              </span>
              <input
                id="upload"
                type="file"
                accept="image/jpeg,image/png"
                onChange={(e) => handleImageChange(e)}
                // className="hidden"
                style={{ paddingLeft: '64px' }}
              />
            </UploadLabel>
          </UploadDiv>
          <SkipDiv onClick={() => handleInputChange()}>
            <label htmlFor="skip">
              <Input
                id="skip"
                name="skip"
                type="radio"
                checked={skipped}
                onChange={() => handleInputChange()}
              />
              <span className="ml-4 text-xl">
                {t('camerainput.button.skip')}
              </span>
            </label>
          </SkipDiv>
          <ImgContainer>{getImagePreview()}</ImgContainer>
        </ParentContainer>
      </div>
    </div>
  );
}

const ParentContainer = styled.div(() => [
  tw`flex flex-col w-full md:w-1/2 items-center`,
]);

const UploadDiv = styled.div(() => [
  tw`bg-white border border-gray-400 rounded shadow w-full`,
  tw`hover:bg-teal-30`,
]);

const UploadLabel = styled.label(() => [
  tw`cursor-pointer flex flex-col items-center p-4`,
]);

const SkipDiv = styled.div(({ skipped }) => [
  tw`border border-gray-400 cursor-pointer flex p-3 rounded shadow w-full`,
  tw`hover:bg-teal-30`,
  skipped ? tw`bg-teal-30` : tw`bg-white`,
]);

const Input = styled.input(() => [
  tw`form-radio h-6 text-blue-100 text-xl w-6`,
]);

const ImgContainer = styled.div(() => [
  tw`h-auto py-4 w-full`,
]);

const PlaceholderDiv = styled.div(() => [
  tw`border border-dashed border-gray-600 p-10 rounded`,
  tw`flex h-full justify-center`,
]);
