import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import tw, { styled } from 'twin.macro';
import Panel from '../layout/Panel';
import { useUpdateProfileMutation } from '../../patient-app-common/api/patientApi';

export default function DemoProfile({ initProfile }) {
  const [updateProfile, { isSuccess, isError }] = useUpdateProfileMutation();
  const [profile, setProfile] = useState(_.cloneDeep(initProfile));

  const handleValueSelect = (e, keyId) => {
    const profileCopy = _.cloneDeep(profile);
    profileCopy[keyId].wasUpdated = true;
    profileCopy[keyId].value = e.target.value;
    setProfile(profileCopy);
  };

  const params = useMemo(() => {
    const keysToUpdate = {};

    for (const key in profile) {
      if (profile[key].wasUpdated) {
        keysToUpdate[key] = profile[key].value;
      }
    }

    return keysToUpdate;
  }, [profile]);

  const handleSubmit = async () => {
    if (_.isEmpty(params)) return;
    await updateProfile(params);
  };

  const profileKeys = Object.keys(profile).map((i) => {
    const keyName = profile[i].key;
    const selectedValue = profile[i].value;
    const { keyId } = profile[i];
    const { options } = profile[i];
    const notSet = !options.includes(selectedValue);

    return (
      <KeyRow key={keyId}>
        <label
          className="flex flex-col justify-center"
          htmlFor={keyId}
        >
          {keyName}
        </label>

        <select
          name={keyName}
          id={keyId}
          defaultValue={notSet ? '' : selectedValue}
          onChange={(e) => handleValueSelect(e, keyId)}
          className="form-select text-sm w-full sm:w-1/3"
        >
          <option value="" disabled>Select an option</option>
          { options.map((option) => (
            <option key={option} value={option}>{option}</option>
          ))}
        </select>
      </KeyRow>
    );
  });

  return (
    <Panel title="Set patient profile">
      <p className="px-0 sm:px-12 pb-4">You can view and modify the patient profile here.</p>
      <div className="flex flex-col justify-center">
        {profileKeys}

        <button
          className="btn-primary mx-auto my-4"
          onClick={handleSubmit}
        >
          Set profile
        </button>
      </div>

      {isSuccess && (
        <p className="italic text-center">
          Your profile has been updated!
        </p>
      )}

      {isError && (
        <p className="error-text text-center">
          An error occurred. Try again later.
        </p>
      )}
    </Panel>
  );
}

const KeyRow = styled.div(() => [
  tw`border-b border-gray-300 flex justify-between py-2`,
  tw`flex-col sm:flex-row`,
  tw`px-0 sm:px-12`,
  tw`hover:bg-teal-20`,
]);
