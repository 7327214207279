import _ from 'lodash';
import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Card from '../layout/Card';
import useModal from '../../../hooks/useModal';
import useSegment from '../../../hooks/useSegment';
import EduModal from '../../education/EduModal';
import { useReadEventMutation } from '../../../patient-app-common/api/patientApi';

const EVENT = 'Education Event Viewed';

export default function EduEvent({
  avatar,
  disableRead = false,
  event,
  index,
  size,
}) {
  const { t } = useTranslation('home');
  const { visible, toggleModal } = useModal();
  const { sendTrackEvent } = useSegment();
  const [readEvent] = useReadEventMutation();

  const handleReadClick = async (event) => {
    try {
      await readEvent({ eventId: event.id }).unwrap();
      sendTrackEvent(EVENT, { id: [event.id], title: [event.title] });
    } catch (e) {
      console.log(e);
    }
  };

  const isSticky = disableRead || event.shouldPersistAfterReading;

  return (
    <>
      <EduModal
        event={event}
        source="EduEvent"
        toggleModal={toggleModal}
        visible={visible}
      />
      <article
        aria-labelledby={`title-${event.id}`}
        aria-describedby={`body-${event.id}`}
        aria-posinset={index + 1}
        aria-setsize={size}
        className="py-4"
      >
        <Card
          date={moment(event.startDateTime).format('LL')}
          icon={avatar}
          headerComp={(
            <h3 className="font-bold" id={`title-${event.id}`}>
              {event.title}
            </h3>
          )}
          bodyComp={(
            <div>
              <p className="whitespace-pre-line" id={`body-${event.id}`}>
                {event.body}
              </p>
              <div className="flex justify-center pt-4 text-center">
                <div className="flex-col w-3/4">
                  {!_.isEmpty(event.edIds) && !_.isEmpty(event.options.linkText) && (
                    <button
                      data-test="edu-btn"
                      onClick={toggleModal}
                      className="btn-secondary w-full"
                    >
                      {event.options.linkText}
                    </button>
                  )}
                  {!isSticky && (
                    <button
                      data-test="read-btn"
                      onClick={() => handleReadClick(event)}
                      className="block btn-primary w-full my-4"
                    >
                      {t('eduevent.button.read')}
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        />
      </article>
    </>
  );
}
