import React from 'react';
import tw, { styled } from 'twin.macro';

export default function Checkbox({
  data, sendAnswer, answerHistory, options,
}) {
  const initAnswer = answerHistory || new Array(data.length)
    .fill(false);
  const [answers, setAnswer] = React.useState(initAnswer);

  const handleInputChange = (key) => {
    const answersClone = [...answers];

    if (Number(key) === data.length - 1) {
      answersClone.fill(false);
    } else {
      answersClone[data.length - 1] = false;
    }

    answersClone[key] = !answersClone[key];
    setAnswer(answersClone);
    sendAnswer(answersClone);
  };

  return (
    <div>
      <AnswersContainer>
        {data.map((item) => (
          <label key={item.key} htmlFor={item.key} className="font-bold">
            <AnswerDiv key={item.key} selected={answers[item.key]}>
              <div className="px-5">
                <Input
                  id={item.key}
                  name={item.key}
                  type="checkbox"
                  checked={answers[item.key]}
                  onChange={() => handleInputChange(item.key)}
                />
              </div>
              <div>
                <p className="font-bold">{item.text}</p>
              </div>
            </AnswerDiv>
          </label>
        ))}
      </AnswersContainer>
      {options.image !== '' && (
        <Img src={options.image} alt={options.alt_text} />
      )}
    </div>
  );
}

const AnswersContainer = styled.div(() => [
  tw`divide-y shadow`,
]);

const AnswerDiv = styled.div(({ selected }) => [
  tw`cursor-pointer flex p-3 `,
  selected ? tw`bg-teal-30` : tw`bg-white`,
  tw`hover:bg-teal-30`,
]);

const Input = styled.input(() => [
  tw`form-checkbox h-4 text-blue-100 w-4`,
  tw`border-2 border-black`,
]);

const Img = styled.img(() => [
  tw`mx-auto object-contain py-4`,
]);
