import React, { useEffect } from 'react';
import tw, { css, styled } from 'twin.macro';
import { useTranslation } from 'react-i18next';
import useSegment from '../../hooks/useSegment';

const image = `${process.env.PUBLIC_URL}/assets/images/loading.png`;
const EVENT = 'An error caused a crash';

export default function Crash() {
  const { t } = useTranslation();
  const { sendTrackEvent } = useSegment();

  useEffect(() => {
    sendTrackEvent(EVENT, { pathname: window.location.hash });
  }, []);

  return (
    <Container>
      <TextColumn>
        <H1>{t('common.layout.crash')}</H1>
        <Btn
          onClick={(e) => {
            window.location = 'mailto:support@seamless.md';
            e.preventDefault();
          }}
          className="btn-primary"
        >
          {t('common.link.help')}
        </Btn>
      </TextColumn>
      <ImgColumn>
        <img alt="" src={image} />
      </ImgColumn>
    </Container>
  );
}

const H1 = styled.h1(() => [
  tw`font-bold text-gray-700`,
  tw`text-4xl sm:text-5xl`,
]);

const Container = styled.div(() => [
  tw`bg-white h-screen`,
  tw`flex flex-col md:flex-row`,
  css`
    max-height: -webkit-fill-available;
  `,
]);

const TextColumn = styled.div(() => [
  tw`items-center p-12`,
  tw`flex-none md:flex flex-col justify-center`,
  tw`w-full md:w-1/2`,
]);

const ImgColumn = styled.div(() => [
  tw`w-full md:w-1/2`,
  tw`flex-none md:flex items-center`,
]);

const Btn = styled.button(() => [
  tw`inline-flex flex justify-center`,
  tw`text-center space-x-8 m-4`,
]);
