import React from 'react';
import { useTranslation } from 'react-i18next';
import { ImSpinner8 } from 'react-icons/im';

export default function LoaderInline() {
  const { t } = useTranslation();

  return (
    <div aria-busy="true" className="inline-flex pt-2" role="status">
      <span className="text-xl text-blue-100 px-2">
        <ImSpinner8 className="animate-spin" />
      </span>
      <p className="">{t('common.text.loading')}</p>
    </div>
  );
}
