import * as React from 'react';
import EndSurveyCard from '../surgical/EndSurveyCard';
import { Patient } from '../../../../patient-app-common/types/Patient';

interface ChronicProgramProps {
  patient: Patient,
  programPhase: undefined | string,
}

export default function ChronicProgram({
  patient,
  programPhase,
}: ChronicProgramProps) {
  switch (programPhase) {
    case 'end':
      return (
        <EndSurveyCard
          endSurveyId={patient.endSurveyId}
          endSurveyCompleted={patient.endSurveyCompleted}
        />
      );
    default:
      return null;
  }
}
