import React from 'react';
import { useTranslation } from 'react-i18next';
import { isAndroid, isIOS } from 'react-device-detect';
import tw, { css, styled } from 'twin.macro';
import useHospitalFlags from '../../../hooks/useHospitalFlags';
import useLocale from '../../../hooks/useLocale';

const appleEN = '/assets/icons/Download_on_the_App_Store_Badge.svg';
const googleEN = '/assets/icons/Google_Play_Store_badge_EN.svg';

const appleFR = '/assets/icons/Download_on_the_App_Store_Badge_FRCA_RGB_blk.svg';
const googleFR = '/assets/icons/Google_Play_Store_badge_FR.svg'

const { hostname } = window.location;
let appLink = 'https://apps.apple.com/ca/app/seamlessmd/id696595041';
let playLink = 'https://play.google.com/store/apps/details?id=md.seamless.app';
if (hostname.includes('holland')) {
  appLink = 'https://apps.apple.com/ca/app/myhip-knee/id1005212811';
  playLink = 'https://play.google.com/store/apps/details?id=md.myhipknee.app'
}

export default function BadgeFooter() {
  const { t } = useTranslation();
  const { hideMobileLink } = useHospitalFlags();
  const { patientLocale } = useLocale();

  const [assets, setAssets] = React.useState({ apple: appleEN, google: googleEN });

  React.useEffect(() => {
    if (patientLocale === 'fr') {
      setAssets({
        apple: appleFR,
        google: googleFR,
      })
    }
  }, [patientLocale]);

  if (hideMobileLink) {
    return null;
  }

  return (
    <Container>
      <p className="font-light text-sm sm:text-base">
        {t('common.text.download_mobile')}
      </p>

      <div className="flex justify-center">
        {!isAndroid &&
          <a
            href={appLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ImgDiv>
              <AppleBadge src={assets.apple} alt="App store" />
            </ImgDiv>
          </a>
        }

        {!isIOS &&
          <a
            href={playLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ImgDiv>
              <GoogleBadge src={assets.google} alt="Google play" />
            </ImgDiv>
          </a>
        }
      </div>
    </Container>
  );
}

const Container = styled.footer(() => [
  tw`border-t border-gray-500 py-4 text-center w-full`,
]);

const ImgDiv = styled.div(() => [
  tw`p-2`,
]);

const AppleBadge = styled.img(() => [
  css`width: 10rem;`,
]);

const GoogleBadge = styled.img(() => [
  css`width: 10rem;`,
]);
