import React from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import tw, { css, styled } from 'twin.macro';
import { useAppSelector } from '../../../redux/hooks';

export default function SurveyRecord({ editable, handleCancelClick, lastEntryDate }) {
  const { t } = useTranslation('survey');
  const navigate = useNavigate();

  const { demo } = useAppSelector(({ auth }) => auth);

  const date = moment(lastEntryDate).format('LLLL');
  const redoBtn = demo || editable;

  return (
    <div className="bg-gray-100 h-screen">
      <div className="flex justify-center pt-12">
        <Card>
          <h1 className="pt-16 text-lg md:text-2xl">{t('surveyrecord.title', { date })}</h1>
          <div className="flex justify-center p-16 whitespace-pre-line">
            <Text>
              {t('surveyrecord.description_exit')}
              {'\n'}
              {redoBtn && t('surveyrecord.description_continue')}
            </Text>
          </div>

          {redoBtn && (
            <Btn
              className="btn-secondary"
              onClick={() => handleCancelClick()}
            >
              {t('common.button.survey_start')}
            </Btn>
          )}

          <Btn
            className="btn-primary"
            onClick={() => navigate('/')}
          >
            {t('common.action.exit_session')}
          </Btn>
        </Card>
      </div>
    </div>
  );
}

const Card = styled.div(() => [
  tw`bg-white border border-gray-500 h-auto text-center rounded shadow`,
  tw`w-full lg:w-3/4`,
  css`min-height:32rem`,
]);

const Text = styled.p(() => [
  tw`text-left p-4`,
  tw`text-base md:text-lg`,
]);

const Btn = styled.button(() => [
  tw`m-2`,
  tw`w-3/4 md:w-1/4`,
]);
