import React from 'react';
import _ from 'lodash';
import tw, { styled } from 'twin.macro';
import { useTranslation } from 'react-i18next';
import { BiChevronUp, BiChevronDown } from 'react-icons/bi';

export default function Medicine({
  answerHistory,
  data,
  medicineHistory,
  options,
  sendAnswer,
}) {
  const { t } = useTranslation('survey');

  let initAnswer = Array.from({ length: data.length }).map(() => ({}));

  if (answerHistory) {
    initAnswer = answerHistory;
  } else if (medicineHistory) {
    const medProfile = JSON.parse(medicineHistory);

    data.forEach((item, i) => {
      const key = item.options.medicineId;
      if (medProfile[key]) {
        initAnswer[i] = medProfile[key];
      }
    });
  }

  const [answers, setAnswer] = React.useState(initAnswer);
  const [errors, setErrors] = React.useState(new Array(data.length).fill(false));

  React.useEffect(() => {
    if (!errors.includes(true)) {
      sendAnswer(answers);
    } else {
      sendAnswer(null);
    }
  
  }, [answers]);

  const handleCheckbox = (selected, i) => {
    const answersClone = [...answers];
    const errorsClone = [...errors];

    if (!selected) {
      answersClone[i] = {};
      errorsClone[i] = false;
    } else {
      errorsClone[i] = true;
    }

    setErrors(errorsClone);
    setAnswer(answersClone);
  };

  const handleSelect = (key, value, i) => {
    const answersClone = [...answers];
    const errorsClone = [...errors];

    const medicine = answersClone[i];
    medicine[key] = value;

    if (Object.keys(medicine).length < 2) {
      errorsClone[i] = true;
    } else {
      errorsClone[i] = false;
    }

    setErrors(errorsClone);
    setAnswer(answersClone);
  };

  return (
    <div>
      <AnswersContainer>
        {data.map((item, i) => (
          <CheckboxPanel
            key={item.text}
            handleCheckbox={(value) => handleCheckbox(value, i)}
            index={i}
            title={item.text}
            checked={!_.isEmpty(answers[i])}
          >
            <div className="py-2 pl-12">
              { errors[i] && (
                <p className="error-text pb-4">
                  {t('common.error.blank_input')}
                </p>
              )}

              <label
                className="flex flex-col justify-center"
                htmlFor={`${item.text}-dose`}
              >
                {t('medicine.input.dose')}
              </label>

              <Select
                aria-required={!answers[i].dose}
                aria-label={`${item.text}-dose`}
                name={`${item.text}-dose`}
                id={`${item.text}-dose`}
                value={answers[i].dose ?? ''}
                onChange={(e) => handleSelect('dose', e.target.value, i)}
                error={!answers[i].dose}
              >
                <option value="" disabled>{t('common.select.placeholder')}</option>
                {item.options.doses.map((option) => (
                  <option key={option} value={option}>{option}</option>
                ))}
              </Select>
            </div>

            <div className="py-2 pl-12">
              <label
                className="flex flex-col justify-center"
                htmlFor={`${item.text}-pills`}
              >
                {t('medicine.input.count')}
              </label>

              <Select
                aria-required={!answers[i].pills}
                aria-label={`${item.text}-pills`}
                name={`${item.text}-pills`}
                id={`${item.text}-pills`}
                value={answers[i].pills ?? ''}
                onChange={(e) => handleSelect('pills', e.target.value, i)}
                error={!answers[i].pills}
              >
                <option value="" disabled>{t('common.select.placeholder')}</option>
                {_.range(1, 21).map((option) => (
                  <option key={option} value={option}>{option}</option>
                ))}
              </Select>
            </div>
          </CheckboxPanel>
        ))}
      </AnswersContainer>
      {options.image !== '' && (
        <Img src={options.image} alt={options.alt_text} />
      )}
    </div>
  );
}

const AnswersContainer = styled.div(() => [
  tw`divide-y shadow`,
]);

const Img = styled.img(() => [
  tw`mx-auto object-contain py-4`,
]);

const Select = styled.select(({ error }) => [
  tw`form-select w-full sm:w-1/3`,
  error && tw`border-red`,
]);

function CheckboxPanel({
  checked,
  children,
  handleCheckbox,
  index,
  title,
}) {
  const [expand, toggleExpand] = React.useState(checked);
  const divRef = React.useRef(null);

  const handleExpandClick = () => {
    handleCheckbox(!expand);
    toggleExpand(!expand);
    divRef.current.focus();
  };

  return (
    <>
      <CheckBoxDiv key={title} selected={expand} htmlFor={title}>
        <div className="flex justify-start w-3/4">
          <div className="px-5">
            <Checkbox
              aria-controls={index}
              aria-expanded={expand}
              checked={expand}
              expand={expand}
              id={title}
              name={title}
              onChange={() => handleExpandClick()}
              type="checkbox"
            />
          </div>
          <p className="font-bold">{title}</p>
        </div>

        <div className="flex justify-end w-1/4">
          <span className="text-2xl pr-4">
            {expand ? <BiChevronUp /> : <BiChevronDown />}
          </span>
        </div>

      </CheckBoxDiv>
      <ExpandDiv
        role="region"
        id={index}
        expand={expand}
        ref={divRef}
        tabIndex={index}
      >
        <fieldset>
          {children}
        </fieldset>
      </ExpandDiv>
    </>
  );
}

const CheckBoxDiv = styled.label(({ selected }) => [
  tw`cursor-pointer flex p-3 `,
  selected ? tw`bg-teal-30` : tw`bg-white`,
  tw`hover:bg-teal-30`,
]);

const Checkbox = styled.input(() => [
  tw`form-checkbox h-4 text-blue-100 w-4`,
  tw`border-2 border-black`,
]);

const ExpandDiv = styled.div(({ expand }) => [
  tw`bg-white px-8`,
  expand ? tw`block` : tw`hidden`,
]);
