import React from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { BiX } from 'react-icons/bi';
import tw, { css, styled } from 'twin.macro';

interface ModalProps {
  visible: boolean;
  handleCloseClick: () => void;
  component: React.ReactNode;
  showFooter?: boolean;
  width?: string;
}

export default function Modal({
  visible,
  handleCloseClick,
  component,
  showFooter,
  width,
}: ModalProps) {
  const { t } = useTranslation();

  const divRef = React.useRef<HTMLDivElement>(null);
  const customWidth = width || '500px';

  React.useEffect(() => {
    if (visible && divRef.current) {
      divRef.current.focus();
    }
  }, [visible]);

  React.useEffect(() => {
    const handleEsc = (event : KeyboardEvent) => {
      if (event.code === 'Escape') {
        if (visible) {
          handleCloseClick();
        }
      }
    };

    document.addEventListener('keydown', handleEsc);

    return () => {
      document.removeEventListener('keydown', handleEsc);
    };
    
  }, [visible]);

  if (visible) {
    return (
      ReactDOM.createPortal(
        <>
          <Helmet>
            <style>{'body { overflow: hidden; }'}</style>
          </Helmet>
          <ModalOverlay />
          <ModalWrapper aria-modal role="dialog">
            <ModalCard customWidth={customWidth} ref={divRef} tabIndex={0}>
              <Header>
                <CloseBtn type="button" data-dismiss="modal" aria-label={t('common.action.close')} onClick={handleCloseClick}>
                  <span aria-hidden="true"><BiX /></span>
                </CloseBtn>
              </Header>

              {component}

              {showFooter && (
                <Footer>
                  <button
                    onClick={handleCloseClick}
                    className="btn-danger"
                  >
                    {t('common.action.close')}
                  </button>
                </Footer>
              )}
            </ModalCard>
          </ModalWrapper>
        </>, document.body,
      )
    );
  }
  return null;
}

const ModalOverlay = styled.div(() => [
  tw`fixed top-0 left-0`,
  tw`h-full w-full`,
  tw`bg-black opacity-15`,
  css`z-index: 1040;`,
]);

const ModalWrapper = styled.div(() => [
  tw`fixed top-0 left-0`,
  tw`overflow-x-hidden overflow-y-auto`,
  css`
    z-index: 1050;
    width: 100%;
    height: 100%;
    outline: 0;
  `,
  css`&::-webkit-scrollbar {
      display: none;
  }`,
]);

const ModalCard = styled.div(({ customWidth }: { customWidth: string }) => [
  tw`relative rounded py-4 px-8`,
  css`
    background-color: white;
    margin: 1.75rem auto;
    max-width: ${customWidth};
    z-index: 100;
  `,
]);

const Header = styled.div(() => [
  tw`flex justify-end`,
]);

const Footer = styled.div(() => [
  tw`border-t border-gray-400 mt-8 pt-4`,
]);

const CloseBtn = styled.button(() => [
  tw`cursor-pointer text-gray-700 text-3xl`,
  tw`hover:text-red`,
]);
