import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Card from '../../layout/Card';
import { Patient } from '../../../../patient-app-common/types/Patient';

interface PeriopCardProps {
  patient: Patient;
  surgeryDate: moment.Moment | null;
  toggleModal: () => void;
}

export default function PeriopCard({ patient, surgeryDate, toggleModal }: PeriopCardProps) {
  const { t } = useTranslation('home');

  const daysAfterSurgery = moment().diff(surgeryDate, 'days');

  const { hasPeriop } = patient;

  return (
    <div
      aria-labelledby="periop-title"
      aria-describedby="periop-body"
      data-test="edu-event"
      className="py-4"
      role="dialog"
    >
      <Card
        headerComp={(
          <>
            <h2 className="font-bold" role="status" id="periop-title">
              {t('periopcard.countdown', { count: daysAfterSurgery })}
            </h2>
          </>
        )}
        bodyComp={(
          <>
            {hasPeriop && (
              <>
                <p id="periop-body">
                  {t('periopcard.has_periop.description')}
                </p>
                <button
                  className="pt-8 text-blue-100"
                  onClick={toggleModal}
                >
                  {t('periopcard.no_periop.description')}
                </button>
              </>
            )}

            {!hasPeriop && (
              <>
                <p id="periop-body">
                  {t('periopcard.no_periop.description')}
                </p>
                <div className="flex justify-center pt-4">
                  <Link
                    to="/discharge"
                    className="btn-primary inline-block text-center w-3/4"
                  >
                    {t('periopcard.button.set_discharge')}
                  </Link>
                </div>
              </>
            )}
          </>
        )}
      />
    </div>
  );
}
