import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import tw, { styled } from 'twin.macro';
import { useTranslation } from 'react-i18next';
import { FiThumbsUp, FiThumbsDown } from 'react-icons/fi';
import { educationTopic } from '../../../patient-app-common/helpers/education_helpers';
import Modal from '../../layout/Modal';
import useModal from '../../../hooks/useModal';
import useSegment from '../../../hooks/useSegment';
import { Education } from '../../../patient-app-common/types/Education';

interface EduFeedbackProps {
  education: Education[],
  componentID: string;
}

type EduFeedbackParams = {
  id: string;
  title: string[],
  procedureID: number,
  feedback?: string;
};

const EVENT_THUMBS_UP = 'Education Feedback Thumbs Up';
const EVENT_THUMBS_DOWN = 'Education Feedback Thumbs Down';

export default function EduFeedback({ education, componentID }: EduFeedbackProps) {
  const { t } = useTranslation('education');
  const { sendTrackEvent } = useSegment();
  const { visible, toggleModal } = useModal();

  const [data, setData] = React.useState<EduFeedbackParams | null>(null);
  const [selected, setSelected] = useState('');
  const [comment, setComment] = useState('Not useful');

  const handleBtnClick = async (option: string) => {
    setSelected(option);

    if (option === 'no') {
      toggleModal();
    }

    if (option === 'yes') {
      const params = {
        ...data,
        feedback: 'Useful',
      };
      sendTrackEvent(EVENT_THUMBS_UP, params);
    }
  };

  useEffect(() => {
    setSelected('');
    const topic = educationTopic(education, componentID);
    if (!topic) return;

    setData({
      id: componentID,
      title: [topic.title],
      procedureID: topic.procedureId,
    });
  }, [componentID]);

  useEffect(() => {
    if (!data) return;
    if (selected === 'no' && !visible) {
      // Note: since thumbs down was grouped as 1 event, it is sent after the comment modal is closed
      // TODO: discuss whether to divide thumbs down and additional comment as 2 events
      sendTrackEvent(EVENT_THUMBS_DOWN, {
        ...data,
        comment,
      });
    }
  }, [comment, data, selected, visible]);

  return (
    <>
      <Modal
        visible={visible}
        handleCloseClick={toggleModal}
        component={(
          <EduForm
            setComment={(value: string) => setComment(value)}
            toggleModal={toggleModal}
          />
        )}
      />
      <Container>
        <p>
          {t('edufeedback.title')}
        </p>
        <div className="inline-flex">
          <Btn
            selected={selected}
            option="yes"
            onClick={() => handleBtnClick('yes')}
          >
            {t('common.action.yes')}
            <span className="pl-2">
              <FiThumbsUp />
            </span>
          </Btn>
          <div>
            <Btn
              selected={selected}
              option="no"
              onClick={() => handleBtnClick('no')}
            >
              {t('common.action.no')}
              <span className="pl-2">
                <FiThumbsDown />
              </span>
            </Btn>
          </div>
        </div>

        {!_.isEmpty(selected) && (
          <p className="italic text-sm">
            {t('edufeedback.response_success')}
          </p>
        )}
      </Container>
    </>
  );
}

interface EduFormProps {
  setComment: (value: string) => void;
  toggleModal: () => void;
}

function EduForm({ setComment, toggleModal }: EduFormProps) {
  const  { t } = useTranslation('education'); 
  const [text, setText] = React.useState('');
  const [submitted, setSubmitted] = useState(false);

  return (
    <>
      <p className="py-4">
        {t('edufeedback.form.title')}
      </p>

      <textarea
        className="form-textarea w-full"
        maxLength={300}
        onChange={(e) => setText(e.target.value)}
        rows={4}
      />

      <div className="py-4">
        <button
          className="btn-primary"
          onClick={() => {
            setSubmitted(true);
            setComment(`Not useful: ${text}`);
          }}
        >
          {t('common.action.submit')}
        </button>
        <button className="btn-danger mx-2" onClick={toggleModal}>
          {t('common.action.close')}
        </button>
        {submitted && (
          <p className="italic py-2 text-sm">
            {t('edufeedback.response_success')}
          </p>
        )}
      </div>
    </>
  );
}

const Container = styled.div(() => [tw`p-4 text-center`]);

const Btn = styled.button(({ option, selected }: { option: string, selected: string }) => [
  tw`border border-gray-500 inline-flex items-center m-2 p-2 rounded-lg text-sm w-16`,
  tw`text-xs lg:text-sm`,
  tw`hover:bg-teal-10`,
  option === selected && tw`bg-teal-10`,
]);
