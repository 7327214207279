import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import tw, { styled } from 'twin.macro';
import { Appointment } from '../../../patient-app-common/types/Patient';
import { useTranslation } from 'react-i18next';

interface AppointmentCardProps {
  appointments: Appointment[];
}

export default function AppointmentCard({ appointments }: AppointmentCardProps) {
  const { t } = useTranslation('home');

  const count = useMemo(() => {
    if (!appointments?.length) return 0;

    const activeAppts = appointments.filter((appt) => {
      const active = moment()
        .startOf('day')
        .isSameOrBefore(appt.startAt, 'd');
      return active;
    });

    return activeAppts.length;
  }, [appointments]);

  if (!count) {
    return null;
  }

  return (
    <Container>
      <H2>
        {t('home.section.appointment', { count })}
      </H2>
      <div className="pt-4">
        <Link className="btn-primary inline-block" to="/appointments">
          {t('home.button.appointment')}
        </Link>
      </div>
    </Container>
  );
}

const Container = styled.div(() => [
  tw`bg-teal-10 border-l-8 border-teal-70 my-4 p-8 rounded-lg shadow-md`,
]);

const H2 = styled.h2(() => [
  tw`font-bold`,
  tw`text-base sm:text-lg`,
]);
