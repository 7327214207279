import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import tw, { styled } from 'twin.macro';
import _ from 'lodash';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import { labelForWeek } from '../../../../patient-app-common/helpers/progress_helper';
import LineChart from './LineChart';
import EntryList from '../EntryList';
import useSegment from '../../../../hooks/useSegment';

const EVENT = 'Progress chart date changed';

export default function WeeklyLineData({ question }) {
  const { t } = useTranslation();
  const { sendTrackEvent } = useSegment();
  const { pathname } = useLocation();

  const [startOfWeek, setStartOfWeek] = useState(moment().startOf('isoWeek'));

  const trackNavigation = (direction, date) => {
    const eventData = {
      id: question.id,
      direction,
      pathname,
      procedureId: question.procedureId,
      procedureName: question.surveyName,
      questionType: question.type,
      startDate: moment(date).format('YYYY-MM-DD').toString(),
      timeFrame: 'week',
      title: question.title,
    };

    sendTrackEvent(EVENT, eventData);
  };

  const getPrevWeek = () => {
    const prevWeek = moment(startOfWeek).subtract(1, 'weeks');
    setStartOfWeek(prevWeek);
    trackNavigation('back', prevWeek);
  };

  const disableBack = useMemo(() => {
    const { patientEntries } = question;
    const firstEntryDate = _.first(patientEntries).date;
    return moment(firstEntryDate).isSameOrAfter(startOfWeek, 'isoWeek');
  }, [question, startOfWeek]);

  const getNextWeek = () => {
    const nextWeek = moment(startOfWeek).add(1, 'weeks');
    setStartOfWeek(nextWeek);
    trackNavigation('next', nextWeek);
  };

  const disableNext = useMemo(() => moment().isSameOrBefore(startOfWeek, 'isoWeek'), [startOfWeek]);

  const entriesForWeek = useMemo(() => {
    const { patientEntries } = question;
    const data = [];

    for (let i = 0; i <= 6; i += 1) {
      const dayOfWeek = moment(startOfWeek).add(i, 'days');

      // exclude future entries
      if (moment().isBefore(dayOfWeek)) break;

      // note this resets entry date to 12am so that the scatter plot lines up with the x-axis
      const datum = { date: dayOfWeek };

      // if there are multiple entries on the same day, get the last entry
      const entry = _.findLast(patientEntries, ({ date }) => moment(date).isSame(dayOfWeek, 'day'));

      if (entry) {
        datum.answer = entry.answer;
        datum.skipped = false;
      } else {
        datum.skipped = true;
        datum.answer = 0;
      }
      data.push(datum);
    }

    return data;
  }, [startOfWeek, question]);

  return (
    <>
      <h3 className="text-sm my-4">{labelForWeek(startOfWeek)}</h3>
      <div className="inline-flex gap-4">
        <Highlight disabled={disableBack} onClick={() => getPrevWeek()}>
          <BiChevronLeft className="inline-flex" />
          {t('common.action.back')}
        </Highlight>
        <Highlight disabled={disableNext} onClick={() => getNextWeek()}>
          {t('common.action.next')}
          <BiChevronRight className="inline-flex" />
        </Highlight>
      </div>
      <LineChart
        question={question}
        filteredEntries={entriesForWeek}
        mode="week"
      />
      <EntryList
        question={question}
        filteredEntries={entriesForWeek}
        mode="week"
      />
    </>
  );
}

const Highlight = styled.button(({ disabled }) => [
  tw`text-sm text-blue-100`,
  disabled && tw`text-gray-400`,
]);
