import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import tw, { styled } from 'twin.macro';

interface PreopVisitCardProps {
  hospitalDisplayName: string;
  preOpDate: moment.Moment | null | undefined;
  timezone: string;
}

export default function PreopVisitCard({
  hospitalDisplayName,
  preOpDate,
  timezone,
}: PreopVisitCardProps) {
  const { t } = useTranslation('home');

  const daysToPreop = moment(preOpDate).startOf('day')
    .diff(moment.tz(timezone).startOf('day'), 'days');

  const date = moment(preOpDate).format('LL');
  const time = moment(preOpDate).format('h:mm a');

  const getText = () => {
    let text = t('preopvisitcard.countdown', { date, time });
    if (daysToPreop === 0) {
      text = t('preopvisitcard.countdown_0', { time });
    }
    return text;
  };

  if (daysToPreop >= 0) {
    return (
      <Container>
        {getText()}
      </Container>
    );
  }

  return null;
}

const Container = styled.div(() => [
  tw`bg-teal-10 border-l-8 border-teal-70 my-4 p-8 rounded-lg shadow-md`,
]);
