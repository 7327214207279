import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import tw, { styled } from 'twin.macro';
import EduEvent from '../../home/messages/EduEvent';
import useAppEvents from '../../../patient-app-common/hooks/useAppEvents';

export default function PastEventList() {
  const { t } = useTranslation('progress');
  const { completedEvents, eventsWithSchedule } = useAppEvents();
  const { messageEvents } = eventsWithSchedule;

  const completedIDs = completedEvents.map((event) => event.applicationEventId);

  const pastEvents = useMemo(() => {
    if (!messageEvents?.length || !completedIDs?.length) return [];

    return messageEvents
      .filter((event) => event.eventType === 'education')
      .filter((event) => completedIDs.includes(event.id));
  }, [completedIDs, messageEvents]);

  if (!pastEvents.length) {
    return (
      <div className="pt-8">
        <H2 blank>{t('pasteventlist.empty_text')}</H2>
      </div>
    );
  }

  return (
    <div className="pt-8">
      <H2>{t('pasteventlist.title')}</H2>
      <div className="flex justify-center w-full">
        <CardContainer>
          { pastEvents.reverse()
            .map((event, index) => (
              <EduEvent
                data-test="message"
                disableRead
                event={event}
                index={index}
                size={EduEvent.length}
              />
            )) }
        </CardContainer>
      </div>
    </div>
  );
}

const CardContainer = styled.div(() => [
  tw`w-full md:w-1/2`,
]);

const H2 = styled.h2(({ blank }) => [
  tw`flex font-light justify-center text-center`,
  blank && tw`text-gray-600`,
]);
