import React, { useEffect, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import useHospitalFlags from '../../hooks/useHospitalFlags';
import { useRecaptchaMutation } from '../../patient-app-common/api/authApi';

export default function ReCaptcha({ setIsHuman } : { setIsHuman: (result: boolean) => void }) {
  const { hasRecaptcha } = useHospitalFlags();

  const [recaptcha] = useRecaptchaMutation();

  const captchaRef = useRef<ReCAPTCHA>(null);

  useEffect(() => {
    if (hasRecaptcha) {
      setIsHuman(false);
    }
  }, [hasRecaptcha]);

  const verifyRecaptcha = async () => {
    const captchaToken = captchaRef.current?.getValue();

    try {
      const results = await recaptcha(captchaToken).unwrap();

      if (results.success) {
        return true;
      }
    } catch (_error: any) {
      return false;
    }

    return false;
  };

  const sendResult = async () => {
    const result = await verifyRecaptcha();
    setIsHuman(result);
  };

  if (!hasRecaptcha) {
    return null;
  }

  return (
    <div className="pt-8">
      <ReCAPTCHA
        ref={captchaRef}
        sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY || ''}
        onChange={sendResult}
      />
    </div>
  );
}
