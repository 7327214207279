import { useEffect, useState } from 'react';
import _ from 'lodash';
import {
  matchPath,
  useNavigate,
} from 'react-router-dom';
import { useGetPatientQuery } from '../patient-app-common/api/patientApi';
import { logout } from '../patient-app-common/slices/authSlice';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { getURLParam } from '../utils/utils';

export default function useSetupPrompt() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [initUrl, setInitUrl] = useState('');

  useEffect(() => {
    setInitUrl(window.location.href);
  }, []);

  const { patientId, providerMode } = useAppSelector(({ auth }) => auth);

  const {
    data: patient,
  } = useGetPatientQuery(undefined, { skip: !patientId || providerMode });

  useEffect(() => {
    if (!patient) return;

    const { setupUrl, setupSurveyId, setupSurveyCompleted } = patient;

    if (!_.isEmpty(setupUrl)) {
      dispatch(logout());
      // TODO: change if using BrowserRouter in App.js
      return navigate(setupUrl.split('#')[1], { replace: true });
    }

    if (!setupSurveyId || setupSurveyCompleted) return;

    const routeParams = {};

    const surveyId = getURLParam(initUrl, 'procedure_id');
    const path = initUrl.split('#')[1];

    const autoAuthLink = !_.isEmpty(path) && matchPath({
      path: '/auth_auto/:token/:eventId?',
    }, path);
  
    const hasNextSurvey = autoAuthLink && surveyId && setupSurveyId !== _.toNumber(surveyId);

    if (hasNextSurvey) {
      const eventId = autoAuthLink?.params?.eventId;
      const nextSurveyEventId = eventId !== '0' ? eventId : null;

      routeParams.state = {
        hasNextSurvey,
        nextSurveyEventId,
        nextSurveyPathname: `/tracker/${surveyId}`,
      };
    }

    return navigate(`/tracker/${setupSurveyId}`, routeParams);
  }, [patient]);
}
