import React from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { ImSpinner8 } from 'react-icons/im';
import tw, { css, styled } from 'twin.macro';

export default function Loader({ visible, text }) {
  const { t } = useTranslation();
  const divRef = React.useRef(null);

  React.useEffect(() => {
    if (visible) {
      divRef.current.focus();
    }
  }, [visible]);

  if (visible) {
    return (
      ReactDOM.createPortal(
        <>
          <ModalOverlay />
          <ModalWrapper aria-modal role="dialog">
            <ModalCard ref={divRef} tabIndex={0}>
              <ContentContainer>
                <div aria-busy="true" className="m-6" role="status">
                  <span className="text-6xl text-blue-100">
                    <ImSpinner8 className="animate-spin" />
                  </span>
                </div>
                <p className="text-xl">{t('common.text.loading')}</p>
                <p className="text-xl">{text}</p>
              </ContentContainer>
            </ModalCard>
          </ModalWrapper>
        </>, document.body,
      )
    );
  }
  return null;
}

const ModalOverlay = styled.div(() => [
  tw`fixed top-0 left-0`,
  tw`h-full w-full`,
  tw`bg-black opacity-15`,
  css`z-index: 1040;`,
]);

const ModalWrapper = styled.div(() => [
  tw`fixed top-0 left-0`,
  tw`overflow-x-hidden overflow-y-auto`,
  css`
    z-index: 1050;
    width: 100%;
    height: 100%;
    outline: 0;
  `,
]);

const ModalCard = styled.div(() => [
  tw`bg-white relative rounded py-4 px-8`,
  css`
    margin: 1.75rem auto;
    max-width: 500px;
    z-index: 100;
  `,
]);

const ContentContainer = styled.div(() => [
  tw`flex flex-col items-center text-center p-10`,
]);
