import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Navigate, useLocation, useParams } from 'react-router-dom';
import tw, { styled } from 'twin.macro';
import ChartsList from '../../progress/charts/ChartsList';
import { copyAndformatVisualQuestions } from '../../../patient-app-common/helpers/progress_helper';
import useTrackers from '../../../patient-app-common/hooks/useTrackers';
import useSegment from '../../../hooks/useSegment';
import SurveyLoader from '../layout/SurveyLoader';
import { useGetSurveyQuery } from '../../../patient-app-common/api/surveyApi';

const CHARTS_EVENT = 'Progress chart list viewed';

export default function ResultChart() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { surveyId } = useParams();
  const { sendTrackEvent } = useSegment();
  const divRef = React.useRef(null);

  const { data: survey, isLoading: isLoadingSurvey } = useGetSurveyQuery(surveyId, {
    skip: !surveyId,
  });

  const { isFetching: isFetchingEntries, surveyEntries } = useTrackers();

  const chartQuestions = useMemo(() => {
    if (!survey || !surveyEntries?.length) return [];
    return copyAndformatVisualQuestions([survey], surveyEntries);
  }, [survey, surveyEntries]);

  useEffect(() => {
    if (!survey || !chartQuestions.length) return;

    sendTrackEvent(CHARTS_EVENT, {
      pathname,
      procedureId: survey.id,
      procedureName: survey.patientDisplayName,
    });
  }, [survey, chartQuestions]);

  if (isLoadingSurvey || isFetchingEntries) {
    return <SurveyLoader />;
  }

  if (surveyEntries && !chartQuestions.length) {
    return <Navigate to="/" replace />;
  }

  return (
    <>
      <HeaderDiv>
        <div className="flex-1">
          <h1 className="text-xl">{t('common.link.charts')}</h1>
        </div>
        <div className="inline-flex">
          <SkipBtn onClick={() => divRef.current.focus()}>
            {t('common.link.skip')}
          </SkipBtn>

          <Link className="block btn-danger" to="/">
            {t('common.action.close')}
          </Link>
        </div>
      </HeaderDiv>
      <BodyDiv aria-label={t('common.link.charts')} ref={divRef} role="region" tabIndex={0}>
        <ChartsList chartQuestions={chartQuestions} />
      </BodyDiv>
      <FooterDiv>
        <Link className="flex justify-center p-3 w-full" to="/">
          <Btn>
            {t('common.action.ok')}
          </Btn>
        </Link>
      </FooterDiv>
    </>
  );
}

const HeaderDiv = styled.div(() => [
  tw`bg-white border-b border-gray-500 py-2`,
  tw`fixed flex flex-wrap items-center w-full`,
  tw`lg:px-16 px-6`,
  tw`top-0 z-10`,
]);

const SkipBtn = styled.button(() => [
  tw`text-white`,
  tw`focus:text-black`,
  tw`hidden sm:block`,
]);

const BodyDiv = styled.div(() => [
  tw`flex justify-center mt-16 mb-24 overflow-scroll`,
]);

const FooterDiv = styled.footer(() => [
  tw`bg-white border-gray-300 border-t p-4 shadow-top w-full`,
  tw`bottom-0 fixed z-10`,
]);

const Btn = styled.button(() => [
  tw`bg-blue-100 border border-blue-100 py-2 px-4 rounded shadow text-white`,
  tw`w-full md:w-1/2 lg:w-1/3`,
  tw`text-lg sm:text-2xl`,
]);
