import React from "react";
import { useTranslation } from "react-i18next";
import tw, { styled } from "twin.macro";

const path = `${process.env.PUBLIC_URL}/assets/painfaces`;
const faceImages = {
  "WB-0-OFF.png": `${path}/WB-0-OFF.png`,
  "WB-0-ON.png": `${path}/WB-0-ON.png`,
  "WB-2-OFF.png": `${path}/WB-2-OFF.png`,
  "WB-2-ON.png": `${path}/WB-2-ON.png`,
  "WB-4-OFF.png": `${path}/WB-4-OFF.png`,
  "WB-4-ON.png": `${path}/WB-4-ON.png`,
  "WB-6-OFF.png": `${path}/WB-6-OFF.png`,
  "WB-6-ON.png": `${path}/WB-6-ON.png`,
  "WB-8-OFF.png": `${path}/WB-8-OFF.png`,
  "WB-8-ON.png": `${path}/WB-8-ON.png`,
  "WB-10-OFF.png": `${path}/WB-10-OFF.png`,
  "WB-10-ON.png": `${path}/WB-10-ON.png`,
};

export default function FaceScale({ sendAnswer, answerHistory }) {
  const { t } = useTranslation('survey');
  const [answer, setAnswer] = React.useState(answerHistory);

  const faceScale = [
    { value: 0, legend: t("facescale.value_0") },
    { value: 2, legend: t("facescale.value_1") },
    { value: 4, legend: t("facescale.value_2") },
    { value: 6, legend: t("facescale.value_3") },
    { value: 8, legend: t("facescale.value_4") },
    { value: 10, legend: t("facescale.value_5") },
  ];

  const handleClick = (value) => {
    setAnswer(value / 2);
    sendAnswer(value / 2);
  };

  return (
    <>
      <div className="flex justify-center">
        <ScaleContainer>
          {faceScale.map((face) => (
            <AnswerContainer key={face.value}>
              <button
                onClick={() => handleClick(face.value)}
              >
                <ImgDiv>
                  {face.value === answer * 2 ? (
                    <Img
                      alt={`${face.value}: ${face.legend}`}
                      src={faceImages[`WB-${face.value}-ON.png`]}
                    />
                  ) : (
                    <Img
                      alt={`${face.value}: ${face.legend}`}
                      src={faceImages[`WB-${face.value}-OFF.png`]}
                    />
                  )}
                </ImgDiv>
                <p>{face.value}</p>
                <p>{face.legend}</p>
              </button>
            </AnswerContainer>
          ))}
        </ScaleContainer>
      </div>
      {answer !== undefined && (
        <div className="flex justify-center pt-8">
          <div className="flex flex-col items-center">
            <img
              alt={`${t('common.text.selection')}: ${faceScale[answer].legend}`}
              src={faceImages[`WB-${answer * 2}-ON.png`]}
            />

            <p className="font-bold p-4 pb-8 text-lg">
              {faceScale[answer].legend}
            </p>
          </div>
        </div>
      )}
    </>
  );
}

const ScaleContainer = styled.div(() => [
  tw`bg-white border border-gray-500 inline-flex rounded shadow`,
  tw`lg:w-3/4 w-full`,
]);

const AnswerContainer = styled.div(() => [
  tw`border-2 border-white flex justify-center rounded w-1/2`,
  tw`hover:bg-teal-30`,
]);

const ImgDiv = styled.div(() => [tw`flex justify-center p-1`]);

const Img = styled.img(() => [
  tw`object-scale-down`,
]);
