import React from 'react';
import ResultCard from '../../survey/result/ResultCard';

export default function TrackerResult({ recommendations }) {
  return (
    <div className="mx-auto mt-8">
      {recommendations.map((recommendation) => (
        <ResultCard
          key={recommendation.id}
          recommendation={recommendation}
        />
      ))}
    </div>
  );
}
