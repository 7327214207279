import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Card from '../../layout/Card';
import AlertCard from '../../../layout/AlertCard';

interface PreopCardMatProps {
  dueDate: moment.Moment | null;
  toggleModal: () => void;
}

export default function PreopCardMat({ dueDate, toggleModal }: PreopCardMatProps) {
  const navigate = useNavigate();
  const { t } = useTranslation('home'); 

  const hasDueDate = !_.isEmpty(dueDate);

  const conceptionDate = moment(dueDate)
    .subtract(280, 'd');

  const daysPregnant = moment()
    .diff(moment(conceptionDate)
      .startOf('day'), 'days');

  const getBodyText = () => {
    const isDue = moment().isSame(dueDate, 'day');

    let string = t('preopcardmat.description_nodate');

    if (isDue) {
      string = t('preopcardmat.countdown_zero');
    } else if (daysPregnant) {
      if (daysPregnant <= 7) {
        string = t('preopcardmat.countdown_day', { count: daysPregnant })
      } else {
        const weeksPregnant = Math.floor(daysPregnant / 7);
        string = t('preopcardmat.countdown_week', { count: weeksPregnant })
      }
    }

    return string;
  };

  return (
    <div
      aria-labelledby="preop-title"
      aria-describedby="preop-body"
      data-test="phase-card"
      className="py-4"
      role="dialog"
    >
      <Card
        headerComp={(
          <>
            <h2 className="font-bold" role="status" id="preop-title">
              {hasDueDate ? (
                <span>{t('preopcardmat.title_date', { date: moment(dueDate).format('LL') })}</span>
              ) : (
                <span>{t('preopcardmat.title_nodate')}</span>
              )}
            </h2>
          </>
        )}
        bodyComp={(
          <>
            <p id="preop-body">{getBodyText()}</p>

            {(!daysPregnant || daysPregnant < 280) && (
              <>
                <AlertCard
                  title={t('preopcardmat.button.set_future_surgery_title')}
                  text={t('preopcardmat.button.set_future_surgery_description')}
                  handleAlertClick={toggleModal}
                />
                <AlertCard
                  title={t('preopcardmat.button.confirm_surgery_title')}
                  text={t('preopcardmat.button.confirm_surgery_description')}
                  handleAlertClick={() => navigate('/maternity')}
                />
              </>
            )}
            {(daysPregnant >= 280) && (
              <AlertCard
                title={t('preopcardmat.button.set_past_surgery_title')}
                text={t('preopcardmat.button.confirm_surgery_description')}
                handleAlertClick={() => navigate('/maternity')}
              />
            )}
          </>
        )}
      />
    </div>
  );
}
