import React from 'react';
import { useTranslation } from 'react-i18next';
import { Panel, LoaderInline } from '../../layout';
import { useUpdatePatientMutation } from '../../../patient-app-common/api/patientApi';

export default function Language({ languageId, languages }) {
  const { t } = useTranslation();

  const [updatePatient, { isLoading }] = useUpdatePatientMutation();

  const [selectedLang, setSelectedLang] = React.useState(languageId);

  const handleConfirmClick = async () => {
    const params = { languageId: selectedLang };

    try {
      await updatePatient(params).unwrap();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Panel title={t('common.select.language')}>
      <div className="inline-flex w-full">
        <select
          aria-label="language selection"
          name="lng"
          id="lng"
          defaultValue={selectedLang}
          onChange={(e) => {
            setSelectedLang(e.target.value)
          }}
          className="form-select w-3/4"
        >
          {languages.map((lng) => (
            <option key={lng.id} value={lng.id}>{lng.displayName}</option>
          ))}
        </select>
        <div className="px-4">
          <button className="btn-primary" onClick={() => handleConfirmClick()}>
            {t('common.action.save')}
          </button>
        </div>
      </div>
      { isLoading && <LoaderInline /> }
    </Panel>
  );
}
