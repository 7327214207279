import React from 'react';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import '../../styles/react_dates_overrides.css';
import { SingleDatePicker } from 'react-dates';
import { useTranslation } from 'react-i18next';

export default function DateInput({ className, sendDate, startDate }) {
  const { t } = useTranslation();

  const [focus, setFocus] = React.useState(false);
  const [date, setDate] = React.useState(null);

  const handleChange = (date) => {
    setDate(date);
    sendDate(date);
  };

  React.useEffect(() => {
    if (startDate) {
      setDate(startDate);
    }
  }, [startDate]);

  function renderMonthElement({
    month,
    onMonthSelect,
    onYearSelect,
  }) {
    let i;
    const years = [];

    for (i = moment().year(); i >= moment().year() - 100; i--) {
      years.push(
        <option value={i} key={`year-${i}`}>{i}</option>,
      );
    }

    const months = moment.months().map((label, value) => (
      <option value={value} key={label}>{label}</option>
    ));

    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <>
          <select
            value={month.month()}
            onChange={(e) => onMonthSelect(month, e.target.value)}
          >
            {months}
          </select>
        </>
        <>
          <select
            value={month.year()}
            onChange={(e) => onYearSelect(month, e.target.value)}
          >
            {years}
          </select>
        </>
      </div>
    );
  }

  return (
    <div className={className} style={{ padding: 0 }}>
      <SingleDatePicker
        id="datepicker"
        block
        date={date}
        displayFormat="LL"
        isOutsideRange={() => false}
        focused={focus}
        hideKeyboardShortcutsPanel
        numberOfMonths={1}
        onDateChange={(date) => handleChange(date)}
        onFocusChange={(input) => setFocus(input.focused)}
        placeholder={t('common.input.date')}
        renderMonthElement={renderMonthElement}
        showClearDate
        showDefaultInputIcon
      />
    </div>
  );
}
