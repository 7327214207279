import React from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import tw, { css, styled } from 'twin.macro';

export default function Device({ sendAnswer }) {
  React.useEffect(() => {
    sendAnswer(false);
  
  }, []);

  return (
    <div>
      <p>This feature is only available on the SeamlessMD app.Press "Next" to continue the survey without connecting your device and enter your measurement on your own.</p>
      <Container>
        <p className="font-light text-sm sm:text-base">
          Use the SeamlessMD app to connect your Bluetooth device
        </p>
        <div className="flex justify-center">
          {!isAndroid && (
            <a
              href="https://apps.apple.com/ca/app/seamlessmd/id696595041"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="p-2">
                <Badge
                  src="/assets/icons/Download_on_the_App_Store_Badge.svg"
                  alt="Downoad on the App Store"
                />
              </div>
            </a>
          )}
          {!isIOS && (
            <a
              href="https://play.google.com/store/apps/details?id=md.seamless.app"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="p-2">
                <Badge
                  src="/assets/icons/Google_Play_Store_badge_EN.svg"
                  alt="Get it on Google Play"
                />
              </div>
            </a>
          )}
        </div>
      </Container>
    </div>
  );
}

const Container = styled.footer(() => [
  tw`
    bg-white
    border 
    border-gray-500
    my-4
    py-4 
    rounded
    text-center 
    w-full
  `,
]);

const Badge = styled.img(() => [
  css`width: 10rem;`,
]);
