import React from "react";
import { useTranslation } from "react-i18next";
import { BiDownArrowAlt, BiUpArrowAlt } from "react-icons/bi";
import tw, { css, styled } from "twin.macro";
import { ORTHO_ASSETS } from "../../../constants/ortho-assets";

export default function Picker({ data, sendAnswer, answerHistory }) {
  const { t } = useTranslation('survey');

  const initAnswer = answerHistory || 0;
  const [value, setAnswer] = React.useState(initAnswer);

  React.useEffect(() => {
    sendAnswer(initAnswer);
    
  }, []);

  const downClick = () => {
    if (value < data.length - 1) {
      setAnswer(value + 1);
      sendAnswer(value + 1);
    }
  };

  const upClick = () => {
    if (value >= 1) {
      setAnswer(value - 1);
      sendAnswer(value - 1);
    }
  };

  const getImage = (answer) => {
    // the provider may provide an incorrect hardcoded asset path 
    // so we extract only the file name of the asset
    if (answer === undefined) {
      return '';
    }

    const path = data[answer].text;
    const fileName = path.substring(path.lastIndexOf('/') + 1);
    const img = ORTHO_ASSETS[fileName] || '';

    return img;
  };

  const getImageLabel = (answer) => {
    if (answer === undefined) {
      return '';
    }

    const fileName = data[answer].text;
    const degrees = fileName.match(/\d+/g).map(Number);

    return t('picker.selected_hint', { answer: degrees });
  };

  return (
    <div>
      <Container>
        <Img
          src={getImage(value)}
          alt={getImageLabel(value)}
        />
      </Container>

      <Container>
        <Btn
          onClick={() => downClick()}
          aria-label={t("picker.button.down")}
        >
          <BiDownArrowAlt />
        </Btn>

        <Btn
          onClick={() => upClick()}
          aria-label={t("picker.button.up")}
        >
          <BiUpArrowAlt />
        </Btn>
      </Container>
    </div>
  );
}

const Container = styled.div(() => [
  tw`flex justify-center`,
]);

const Img = styled.img(() => [
  css`width: 25rem`,
]);

const Btn = styled.button(() => [
  tw`bg-white border border-gray-500 m-4 mb-8 px-8 rounded-full shadow`,
  tw`text-4xl sm:text-6xl`,
]);
