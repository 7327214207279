import _ from 'lodash';
import moment from 'moment';

// clone the result template from the survey to avoid mutation error
// add triggered and message list properties
export function getResultTemplate(surveyData) {
  const { results } = surveyData;
  const template = _.cloneDeep(results);

  Object.keys(template).forEach((id) => {
    template[id].triggered = false;
    template[id].messageList = [];
  });

  return template;
}

export function getRuleEducation(rule, education) {
  const eduParams = {
    ids: [],
    titles: [],
    html: '',
  };

  if (rule.edIds.length) {
    const lastItemIndex = rule.edIds.length - 1;

    rule.edIds.forEach((edId, index) => {
      const eduTopic = education.find(
        (component) => (component.id === _.toNumber(edId)),
      );

      if (eduTopic) {
        eduParams.ids.push(edId);
        eduParams.titles.push(eduTopic.title);
        eduParams.html += eduTopic.html;

        if (eduTopic.html && rule.edIds.length > 1 && index !== lastItemIndex) {
          eduParams.html += '<hr style="margin-top:1.5rem;margin-bottom:1.5rem" />';
        }
      }
    });
  }

  return eduParams;
}

function validateEntry(question, patientAnswer) {
  const { answers, type } = question;

  switch (type) {
    case 'checkbox':
      return _.isArray(patientAnswer) && answers.length >= patientAnswer.length;

    case 'face_scale':
    case 'gen_scale':
    case 'radio':
    case 'picker':
      return _.isNumber(patientAnswer) && answers.length > patientAnswer;

    case 'numerical_input': {
      const value = _.toNumber(patientAnswer);
      return _.isNumber(value);
    }

    default:
      return true;
  }
}

export function resultHelperContext({
  answers,
  education,
  surgeryDate,
  surveyData,
  surveyEntries,
}) {
  const { questions, rules } = surveyData;

  function getActiveRuleIds() {
    const daysAfterSurgery = moment().diff(surgeryDate, 'days');
    const activeRules = _.valuesIn(rules);
    _.remove(activeRules, (rule) => (
      rule.serverOnly
      || (rule.activeDate && rule.activeDate > daysAfterSurgery)
      || (rule.inactiveDate && daysAfterSurgery >= rule.inactiveDate)
    ));
    return _.map(activeRules, 'id');
  }

  // returns the rules triggered by the patient answers
  function getTriggeredRules() {
    const rulesTriggered = [];

    const ruleIds = getActiveRuleIds();

    ruleIds.forEach((id) => {
      let answersMeetRule = false;
      let rulePieceCount = 0;
      let ruleEdu = {};

      const rule = rules[id];
      const { rulePieces, resultId } = rule;

      rulePieceCount = answerMeetsRule(rule);

      if (rulePieceCount === rulePieces.length) {
        answersMeetRule = true;
      }

      if (answersMeetRule) {
        ruleEdu = getRuleEducation(rule, education);

        rulesTriggered.push({
          resultId,
          ruleMsg: rule.patientMessage,
          ruleEdu,
        });
      }
    });

    return rulesTriggered;
  }

  function answerMeetsRule(rule) {
    let count = 0;
    const { rulePieces } = rule;

    rulePieces.forEach((rulePiece) => {
      const qNumber = `q${rulePiece.questionNumber}`;
      const qIndex = rulePiece.questionNumber - 1;
      const question = questions[qIndex];
      const patientAnswer = answers[qNumber];
      const rulePieceAnswer = rulePiece.answerNumber - 1;

      // error occurs when survey question type was changed after a survey was submitted
      const isValidEntry = validateEntry(question, patientAnswer);
      if (!isValidEntry) return count;

      switch (question.type) {
        case 'checkbox':
          if (patientAnswer[rulePieceAnswer].toString() === (rulePiece.value)) {
            count += 1;
          }
          break;

        case 'face_scale':
        case 'picker':
          if (patientAnswer === rulePieceAnswer) {
            count += 1;
          }
          break;

        case 'gen_scale':
        case 'radio':
          if (patientAnswer === rulePieceAnswer) {
            if (rulePiece.value === 'true') {
              count += 1;
            }
          } else if (rulePiece.value === 'false') {
            count += 1;
          }
          break;

        case 'numerical_input': {
          const numericalRuleTriggered = numericalMeetsRule(rulePiece, rule, patientAnswer);

          if (numericalRuleTriggered) {
            count += 1;
          }
        }
          break;

        default:
      }
    });
    return count;
  }

  function numericalMeetsRule(rulePiece, rule, patientAnswer) {
    const { rulePieces } = rule;
    let ruleTriggered = false;

    switch (rule.comparator) {
      case 'decrease_by':
      case 'increase_by':
        ruleTriggered = numericalEntriesDiff(
          rulePiece,
          _.round(patientAnswer, 2),
          rule.comparator,
        );
        break;

      case 'is_between':
        ruleTriggered = numericalIsBetween(
          rulePiece.questionNumber,
          rulePieces,
          _.round(patientAnswer, 2),
        );
        break;

      default:
        ruleTriggered = evaluateNumerical(
          _.round(patientAnswer, 2),
          _.round(rulePiece.value, 2),
          rule.comparator,
        );
    }
    return ruleTriggered;
  }

  function numericalEntriesDiff(rulePiece, answer, comparator) {
    let result = false;

    const refDate = moment().subtract(rulePiece.date, 'day').startOf('day');

    const refEntry = _.findLast(surveyEntries, (entry) => moment(entry.createdAt)
      .startOf('day')
      .isSameOrBefore(refDate));

    if (!refEntry) return result;

    const pastAnswer = _.toNumber(refEntry.source[`q${rulePiece.questionNumber}`]);

    if (!_.isNumber(pastAnswer)) {
      return result;
    }

    const entryDate = moment(refEntry.createdAt)
      .startOf('day');
    const daysFromNow = moment()
      .diff(entryDate, 'days');
    const daysOffset = daysFromNow - rulePiece.date;
    const avgDailyChange = (answer - pastAnswer) / daysFromNow;
    const valueToCompare = (avgDailyChange * daysOffset) + pastAnswer;

    switch (comparator) {
      case 'increase_by':
        if (answer >= valueToCompare + _.toNumber(rulePiece.value)) {
          result = true;
        }
        break;

      case 'decrease_by':
        if (answer <= valueToCompare - _.toNumber(rulePiece.value)) {
          result = true;
        }
        break;

      default:
        result = false;
        break;
    }
    return result;
  }

  function numericalIsBetween(question, rulePieces, answer) {
    const questionRulePieces = rulePieces.filter(
      (rulePiece) => (question === rulePiece.questionNumber),
    );

    const values = _.map(questionRulePieces, 'value')
      .map((valueString) => _.round((valueString), 2))
      .filter((value) => typeof value === 'number')
      .sort((a, b) => a - b);

    const min = values[0];
    const max = values[values.length - 1];

    if (values.length > 1 && answer >= min && answer <= max) {
      return true;
    }

    return false;
  }

  function evaluateNumerical(answer, ruleValue, comparator) {
    let result = false;

    switch (comparator) {
      case 'greater_than':
        if (answer > ruleValue) {
          result = true;
        }
        break;
      case 'greater_than_or_equal':
        if (answer >= ruleValue) {
          result = true;
        }
        break;
      case 'less_than_or_equal':
        if (answer <= ruleValue) {
          result = true;
        }
        break;
      case 'less_than':
        if (answer < ruleValue) {
          result = true;
        }
        break;
      case 'exactly_equal':
        if (answer === ruleValue) {
          result = true;
        }
        break;
      default:
        result = false;
        break;
    }
    return result;
  }

  return { getTriggeredRules };
}
