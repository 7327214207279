import _ from 'lodash';
import useEducation from './useEducation';
import {
  getResultTemplate,
  getRuleEducation,
} from '../helpers/result_helper';

export default function useResults() {
  const { getEducationForSurvey } = useEducation({ skip: true });

  const createResults = async (surveyData, patientEntry) => {
    const template = getResultTemplate(surveyData);

    const surveyEducationId = surveyData.mainTracker ? undefined : surveyData.id;
    const education = await getEducationForSurvey(surveyEducationId, true);

    const { ruleIds } = patientEntry;
    const { rules } = surveyData;

    const triggeredRules = _.filter(rules, (rule) => ruleIds.includes(rule.id));

    triggeredRules.forEach((rule) => {
      const { resultId } = rule;
      const result = template[resultId];

      if (result) {
        result.triggered = true;

        const ruleEdu = getRuleEducation(rule, education);
        const ruleMsg = rule.patientMessage;

        result.messageList.push({
          ruleMsg,
          ruleEdu,
        });
      }
    });

    let results = Object.keys(template).map((id) => template[id]);
    results = _.orderBy(results, ['priority'], ['asc']);
    const defaultResult = [results.pop()];
    results = _.filter(results, ['triggered', true]);

    if (!results.length) return defaultResult;

    if (surveyData.recommendationType === 'consolidated_recommendation') {
      return consolidateResults(results);
    }

    return results;
  };

  const consolidateResults = (separatedResults) => {
    const resultsCopy = _.cloneDeep(separatedResults);
    const priorityResult = resultsCopy.shift();
    let { messageList } = priorityResult;

    resultsCopy.forEach((result) => {
      messageList = messageList.concat(result.messageList);
    });

    priorityResult.messageList = messageList;
    return [priorityResult];
  };

  return {
    createResults,
  };
}
