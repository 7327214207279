import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Card from '../../layout/Card';

interface EndSurveyCardProps {
  endSurveyId: number;
  endSurveyCompleted: boolean;
}

export default function EndSurveyCard({ endSurveyId, endSurveyCompleted }: EndSurveyCardProps) {
  const { t } = useTranslation('home');

  if (endSurveyCompleted) return null;

  return (
    <div className="py-8">
      <Card
        headerComp={(
          <div>
            <h2 className="font-bold text-lg">{t('endsurveycard.title')}</h2>
          </div>
        )}
        bodyComp={(
          <div>
            <div>
              <p>{t('endsurveycard.description')}</p>
              <div className="flex justify-center">
                <Link
                  className="btn-primary w-3/4 block text-center mt-4"
                  to={`/tracker/${endSurveyId}`}
                >
                  {t('common.button.survey_start')}
                </Link>
              </div>
            </div>
          </div>
        )}
      />
    </div>
  );
}
