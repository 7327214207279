/* eslint-disable import/prefer-default-export */
import _ from 'lodash';
import { ProfileKeysById } from '../types/Patient';
import { Education } from '../types/Education';
import { meetsProfileDependency } from './logic_helpers';

export interface EducationTree extends Education {
  children?: Education[]
}

// recursively creates a list of containers with education subtopics
// returns a tree array of education component objects
export function treeSortEducation(education: EducationTree[], parentID: null | number) {
  const topics: EducationTree[] = [];
  education.forEach((component) => {
    if (component.hidden === true) {
      return;
    }

    if (component.parentId === parentID) {
      const componentClone = { ...component };
      componentClone.children = treeSortEducation(education, component.id);
      topics.push(componentClone);
    }
  });

  return topics;
}

// recursively creates an ordered list of education topics
// filters components that do not meet or has a parent that does not meet profile dependency
// used for creating breadcrumbs or pagination links
// returns a flattened array of education component objects
export function flattenEducationList(
  education: Education[],
  profile: ProfileKeysById,
  blackListedParentIDs: number[] = [],
  parentID: null | number = null,
) {
  const topics: Education[] = [];
  // blacklist is to filter out parent topics not matching profile
  const blacklist: number[] = [...blackListedParentIDs];

  education.forEach((component) => {
    if (component.hidden === true) {
      return;
    }

    const profileDependency = component.profileDependencies;

    if (profileDependency.length) {
      const meetsProfile = meetsProfileDependency(
        profileDependency,
        component.profileDependencyType,
        profile,
      );

      if (!meetsProfile) {
        blacklist.push(component.id);
        return;
      }
    }

    if (component.parentId === parentID) {
      if (component.parentId && blacklist.includes(component.parentId)) {
        return;
      }

      const componentClone = { ...component };
      topics.push(componentClone);

      const subtopics = flattenEducationList(education, profile, blacklist, component.id);

      subtopics.forEach((component) => {
        const componentClone = { ...component };
        topics.push(componentClone);
      });
    }
  });

  return topics;
}

// returns the filtered education components plus their children in a tree array
export function filteredEducationTree(
  education: EducationTree[],
  whitelist: number[],
) {
  const topics: EducationTree[] = [];

  // filter topics that are included in the whitelist
  const matchedTopics = _.filter(education,
    (component) => (whitelist.includes(component.id)));
  // exclude matching child topic when there is a matching parent topic
  _.remove(matchedTopics, (component) => (
    component.parentId && whitelist.includes(component.parentId)
  ));

  matchedTopics.forEach((component) => {
    const componentClone = { ...component };
    componentClone.children = treeSortEducation(education, component.id);
    topics.push(componentClone);
  });

  return topics;
}

// returns an education component object
// id can be string or number due to API inconsistency where app event education id is a string
export function educationTopic(education: Education[], id: string | number) {
  return education.find((component) => component.id === _.toNumber(id));
}

// shift headers of an html down one level
// used to combine education components into one html such that there is only one H1
export function replaceHeaders(htmlStr: string) {
  const headers = {
    '<h1>': '<h2>',
    '</h1>': '</h2>',
    '<h2>': '<h3>',
    '</h2>': '</h3>',
    '<h3>': '<h4>',
    '</h3>': '</h4>',
    '<h4>': '<h5>',
    '</h4>': '</h5>',
    '<h5>': '<h6>',
    '</h5>': '</h6>',
    '<h6>': '<p>',
    '</h6>': '</p>',
  };

  const regex = new RegExp(Object.keys(headers)
    .join('|'), 'gi');

  // @ts-ignore
  return htmlStr.replace(regex, (matched) => headers[matched.toLowerCase()]);
}

export type CombinedTopic = {
  ids: number[],
  titles: string[],
  html: string,
  h1: string
}
// combine education components into one html
export function combineEducationTopics(education: Education[], idArray: string[]) {
  const combinedEdu: CombinedTopic = {
    ids: [],
    titles: [],
    html: '',
    h1: '',
  };

  const lastItemIndex = idArray.length - 1;

  idArray.forEach((id, index) => {
    if (id === '0') {
      return;
    }
    const eduTopic: Education | undefined = educationTopic(education, id);

    if (!eduTopic) return;

    let html = eduTopic?.html;

    if (!html && !combinedEdu.h1) {
      combinedEdu.h1 = (eduTopic.title);
    }

    if (html && idArray.length > 1 && index !== lastItemIndex) {
      html += '<hr style="margin-top:1.5rem;margin-bottom:1.5rem" />';
    }

    combinedEdu.ids.push(eduTopic.id);
    combinedEdu.titles.push(eduTopic.title);
    combinedEdu.html += html;
  });

  if (combinedEdu.h1) {
    const header = `<h1><strong>${combinedEdu.h1}</strong></h1>`;

    combinedEdu.html = `
      <div class='ed-v3'>
        ${header + replaceHeaders(combinedEdu.html)}
      </div>
    `;
  }

  return combinedEdu;
}

type Breadcrumb = {
  id: number,
  title: string,
}

// recursively finds the parent topic lineage of a given topic
// returns an array of objects with the education id and title
export function breadcrumbs(
  education: Education[],
  id: string | number,
  crumbs: Breadcrumb[] = [],
): Breadcrumb[] {
  const data: Breadcrumb[] = [...crumbs];
  const topic = educationTopic(education, id);

  if (!topic) return data;

  data.push({
    id: topic.id,
    title: topic.title,
  });

  const parentID = topic.parentId;

  if (parentID !== null) {
    return breadcrumbs(education, parentID, data);
  }

  return data;
}

export type EducationParams = {
  id: number;
  title: string;
  html: string;
  subtopics?: Education[];
}

// returns html or subtopics of a given topic
export function educationParams(education: Education[], id: number) {
  const params: EducationParams = { id, title: '', html: '' };

  if (!id || id === 0) {
    return params;
  }

  const topic = educationTopic(education, id);

  if (!topic) return params;

  params.title = topic.title;

  if (topic.html) {
    params.html = topic.html;
  } else {
    params.subtopics = treeSortEducation(education, id);
  }

  return params;
}
